<script>
import { CARD_PAYMENTS } from '../../constatns/CristmasConstants'

const STATUS_TEXT = {
  pending: 'Waiting for payment',
  success: 'Payment received',
  fail: 'Decline',
  check: 'In progress'
}

// const updateForm = (firstObject, secondObject) => {
//   const keysOne = Object.keys(firstObject)
//   const keysTwo = Object.keys(secondObject)

//   keysTwo.forEach(key => {
//     // если есть не актуальный ключ, то удаляем его
//     if (!keysOne.includes(key)) {
//       delete secondObject[key]
//     }
//   })

//   keysOne.forEach(key => {
//     if (keysTwo.includes(key)) {
//       //если свойства - обьекты, то запускаем рекурсию
//       if (
//         Object.prototype.toString.call(firstObject[key]) ===
//           '[object Object]' &&
//         Object.prototype.toString.call(secondObject[key]) === '[object Object]'
//       ) {
//         updateForm(firstObject[key], secondObject[key])
//       } // если свойство - обьект, только в firstObject, то устанавлиевам это свойство для secondObject
//       else if (
//         Object.prototype.toString.call(firstObject[key]) === '[object Object]'
//       ) {
//         secondObject[key] = firstObject[key]
//       } // если свойство - не обьект, то осталяем всё без измений
//       else return
//     } // если актуального ключа нет, то добавляем его
//     else secondObject[key] = firstObject[key]
//   })
// }

export default {
  name: 'ChristmasMixinCD',

  data: () => ({
    form: {
      walletAddress: '0',
      payment: {
        withdrawalList: [{ account: '0', gateway: 'QIWI wallet' }],
        comment: '0',
        status: {
          color: '',
          name: 'pending'
        },
        total: {
          amount: 0,
          currency: 'RUB'
        },
        type: 'Tax Payment'
      },
      selectedPaymentSystem: { value: 'qiwi', text: 'QIWI' },

      newDesign: false,
      isComment: true,
      whiteAlert: false,
      redAlert: false,
      isAddress: true,
      statusText: 'Waiting for payment',
      commentLabel: 'Сomment to payment'
    },

    paymentStatusList: [
      { value: 'pending', text: 'В ожидании' },
      { value: 'success', text: 'Платеж принят' },
      { value: 'fail', text: 'Платеж отклонен' },
      { value: 'check', text: 'Check' }
    ],

    paymentSystemList: [
      { value: 'qiwi', text: 'QIWI' },
      { value: 'mastercard', text: 'MasterCard' },
      { value: 'visa', text: 'Visa' },
      { value: 'bitcoin', text: 'Bitcoin' },
      { value: 'litecoin', text: 'Litecoin' },
      { value: 'mir', text: 'Mir' },
      { value: 'usdt-main', text: 'USDT (TRC-20)' },
      { value: 'usdt-main', text: 'USDT (ERC-20)' },
      { value: 'ton', text: 'TON' },
      { value: 'stellar', text: 'Stellar' },
      { value: 'solana', text: 'Solana' }
    ],

    currencyList: ['USD', 'USDT', 'EUR', 'RUB', 'TON', 'BTC', 'SOL']
  }),

  computed: {
    isWalletAddressCard() {
      return CARD_PAYMENTS.includes(this.form.selectedPaymentSystem.value)
    },

    walletType() {
      return this.form.isAddress ? 'address' : 'username'
    }
  },

  watch: {
    // form: {
    //   handler: function(val) {
    //     const name = `ls.${this.$options.name}`
    //     localStorage[name] = JSON.stringify(val)
    //   },
    //   deep: true
    // },

    'form.payment.status.name'(val) {
      this.statusText = STATUS_TEXT[val]
    }
  }

  // mounted() {
  //   const name = `ls.${this.$options.name}`
  //   if (name in localStorage) {
  //     const data = JSON.parse(localStorage.getItem(name))

  //     updateForm(this.form, data)

  //     Object.assign(this.form, data)
  //   }
  // }
}
</script>
