<template>
  <div id="coupons-widget" class="widget coupons">
    <div class="widget__title">
      <div>Bets History</div>
      <bets-select />
    </div>
    <div class="widget__content">
      <bets-desktop
        :bets="filteredBets"
        v-if="filteredBets.length && !mobile"
      />
      <bets-mobile :bets="filteredBets" v-if="filteredBets.length && mobile" />
      <div class="empty-content" v-if="!filteredBets.length">
        NO BETS AVAILABLE
      </div>
    </div>
    <resizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BetsDesktop from './BetsDesktop'
import BetsMobile from './BetsMobile'
import BetsSelect from './BetsSelect'
import 'vue-resize/dist/vue-resize.css'
import { ResizeObserver } from 'vue-resize'

export default {
  name: 'Bets',

  components: {
    BetsDesktop,
    BetsMobile,
    ResizeObserver,
    BetsSelect
  },

  data: () => ({
    mobile: false
  }),

  computed: {
    ...mapGetters({
      filteredBets: 'FILTERED_BETS'
    })
  },

  mounted() {
    this.handleResize()
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 760) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    }
  }
}
</script>

<style>
#coupons-widget .widget__title {
  display: flex;
  justify-content: space-between;
}

.coupon__details svg path {
  fill: #909090;
}

.coupon__details {
  cursor: pointer;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupons__header,
.coupon__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.coupon__main {
  min-height: 40px;
}

#coupons-widget .coupons__header .column,
.bet__table {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #949495;
  border: 0;
}

#coupons-widget .coupons__row .column {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #fff;
  border: 0;
  padding: 0 10px;
}

#coupons-widget .coupons__header .column:last-child,
#coupons-widget .coupons__row .column:last-child {
  width: 30%;
}

.coupon__bets {
  padding: 0 20px 5px;
}

.coupon__status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet__table {
  width: 100%;
  border: 1px solid grey;
}

.bet__status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet__table tbody td,
.bet__table thead td {
  text-align: center;
}

.bet__table {
  background: #444444;
}

.bet__table tbody td {
  color: #fff;
  height: 40px;
}

.bets-filter select {
  width: 120px;
  background: none;
  color: #fff;
  text-align-last: right;
  border: 0;
  font-size: 14px;
}

.bets-filter select option {
  background: #151516;
}
</style>
