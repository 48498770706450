<script>
import TheModal from './TheModal'
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorLicense',

  components: {
    TheModal
  },

  computed: {
    ...mapGetters(['errorLicenseText']),

    title() {
      return this.errorLicenseText?.title || 'Require a license'
    }
  }
}
</script>

<template>
  <the-modal :title="title">
    <template v-slot:content>
      <div class="error-modal">
        <div class="error-modal__content">
          <div class="error-modal__icon">
            <img src="../../assets/secret-payment/warning.svg" alt="alert" />
          </div>
          
          <div v-if="!errorLicenseText?.text" class="error-modal__text">
            <p>
              To get full access to the bookmaker service, you need to purchase
              the <b>"Full User License"</b>.
            </p>
            <p>
              Thanks to it, you can bet on sports events. This license gives
              access to all bookmaker services, including creating trial
              accounts for your friends, thanks to which they will also be able
              to try themselves in bets.
            </p>
          </div>
          <div v-else class="error-modal__text">
            {{ errorLicenseText.text }}
          </div>
        </div>
      </div>
    </template>
  </the-modal>
</template>

<style lang="scss">
.error-modal {
  max-width: 500px;
  background-color: #fff;
  border-radius: inherit;
  padding: 20px;
  color: #6d6d6d;

  &__text {
    font-size: 14px;
  }

  &__icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  & p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }
}
</style>
