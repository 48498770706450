<template>
  <div class="all-odds-list">
    <all-odds
      :event="event"
      :groupingByName="
        groupingByName.slice(0, Math.round(groupingByName.length / 2))
      "
    />
    <all-odds
      :event="event"
      :groupingByName="
        groupingByName.slice(
          Math.round(groupingByName.length / 2),
          groupingByName.length
        )
      "
    />
  </div>
</template>

<script>
import collection from 'lodash/collection'
import object from 'lodash/object'
import AllOdds from './AllOdds'

export default {
  props: {
    event: Object
  },

  components: {
    AllOdds
  },

  computed: {
    groupingByName() {
      const markets = this.event.Odd.Markets
      var grouped = collection.groupBy(markets, function(market) {
        return market.name.value
      })
      const arr = object.values(grouped)
      for (let i = 0; i < arr.length; i++) {
        arr[i].sort(function(a, b) {
          return a.attr - b.attr
        })
      }
      return arr
    }
  }
}
</script>

<style>
.all-odds-list {
  width: 100%;
  height: 100%;
  margin-top: 15px;
  display: flex;
}

.market {
  width: 100%;
}

.odds-group__title {
  width: 100%;
  text-align: left;
  padding: 0 10px;
  color: #949495;
  margin-top: 10px;
}

.market__body {
  flex-flow: row wrap;
}

.market__body-cell {
  width: calc(100% / 3 - 6px) !important;
  margin: 3px 3px;
  padding: 2px 10px;
}

.column {
  width: calc(100% / 2);
  font-size: 12px;
  padding: 2px 5px;
}

.column:first-child {
  padding-right: 5px;
  border-right: 1px solid #676769;
}

.twoItem {
  width: calc(100% / 2 - 6px) !important;
}

.odd-name {
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.odd-value {
  width: 30%;
  text-align: right;
}

@media only screen and (max-width: 1559px) and (min-width: 1242px) {
  .all-odds-list {
    display: block;
  }

  .column {
    width: 100%;
    padding: 0 5px 2px 5px;
  }

  .column:first-child {
    padding-top: 2px;
    border-right: 0px;
  }
}
</style>
