<template>
  <div class="goals">
    <div>{{ homeTeamScore }}</div>
    <div>{{ awayTeamScore }}</div>
  </div>
</template>

<script>
export default {
  name: 'Goals',

  props: {
    score: Array
  },

  computed: {
    homeTeamScore() {
      return this.score[0]
    },

    awayTeamScore() {
      return this.score[1]
    }
  }
}
</script>

<style>
.goals {
  text-align: center;
  padding-right: 50%;
}
</style>
