<template>
  <div id="payment-widget" class="widget commission">
    <div class="widget__title">{{ paymentSystem.text }} - Payment Receipt</div>
    <div class="widget__content">
      <div class="payment">
        <div class="payment__header">
          <div class="payment__logo">
            <payment-system-icon
              :paymentSystem="paymentSystem.value"
              :widgetDesign="'new'"
            />
          </div>
        </div>
        <div class="payment__body">
          <div class="payment__item">
            <div class="payment__title">Amount</div>
            <div class="payment__content payment__amount">
              {{ formattedAmount }}
            </div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Payment system</div>
            <div class="payment__content">{{ paymentSystem.text }}</div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Wallet</div>
            <div class="payment__content">{{ walletAddress }}</div>
          </div>
          <div class="payment__item" v-if="isComment">
            <div class="payment__title">{{ commentLabel }}</div>
            <div class="payment__content">{{ payment.comment }}</div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Status</div>
            <div class="payment__content">
              <payment-status :status="payment.status.name" />
            </div>
          </div>
        </div>
      </div>
      <div class="warning">
        <div class="warning__icon">
          <img
            src="../../../assets/secret-payment/warning.svg"
            alt="warning-icon"
            width="40"
            height="40"
          />
        </div>
        <div class="warning__title">Warning</div>
        <div
          class="warning__message"
          :style="{ color: redAlert ? '#992c2c' : '#000' }"
        >
          Send the exact amount, otherwise the transaction may not go through.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentStatus from '../common/PaymentStatus'
import PaymentSystemIcon from '../../common/PaymentSystemIcon'
import ChristmasAmount from '../mixins/ChristmasAmount'

export default {
  name: 'ChristmasDWidget',

  components: {
    PaymentStatus,
    PaymentSystemIcon
  },

  mixins: [ChristmasAmount],

  props: {
    walletAddress: String,
    payment: Object,
    redAlert: Boolean,
    paymentSystem: Object,
    isComment: Boolean,
    commentLabel: String
  }
}
</script>
