<template>
  <div id="profile-widget" class="widget">
    <div class="widget__title">My profile</div>
    <div class="widget__content">
      <div class="profile">
        <form>
          <div class="form__row">
            <div class="form__group">
              <div class="form__label">Name</div>
              <input
                class="form__input"
                type="text"
                v-model="profile.name"
                disabled
                required
              />
            </div>
          </div>
          <div class="form__row">
            <div class="form__group">
              <div class="form__label">Login</div>
              <input
                class="form__input"
                type="email"
                v-model="profile.login"
                disabled
                required
              />
            </div>
          </div>
          <div class="form__row">
            <div class="form__group">
              <div class="form__label">Phone</div>
              <input
                class="form__input"
                type="text"
                v-model="profile.phone"
                disabled
                required
              />
            </div>
          </div>
          <div
            class="form__row"
            v-for="(field, index) in profile.fields"
            :key="index"
          >
            <div class="form__group">
              <div class="form__label">{{ field.name }}</div>
              <input
                class="form__input"
                type="text"
                v-model="field.value"
                disabled
                required
              />
            </div>
          </div>
          <div class="form__row">
            <div class="form__group">
              <div class="form__submit">
                <input type="submit" value="Save" disabled />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProfileInfo',

  computed: {
    ...mapGetters({
      profile: 'PROFILE'
    })
  }
}
</script>

<style>
.profile {
  width: 70%;
  margin: auto;
}

.profile .form__label {
  color: #fff;
  margin-bottom: 5px;
}

.form__submit input[type='submit']:disabled {
  cursor: not-allowed !important;
  border-color: grey;
  color: grey;
}

@media screen and (max-width: 992px) {
  .profile {
    width: 90%;
  }
}
</style>
