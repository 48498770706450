<template>
  <div class="cookies-popup">
    <div class="cookies-popup__container">
      <div class="cookies-popup__icon"></div>
      <div class="cookies-popup__info">
        <div class="cookies-popup__title">Cookie Notice</div>
        <div class="cookies-popup__text">
          {{ siteName }} uses cookies to help improve your experience whilst
          visiting our site, help us with fraud prevention and to fulfil our
          legal and regulatory obligations. Please read our
          <router-link
            class="cookies-popup__link"
            to="/cookies-privacy-notice"
            target="_blank"
          >
            Cookie Policy
          </router-link>
          for further information on how we use information obtained from
          cookies and how to change your cookie settings. By continuing to use
          our website you consent to our use of cookies in accordance with our
          Cookie Policy.
        </div>
      </div>
      <button class="cookies-popup__button" @click="onButtonClick">
        Accept & Close
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['siteName'])
  },

  methods: {
    onButtonClick() {
      localStorage.setItem('accept-cookies', true)
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.cookies-popup {
  width: 100%;

  position: fixed;
  bottom: 10px;
  left: 0;

  padding: 0 10px;
}

.cookies-popup__container {
  padding: 10px 15px;
  margin: 0 auto;

  max-width: 1025px;

  background-color: #fff;

  display: flex;
  align-items: center;

  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.4);
}

.cookies-popup__icon {
  mask-image: url('../assets/cookies.png');
  mask-repeat: no-repeat;
  mask-size: contain;

  background-color: black;

  min-width: 60px;
  height: 60px;

  margin-right: 16px;
}

.cookies-popup__title {
  margin-bottom: 4px;

  font-weight: 700;
  font-size: 14px;
}

.cookies-popup__text {
  font-size: 12px;
}

.cookies-popup__link {
  color: #4343ff;
  text-decoration: underline;
}

.cookies-popup__button {
  margin-left: 16px;

  background: #459a3d;
  color: #fff;

  border: 0;

  padding: 8px 16px;

  cursor: pointer;

  font-size: 14px;

  width: 100%;
  max-width: 180px;
}

.cookies-popup__button:hover {
  background: #017d01;
}

.cookies-popup__button:active {
  background: #060;
}

@media only screen and (max-width: 768px) {
  .cookies-popup {
    bottom: 0;

    padding: 0;
  }

  .cookies-popup__container {
    flex-direction: column;
  }

  .cookies-popup__icon {
    display: none;
  }

  .cookies-popup__text {
    margin-bottom: 8px;
  }

  .cookies-popup__button {
    max-width: 100%;

    margin-left: 0;
  }
}
</style>
