<template
  ><div class="ticket-right">
    <h2 class="widget__title">Request #{{ requestNumber }}</h2>
    <div class="ticket">
      <dl class="ticket__details">
        <dt>Requester</dt>
        <dd>{{ requesterName }}</dd>

        <dt>Created</dt>
        <dd>
          <time>{{ createdDate }}</time>
        </dd>

        <dt>Last activity</dt>
        <dd>
          <time>{{ lastActivityDate }}</time>
        </dd>
      </dl>
      <hr style="border: 1px solid black;" />
      <dl class="ticket__details">
        <dt>Assigned to</dt>
        <dd>{{ supportName }}</dd>

        <dt>Id</dt>
        <dd>#{{ requestNumber }}</dd>

        <dt>Status</dt>
        <dd>
          <span
            :style="{ 'background-color': statusColor }"
            class="ticket__status"
          >
            {{ status }}
          </span>
        </dd>

        <dt>Priority</dt>
        <dd>
          {{ priority }}
        </dd>

        <dt>{{ bkName }} Product or Service</dt>
        <dd>
          {{ serviceOrProduct }}
        </dd>

        <dt>Ticket Type</dt>
        <dd>{{ ticketType }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
const STATUS_COLORS = {
  Open: 'white',
  Success: 'rgb(121, 208, 97)',
  Failed: 'red'
}

export default {
  name: 'ChristmasSWidgetRight',

  props: {
    requestNumber: { type: String, default: '1' },
    requesterName: { type: String, default: '' },
    createdDate: { type: String, default: '' },
    lastActivityDate: { type: String, default: '' },
    supportName: { type: String, default: '' },
    status: { type: String, default: 'Open' },
    priority: { type: String, default: '' },
    bkName: { type: String, default: '' },
    serviceOrProduct: { type: String, default: '' },
    ticketType: { type: String, default: '' }
  },

  computed: {
    statusColor() {
      return STATUS_COLORS[this.status]
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-right {
  width: 40%;
  margin-left: 12px;
  float: left;
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  position: sticky;
  top: 85px;
}

.ticket {
  background-color: #d7d8d8;
  border: 0;
  font-size: 13px;
  flex: 0 0 auto;
  padding: 1px 20px;
  min-height: 440px;

  &__details dt,
  &__details dd {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    margin: 20px 0 0 0;
  }

  &__details dt {
    color: #2f2f2f;
    font-weight: 300;
    width: 40%;
  }

  &__details dd {
    padding: 0 10px;
    width: 60%;
  }

  &__status {
    border-radius: 24px;
    padding: 4px 6px;
  }
}

@media screen and (max-width: 992px) {
  .ticket-right {
    width: 100%;
    margin-left: 0;
    margin-bottom: 12px;
    position: static;
  }
}
</style>
