<template>
  <div class="advantages" id="advantages">
    <advantageItem
      v-for="(item, index) in dataIn"
      :key="index"
      :title="item.title"
      :text="item.text"
      :icon="require(`../assets/advantage-icons/${index + 1}.svg`)"
    />
  </div>
</template>

<script>
import advantageItem from './advantageItem'
import { mapGetters } from 'vuex'

export default {
  name: 'Advantages',

  components: {
    advantageItem
  },

  computed: {
    ...mapGetters(['site']),

    dataIn() {
      return [
        {
          title: this.site.vars.aboutUs.leftTop.title,
          text: this.site.vars.aboutUs.leftTop.description,
          icon: '../assets/advantage-icons/1.svg'
        },
        {
          title: this.site.vars.aboutUs.rightTop.title,
          text: this.site.vars.aboutUs.rightTop.description,
          icon: '../assets/advantage-icons/2.svg'
        },
        {
          title: this.site.vars.aboutUs.leftMiddle.title,
          text: this.site.vars.aboutUs.leftMiddle.description,
          icon: '../assets/advantage-icons/3.svg'
        },
        {
          title: this.site.vars.aboutUs.rightMiddle.title,
          text: this.site.vars.aboutUs.rightMiddle.description,
          icon: '../assets/advantage-icons/4.svg'
        },
        {
          title: this.site.vars.aboutUs.leftBottom.title,
          text: this.site.vars.aboutUs.leftBottom.description,
          icon: '../assets/advantage-icons/5.svg'
        },
        {
          title: this.site.vars.aboutUs.rightBottom.title,
          text: this.site.vars.aboutUs.rightBottom.description,
          icon: '../assets/advantage-icons/6.svg'
        },
        {
          title: this.site.vars.aboutUs.bottom.title,
          text: this.site.vars.aboutUs.bottom.description,
          icon: '../assets/advantage-icons/7.svg'
        }
      ]
    }
  }
}
</script>

<style>
#advantages {
  border-top: 1px solid #7a7a7b;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10% 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin: 30px 0 0;
  overflow: hidden;
  background-image: url('../assets/header-promo/dots.svg'),
    url('../assets/header-promo/dots.svg');
  background-position: -30%, 135%;
  background-size: 33%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  #advantages {
    padding: 20px 0 30px;
  }
}
</style>
