<template>
  <div
    class="odd-name"
    :data-tooltip="this.oddName"
    :class="{ oddNameOversize: this.isEllipsisActive }"
  >
    {{ oddName }}
  </div>
</template>

<script>
export default {
  name: 'OddName',

  props: {
    oddName: String
  },

  data: () => ({
    isEllipsisActive: false
  }),

  mounted() {
    this.isEllipsisActive = this.$el.offsetWidth < this.$el.scrollWidth
  }
}
</script>

<style>
.oddNameOversize::before,
.oddNameOversize::after {
  position: absolute;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.oddNameOversize::before {
  content: '';
  border-width: 6px 5px 0 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
  margin-left: -5px;
  top: -6px;
}

.oddNameOversize::after {
  content: attr(data-tooltip);
  background-color: rgba(255, 255, 255, 1);
  top: -6px;
  font-size: 11px;
  width: 100%;
  white-space: normal;
  text-align: center;
  margin-left: -50%;
  color: #000;
  padding: 1px;
  transform: translateY(-100%);
}

.market__body-cell:hover .oddNameOversize::before,
.market__body-cell:hover .oddNameOversize::after {
  visibility: visible;
  opacity: 1;
}
</style>
