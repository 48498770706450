import collection from 'lodash/collection'
import object from 'lodash/object'
import axios from 'axios'
import moment from 'moment'

const state = {
  inPlay: [],
  upcoming: [],
  activeEvents: 'upcoming',
  filters: {
    sportId: 4
  },
  sportName: 'Football',
  selectedSport: 4,
  loadLine: true,
  selectedEventId: null
}

const getters = {
  loadLine: state => state.loadLine,

  activeEvents: state => state.activeEvents,

  liveEvents: state => state.inPlay,

  upcomingEvents: state => state.upcoming,

  sportIdFilter: state => state.filters.sportId,

  notEmptyLiveList: state => state.inPlay.length,

  notEmptyUpcomingList: state => state.upcoming.length,

  selectedEvents(state) {
    return state.activeEvents == 'inPlay' ? state.inPlay : state.upcoming
  },

  sports(state, getters) {
    let sportsById = {}

    for (let i = 0; i < getters.selectedEvents.length; i++) {
      const event = getters.selectedEvents[i]
      const sportId = event.SportId
      if (
        sportId == 28 ||
        sportId == 12 ||
        sportId == 27 ||
        sportId == 13 ||
        sportId == 45 ||
        sportId == 9 ||
        sportId == 64 ||
        sportId == 40 ||
        sportId == 6
      )
        continue

      if (state.activeEvents == 'upcoming') {
        const nowTime = moment.utc().valueOf()
        const eventTime = moment.utc(event.Date).valueOf()
        if (nowTime > eventTime) continue
      }

      if (!(sportId in sportsById)) {
        sportsById[sportId] = {
          id: sportId,
          name: event.SportName,
          count: 1
        }
      } else {
        sportsById[sportId].count++
      }
    }

    return Object.values(sportsById)
  },

  events(state, getters) {
    let result = getters.selectedEvents
    const currSportId = state.filters.sportId

    const addingEvents = getters.addingEvents

    if (result.length && !!addingEvents) {
      let existEvent = result.find(event => {
        return event.SportId === 4
      })

      if (existEvent) {
        const [datePart] = existEvent.Date.split('T')

        const updatedAddingEvents = addingEvents.map((event, index) => {
          const newEvent = {
            ...existEvent,
            LeagueName: event.leagueName,
            RegionName: event.regionName,
            HomeTeam: event.homeTeam,
            AwayTeam: event.awayTeam,
            Id: `x1000${index}`,
            Date: `${datePart}T${event.time}Z`
          }

          if (existEvent.Scoreboard) {
            newEvent.Scoreboard = {
              ...existEvent.Scoreboard,
              score: event.score
            }
          }

          return newEvent
        })

        result = [...result, ...updatedAddingEvents]
      }
    }

    if (state.activeEvents == 'upcoming') {
      result = result.filter(event => {
        const nowTime = moment.utc().valueOf()
        const eventTime = moment.utc(event.Date).valueOf()
        return nowTime < eventTime
      })
    }

    return (result = result.filter(event => event.SportId == currSportId))
  },

  leagueGrouping(state, getters) {
    const events = getters.events

    if (events.length > 0) {
      var grouped = collection.groupBy(events, function(event) {
        return event.LeagueName
      })

      const arr = object.values(grouped)

      return arr
    }
  },

  loadStatus(state) {
    return state.inPlay.length === 0 || state.upcoming.length === 0
  },

  selectedEventId: state => state.selectedEventId
}

const mutations = {
  setLoadLine: (state, val) => (state.loadLine = val),

  loadInPlayEvents(state, payload) {
    state.inPlay = payload
  },

  loadUpcomingEvents(state, payload) {
    state.upcoming = payload

    if (!state.upcoming.length) {
      state.activeEvents = 'inPlay'
    }
  },

  eventsSelection(state, name) {
    state.activeEvents = name
    state.filters.sportId = 4
  },

  onSportFilterClick(state, sport) {
    state.filters.sportId = sport.id
    state.sportName = sport.name
    state.selectedSport = sport.id
  },

  selectEvent(state, id) {
    state.selectedEventId = id
  }
}

const actions = {
  getLineData: async ({ commit }) => {
    let one = '/line/updated-list'
    let two = '/line/upcoming-events'

    const requestOne = axios.get(one)
    const requestTwo = axios.get(two)

    await axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          commit('loadInPlayEvents', responses[0].data.results)
          commit('loadUpcomingEvents', responses[1].data.results)
        })
      )
      .catch(errors => {
        console.log(errors)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
