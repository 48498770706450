<template>
  <router-link to="/casino" class="banner">
    <div class="banner__header">
      <div class="banner__photo">
        <button class="banner__button">
          play now
        </button>
      </div>
    </div>
    <div class="banner__content">
      <div class="banner__item game">
        <div class="game__img">
          <div class="roulette"></div>
        </div>
        <div class="game__name">Roulette</div>
        <div class="game__arrow">
          <img
            src="../../assets/right-arrow.svg"
            alt="right-arrow"
            width="12"
            height="12"
          />
        </div>
      </div>
      <div class="banner__item game">
        <div class="game__img">
          <div class="blackjack"></div>
        </div>
        <div class="game__name">Blackjack</div>
        <div class="game__arrow">
          <img
            src="../../assets/right-arrow.svg"
            alt="right-arrow"
            width="12"
            height="12"
          />
        </div>
      </div>
      <div class="banner__item game">
        <div class="game__img">
          <div class="hi-lo"></div>
        </div>
        <div class="game__name">Hi Lo</div>
        <div class="game__arrow">
          <img
            src="../../assets/right-arrow.svg"
            alt="right-arrow"
            width="12"
            height="12"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CasinoBanner'
}
</script>

<style>
.banner__header {
  overflow: hidden;
}

.banner__photo {
  height: 150px;
  width: 100%;
  background: url('../../assets/casino/banner/jackpot.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  display: flex;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.banner__button {
  text-transform: uppercase;
  background: none;
  border-radius: 20px;
  height: 30px;
  width: 120px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #fff;
  margin: auto 0 0 auto;
  cursor: pointer;
  display: block;
}

.banner__photo:hover {
  transform: scale(1.1);
}

.banner__photo:hover .banner__button {
  background: #fff;
  color: #000;
}

.banner__content {
  padding: 5px;
  background: #29292b;
}

.banner__item {
  background: #444444;
  cursor: pointer;
}

.banner__item:not(:last-child) {
  margin-bottom: 5px;
}

.banner .game {
  display: flex;
  align-items: center;
  padding-right: 10px;
  overflow: hidden;
}

.banner__item:hover {
  background: #828282;
}

.banner__item .game__img {
  overflow: hidden;
}

.banner__item .game__name {
  color: #fff;
  font-size: 18px;
  padding-left: 10px;
}

.banner__item .game__arrow {
  margin: 0 0 0 auto;
}

.banner__item .game__arrow img {
  filter: brightness(0) invert(1);
}

.game:hover .roulette,
.game:hover .blackjack,
.game:hover .hi-lo {
  transform: scale(1.2);
}

.roulette,
.blackjack,
.hi-lo {
  height: 60px;
  width: 170px;
  transition: all 0.2s ease-in-out;
}

.roulette {
  background: url('../../assets/casino/banner/roulette.jpg') center no-repeat;
  background-size: cover;
}

.blackjack {
  background: url('../../assets/casino/banner/blackjack.jpeg') top no-repeat;
  background-size: cover;
}

.hi-lo {
  background: url('../../assets/casino/banner/hi-lo.jpg') top no-repeat;
  background-size: cover;
}

@media screen and (max-width: 992px) {
  /* .banner {
    display: none;
  } */
}
</style>
