<template>
  <div id="transactions-widget" class="widget transactions">
    <div class="widget__title">Transaction History</div>
    <div class="widget__content">
      <transactions-desktop
        v-if="transactions.length && !mobile"
        :transactions="transactions"
      />
      <transactions-mobile
        v-if="transactions.length && mobile"
        :transactions="transactions"
      />
      <div class="empty-content" v-if="!transactions.length">
        NO TRANSACTIONS AVAILABLE
      </div>
    </div>
    <resizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionsDesktop from './TransactionsDesktop'
import TransactionsMobile from './TransactionsMobile'
import 'vue-resize/dist/vue-resize.css'
import { ResizeObserver } from 'vue-resize'

export default {
  name: 'Transactions',

  components: {
    TransactionsDesktop,
    TransactionsMobile,
    ResizeObserver
  },

  data: () => ({
    mobile: false
  }),

  computed: {
    ...mapGetters({
      transactions: 'TRANSACTIONS'
    })
  },

  mounted() {
    this.handleResize()
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 760) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    }
  }
}
</script>

<style>
.pa-content-section .widget__title {
  font-size: 15px;
  font-weight: 500;
}

#transactions-widget .widget__content,
#coupons-widget .widget__content {
  padding: 10px 10px 5px;
}

.transactions__table,
.coupons__table {
  width: 100%;
}

table {
  border-collapse: collapse;
}

.transactions__header td {
  text-align: center;
  font-size: 13px;
  color: #949495;
}

.transaction {
  height: 40px;
}

.transaction:not(:last-child),
.coupon:not(:last-child) {
  border-bottom: 1px solid gray;
}

.transaction td {
  font-size: 13px;
  color: #fff;
  text-align: center;
  border: 0;
  padding: 0 10px;
}

.empty-content {
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 20px;
  font-size: 13px;
  background: #29292b;
}

.transaction__amount .unfolding-icon-up,
.transaction__amount .unfolding-icon-down {
  display: none;
}
</style>
