<template>
  <div class="sets">
    <div class="home-team-score">
      <div
        class="set-points"
        :class="{ 'current-set-points': index === currentSetIndex }"
        v-for="(setPoints, index) in homeTeamSets"
        :key="index"
      >
        {{ setPoints }}
      </div>
    </div>
    <div class="away-team-score">
      <div
        class="set-points"
        :class="{ 'current-set-points': index === currentSetIndex }"
        v-for="(setPoints, index) in awayTeamSets"
        :key="index"
      >
        {{ setPoints }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sets',

  props: {
    sets: Array
  },

  computed: {
    homeTeamSets() {
      return this.sets[0]
    },

    awayTeamSets() {
      return this.sets[1]
    },

    currentSetIndex() {
      let nextSetIndex = this.sets[0].indexOf('-')
      const currentSetIndex =
        nextSetIndex > 0
          ? --nextSetIndex
          : nextSetIndex === 0
          ? null
          : this.sets[0].length - 1
      return currentSetIndex
    }
  }
}
</script>

<style>
.sets,
.points-with-sets {
  display: table;
}

.home-team-score,
.away-team-score {
  display: flex;
  color: #949495;
  display: table-row;
  font-weight: 400;
}

.set-points,
.points-with-sets .points {
  display: table-cell;
  text-align: center;
  padding: 0px 2px;
}

.current-set-points {
  color: #fff !important;
}
</style>
