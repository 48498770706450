<template>
  <div class="casino__games">
    <game v-for="(image, index) in images" :key="index" :gameImg="image" />
    <resize-observer @notify="recountItems" />
  </div>
</template>

<script>
import Game from './Game'
import { mapGetters } from 'vuex'
import { ResizeObserver } from 'vue-resize'

export default {
  name: 'GameGrid',

  components: {
    Game,
    ResizeObserver
  },

  data: () => ({
    images: null
  }),

  computed: {
    ...mapGetters(['casinoFilter']),

    gameImages() {
      let images
      if (this.casinoFilter == 'top') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/top/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == 'new') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/new/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == 'slots') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/slots/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == 'jackpots') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/jackpots/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == '3dslots') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/3dslots/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == 'table') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/table/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else if (this.casinoFilter == 'videopoker') {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/videopoker/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      } else {
        images = this.importAllImages(
          require.context(
            '../../../assets/casino/other/',
            false,
            /\.(png|jpe?g)$/
          )
        )
      }
      const shuffledImages = this.shuffle(images)
      return shuffledImages
    }
  },

  mounted() {
    this.recountItems()
  },

  watch: {
    casinoFilter() {
      this.recountItems()
    }
  },

  methods: {
    importAllImages(r) {
      return r.keys().map(r)
    },

    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    },

    recountItems() {
      const windowWidth = window.innerWidth
      let numberOfItemsPerLine
      if (windowWidth > 1240) {
        numberOfItemsPerLine = 5
      } else if (windowWidth <= 1240 && windowWidth > 992) {
        numberOfItemsPerLine = 4
      } else if (windowWidth <= 992 && windowWidth > 576) {
        numberOfItemsPerLine = 3
      } else {
        numberOfItemsPerLine = 2
      }
      const divisionResidues = this.gameImages.length % numberOfItemsPerLine
      this.images = this.gameImages.slice(
        0,
        this.gameImages.length - divisionResidues
      )
    }
  }
}
</script>

<style>
.casino__games {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #151516;
}
</style>
