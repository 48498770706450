function preloadImage(url) {
  return new Promise((resolve, reject) => {
    let image = new Image()
    image.src = url

    image.onload = () => resolve(url)
    image.onerror = err => reject(err)
  })
}

export default preloadImage
