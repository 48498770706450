<template>
  <div class="news-block" v-if="loadNews"><preloader /></div>
  <div class="news-block" id="news-block" v-else>
    <div class="news-block__title">NEWS</div>
    <div class="news-wrapper">
      <div v-for="(news, index) in newsArray" :key="index" class="news">
        <img
          class="news__image"
          :src="news.image"
          v-if="news.image"
          onerror="this.style.display='none'"
        />
        <div class="news__content">
          <div class="news__date">
            <div>{{ news.published_at | fillterTime }}</div>
            <div class="news__month">
              {{ news.published_at | fillterDate }}
            </div>
          </div>
          <div class="news__text">
            <div class="news__title">{{ news.author }}</div>
            <a class="news__source" :href="news.url" target="_blank">{{
              news.source
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import preloader from '../Line/preloader.vue'

export default {
  name: 'NewsBlock',

  components: {
    preloader
  },

  filters: {
    fillterTime: function (value) {
      if (!value) return ''
      const [date, time] = value.split(' ')

      return moment(`${date} ${time}`).format('HH:mm')
    },

    fillterDate: function (value) {
      if (!value) return ''
      const [date, time] = value.split(' ')

      return moment(`${date} ${time}`).format('DD/MM')
    }
  },

  computed: {
    ...mapGetters({ newsArray: 'newsArray', loadNews: 'loadNews' })
  },

  async mounted() {
    await this.fetchNews()
  },

  methods: {
    ...mapActions({ fetchNews: 'fetchNews' })
  }
}
</script>

<style lang="scss" scoped>
.news-block {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 500px;
  max-height: 500px;
  position: relative;
  overflow-x: hidden;

  &__title {
    position: relative;
    display: flex;
    background: #151516;
    min-height: 36px;
    align-items: center;
    font-size: 100%;
    padding: 0px 30px 0 30px;
    color: #fff;
  }
}
.news-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
}
.news {
  background-color: #28282b;

  &:not(:last-child) {
    border-bottom: 2px solid #49494a;
  }

  &__image {
    width: 100%;
  }

  &__content {
    padding: 12px;
    display: flex;
    color: #fff;
  }

  &__date {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__month {
    color: grey;
  }

  &__title {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__source {
    font-style: italic;
  }
}

// @media only screen and (max-width: 992px) {
//   .news-block {
//     height: 360px;
//   }
// }
</style>
