import types from './types'
export default {
  show({ commit }, modal) {
    commit(types.SHOW_MODAL, modal)
  },

  close({ commit }, modal) {
    commit(types.CLOSE_MODAL, modal)
  }
}
