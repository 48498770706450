<template>
  <div class="column">
    <div
      class="odds-group"
      v-for="(groupItem, index) in this.groupingByName"
      :key="index"
    >
      <div class="odds-group__title">{{ groupItem[0].name.value }}</div>
      <div class="market" v-for="market in groupItem" :key="market.id">
        <div class="market__body d-flex">
          <button
            class="market__body-cell odd-btn d-flex-between"
            v-for="odd in market.results"
            :key="odd.id"
            :class="{
              twoItem: market.results.length === 2,
              oddBtnSelected: bets.find(bet => bet.id == odd.id)
            }"
            @click="addBet(odd, market)"
          >
            <odd-name :oddName="odd.name.value" :oddId="odd.id" />
            <div class="odd-value">{{ odd.odds }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OddName from './OddName'
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    groupingByName: Array,
    event: Object
  },

  components: {
    OddName
  },

  computed: {
    ...mapGetters(['bets'])
  },

  methods: {
    ...mapMutations(['addBetToCoupon']),

    addBet(odd, market) {
      const bet = {
        id: odd.id,
        eventId: this.event.Id,
        name: odd.name.value,
        market: market.name.value,
        event: this.event.HomeTeam + ' - ' + this.event.AwayTeam,
        odd: odd.odds
      }
      this.addBetToCoupon(bet)
    }
  }
}
</script>
