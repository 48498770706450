<template>
  <div class="privacy-notice">
    <h2 class="privacy-notice__title">Cookie Privacy Notice</h2>
    <table class="privacy-notice__table">
      <tr>
        <td>Cookies which are strictly necessary</td>
        <td>
          <p>
            These cookies are essential to provide you with the services
            available to and requested by you through
            <router-link class="privacy-notice__link" to="/">{{
              site
            }}</router-link
            >.
          </p>
          <p>
            These cookies are also essential to enable you to use some of the
            features of our website(s) and apps, including access to secure
            areas (such as payment pages).
          </p>
          <p>
            Without these cookies, services you have asked for via our website
            or app, such as access to transactional pages, secure login accounts
            would not be possible.
          </p>
          <p>
            We also use cookies to track transactions in order to enable us to
            comply with our legal and regulatory obligations, with respect to
            Money Laundering and Safer Gambling.
          </p>
          <p>
            We may also use functionality cookies when strictly necessary to
            record information about choices you've made on previous visits to
            our website, in order to comply with our legal and regulatory
            obligations.
          </p>
        </td>
      </tr>
      <tr>
        <td>Performance, Experience and Analytics Cookies</td>
        <td>
          <p>
            We use performance/analytics cookies to analyse how our websites and
            apps are accessed, used, or performing in order to provide you with
            a better user experience and to maintain, operate and continually
            improve our website.
          </p>
          <p>
            Some of our cookies record information about choices you've made on
            previous visits to our website, and allow us to tailor our website
            to you.
          </p>
          <p>
            These cookies mean that when you continue to use or come back to our
            website, we can provide you with our services as you have asked for
            them to be provided.
          </p>
          <p>
            We use Google Analytics, which is a web analytics tool that help us
            understand how users engage with our website.
          </p>
          <p>
            Like many services, Google Analytics use first-party cookies to
            track user interactions.
          </p>
          <p>
            These cookies allow us (via such third parties) to collect
            information about how users use our website.
          </p>
          <p>
            This information is used to compile reports (which disclose website
            trends but without identifying individual visitors) and to help us
            improve our website.
          </p>
          <p>
            Performance and analytics cookies allow us to continually review and
            improve the layout and functionality of our website to give you the
            best experience, including:
          </p>
          <ul>
            <li>
              better understand the way visitors use and interact with our
              website so that we can improve how we present our content;
            </li>
            <li>
              use the data collected by such cookies to experiment with
              different design ideas for particular pages, such as our homepage;
            </li>
            <li>
              collect information about visitors to our website, such as what
              devices and browsers our visitors use;
            </li>
            <li>determine the number of unique users of our website;</li>
            <li>improve our website by measuring any errors that occur;</li>
            <li>
              conduct research and diagnostics to improve product offerings.
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Advertising and Targeting Cookies</td>
        <td>
          <p>
            We allow third party companies, including advertising companies, to
            place cookies on our website.
          </p>
          <p>
            These cookies enable such companies to track your activity across
            various sites where they display adverts and record your activities
            so they can show adverts and promotions that they consider relevant
            to you as you browse the internet.
          </p>
          <p>
            Cookies also allow us and third parties to know whether you have
            seen an advert (or a type of advert), and how long it has been since
            you have last seen it.
          </p>
          <p>
            This information is used for frequency capping purposes, to help
            tailor the adverts you see, and to measure the effectiveness of
            adverts.
          </p>
          <p>
            We can use information from one device to help personalise your
            experience on another device.
          </p>
        </td>
      </tr>
      <tr>
        <td>Research Cookies</td>
        <td>
          <p>
            We use research cookies to gather customer feedback on our website
            which enables us to process customer opinion on our website and
            apps.
          </p>
          <p>
            We use a third party, Usabilla, to provide the feedback tool that we
            use, and allows us to better understand our customers so that we
            can:
          </p>
          <ul>
            <li>Improve the website accordingly; and</li>
            <li>
              Conduct research and diagnostics to improve product offerings.
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Third Party Cookies</td>
        <td class="privacy-notice__last-col">
          <ul>
            <li>Tealium Collect</li>
            <li>Tealium AudienceStream</li>
            <li>Adobe Analytics</li>
            <li>Google Analytics</li>
            <li>Content Square</li>
            <li>Optimizely</li>
            <li>Usabilla</li>
            <li>Google Floodlight Conversions</li>
            <li>DCM Retargeting</li>
            <li>AppNexus Retargeting & Conversion</li>
            <li>Facebook & Instagram Retargeting</li>
            <li>The Tradedesk Retargeting & Conversion</li>
          </ul>
          <ul>
            <li>Twitter Retargeting</li>
            <li>Snapchat Retargeting</li>
            <li>AdServer Retargeting</li>
            <li>Bing Retargeting</li>
            <li>Yahoo Retargeting</li>
            <li>Fresh 8 Retargeting</li>
            <li>AdForm Retargeting (Italy)</li>
            <li>Quantcast Retargeting & Conversion</li>
            <li>DCM Conversion</li>
            <li>AppsFlyer Conversions</li>
            <li>VIQ Attribution Pixel</li>
            <li>Voila</li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'CookiesPrivacyNotice',

  data: () => ({
    site: window.location.hostname
  })
}
</script>

<style scoped>
.privacy-notice {
  color: #fff;

  margin: 70px auto 50px;

  max-width: 1024px;
}

.privacy-notice__title {
  text-align: center;
  margin-bottom: 24px;

  font-weight: 700;
}

.privacy-notice__link {
  color: #7272fd;
  text-decoration: underline;
}

.privacy-notice__last-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

table {
  border-collapse: collapse;

  font-size: 14px;
}

td {
  border: 1px solid #fff;

  padding: 10px;
}

td:first-child {
  font-weight: 700;

  vertical-align: top;
}

p {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  list-style-type: circle;
  padding-left: 30px;
}

li:not(:last-child) {
  margin-bottom: 4px;
}

@media only screen and (max-width: 768px) {
  table {
    font-size: 12px;
  }

  .privacy-notice__last-col {
    grid-template-columns: 1fr;
  }
}
</style>
