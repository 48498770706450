<template>
  <div class="coupon__commission">{{ commission }}</div>
</template>

<script>
export default {
  name: 'BetCommission',

  props: {
    stake: Object,
    odds: Number,
    fee: Number,
    betStatus: String,
    couponBets: Array
  },

  computed: {
    isAllBetsCanceled() {
      return this.couponBets.every(bet => bet.status === 'canceled')
    },

    commission() {
      if (this.betStatus === 'win') {
        if (this.isAllBetsCanceled) {
          return (0).toFixed(2) + ' ' + this.stake.currency
        }
        return (
          ((this.stake.amount / 100) * this.odds * (this.fee / 100)).toFixed(
            2
          ) +
          ' ' +
          this.stake.currency
        )
      }
      return '-'
    }
  }
}
</script>
