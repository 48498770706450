<template>
  <div class="coupons__table">
    <div class="coupons__header">
      <div
        class="column"
        v-for="(header, index) in headers"
        :key="index"
        v-html="header"
      ></div>
      <div class="column"></div>
    </div>
    <div
      class="coupons__row coupon"
      v-for="(coupon, index) in bets"
      :key="index"
    >
      <div class="coupon__main">
        <div class="coupon__numbet column">№ {{ coupon.id }}</div>
        <div class="coupon__status column">
          <status-icon :status="coupon.status" :width="20" :height="20" />
        </div>
        <div class="coupon__created-date column">
          <formatted-date :date="coupon.at" />
        </div>
        <bet-type class="column" :type="coupon.type" />
        <div class="coupon__odds column">{{ coupon.odds }}</div>
        <bet-stake class="column" :stake="coupon.stake" />
        <bet-result
          class="column"
          :stake="coupon.stake"
          :odds="coupon.odds"
          :betStatus="coupon.status"
          :couponBets="coupon.bets"
        />
        <bet-commission
          class="column"
          :stake="coupon.stake"
          :odds="coupon.odds"
          :fee="coupon.fee"
          :betStatus="coupon.status"
          :couponBets="coupon.bets"
        />
        <div
          class="coupon__details column"
          @click="addSelectedCoupon(coupon.id)"
        >
          <unfolding-icon :item="coupon.id" :itemList="selectedCoupons" />
        </div>
      </div>
      <div class="coupon__bets bet" v-if="selectedCoupons.includes(coupon.id)">
        <table class="bet__table">
          <thead class="bet__header">
            <tr>
              <td v-for="(header, index) in betHeaders" :key="index">
                {{ header }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bet__details"
              v-for="(bet, index) in coupon.bets"
              :key="index"
            >
              <td class="bet__sport">{{ bet.sport }}</td>
              <td class="bet__country">{{ bet.country }}</td>
              <td class="bet__league">{{ bet.league }}</td>
              <td class="bet__event">{{ bet.event }}</td>
              <td class="bet__event-date">
                <formatted-date :date="bet.date" />
              </td>
              <td class="bet__name">{{ bet.name }}</td>
              <td class="bet__bet-on">{{ bet.betOn }}</td>
              <td class="bet__odds">{{ bet.odds }}</td>
              <td class="bet__result">{{ bet.result }}</td>
              <td class="bet__status">
                <status-icon :status="bet.status" :width="15" :height="15" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BetResult from './BetResult'
import BetStake from './BetStake'
import BetCommission from './BetCommission'
import BetType from './BetType'
import StatusIcon from '../../common/StatusIcon'
import FormattedDate from '../../common/FormattedDate'
import UnfoldingIcon from '../../common/UnfoldingIcon'

export default {
  name: 'BetsDesktop',

  components: {
    BetResult,
    BetStake,
    BetCommission,
    StatusIcon,
    FormattedDate,
    UnfoldingIcon,
    BetType
  },

  props: {
    bets: Array
  },

  data: () => ({
    headers: [
      'Coupon number',
      'Bet slip status',
      'Placed',
      'Bet type',
      'Odds',
      'Bet',
      'Win',
      'Tax payment'
    ],
    betHeaders: [
      'Sports',
      'Country',
      'League',
      'Event',
      'Event Date',
      'Name',
      'Bet on',
      'Odds',
      'Result',
      'Status'
    ],
    selectedCoupons: []
  }),

  methods: {
    addSelectedCoupon(couponId) {
      const currCouponIndex = this.selectedCoupons.indexOf(couponId)
      if (currCouponIndex === -1) {
        this.selectedCoupons.push(couponId)
      } else {
        this.selectedCoupons.splice(currCouponIndex, 1)
      }
    }
  }
}
</script>
