<template
  ><div id="support-widget" class="widget widget_support">
    <h2 class="widget__title">
      Withdrawal issue on guest account ID {{ accountId }}
    </h2>

    <div class="widget__content support">
      <div class="messages-box">
        <div
          class="message-box"
          v-for="(message, index) in messages"
          :key="index"
        >
          <div class="user">
            <div class="user__img">
              <template v-if="message.isSupport">
                <span class="icon-agent"></span>
                <img
                  class="user__avatar"
                  src="../../../assets/support/consult.jpg"
                  alt="support_avatar"
                />
              </template>
              <img
                v-else
                class="user__avatar"
                src="../../../assets/support/profile.png"
                alt="user_avatar"
              />
            </div>
            <div class="user__info">
              <div class="user__name">
                {{ message.isSupport ? supportName : requesterName }}
              </div>
              <time class="user__date">{{ message.time }}</time>
            </div>
          </div>
          <div class="message">
            <div class="message__main">{{ message.text }}</div>
            <div class="message__details" v-if="message.isBottomMessage">
              Always bet directly on our website:
              <a href="#" traget="_blank">{{ bkLink }}</a>
              "Open" means we replied - we still work on your issue until it is
              resolved.
            </div>
          </div>
        </div>
      </div>
      <div class="text-input">
        <textarea
          class="text-input__textarea"
          placeholder="Your message..."
          rows="3"
        ></textarea>
        <div class="text-input__btn">Send</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChristmasSWidget',

  props: {
    accountId: { type: String, default: '43221' },
    requesterName: { type: String, default: '' },
    supportName: { type: String, default: '' },
    bkLink: { type: String, default: '' },
    messages: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss">
.widget_support {
  width: 100%;
}

.support {
  background: #444444;
  display: flex;
  flex-direction: column;

  a {
    color: #6c6dbd;
  }
}

.message-box {
  padding: 12px 30px 0;
  color: #fff;
  font-weight: 500;
}

.icon-agent::before {
  background-color: #333333;
  border: 2px solid #514646;
  border-radius: 50%;
  bottom: -1px;
  color: #ffffff;
  content: '👤';
  font-size: 9px;
  height: 16px;
  line-height: 13px;
  position: absolute;
  right: 11px;
  text-align: center;
  width: 16px;
}

.user {
  display: flex;
  margin-bottom: 20px;

  &__img {
    display: inline-block;
    position: relative;
  }

  &__avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }

  &__name {
    font-size: 15px;
  }

  &__date {
    font-size: 13px;
    color: #9e9e9e;
  }
}

.message {
  border-bottom: 1px solid #9e9e9e;

  &__main {
    margin-bottom: 20px;
    width: 100%;
    white-space: pre-wrap;
    font-family: 'Montserrat';
  }

  &__details {
    border-top: 2px dotted #9e9e9e;
    padding: 20px 0;
  }
}

.text-input {
  padding: 0px 30px;

  &__textarea {
    resize: none;
    width: 100%;

    outline: 1px solid black;
  }

  &__btn {
    width: 100px;
    color: #000;
    background: #fff;
    height: 100%;
    border-radius: 25px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    margin: 12px auto;
  }
}
</style>
