<template>
  <span>{{ formattedMoney }}</span>
</template>

<script>
export default {
  name: 'Money',

  props: {
    money: Object
  },

  computed: {
    formattedMoney() {
      return (this.money.amount / 100).toFixed(2) + ' ' + this.money.currency
    }
  }
}
</script>
