<template>
  <div class="bet d-flex">
    <div class="bet__description">
      <div class="bet__description-name">{{ bet.name }}</div>
      <div class="bet__description-market">{{ bet.market }}</div>
      <div class="bet__description-event">{{ bet.event }}</div>
    </div>
    <div class="bet__odd">{{ bet.odd }}</div>
    <div class="bet__delete">
      <div @click="removeOdd(bet.id)">x</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'CouponBet',

  props: {
    bet: Object
  },

  methods: {
    ...mapMutations(['removeOdd'])
  }
}
</script>

<style>
#bid-configurator .widget__body .bet {
  background-color: #a9a9a9;
  padding: 10px 20px;
  position: relative;
  color: #f7f7f7;
  /* border-bottom: 1px solid #fff; */
}

#bid-configurator .widget__body .bet:not(:last-child) {
  border-bottom: 1px solid #fff;
}

#bid-configurator .bet:last-child {
  border: 0;
}

.bet__description {
  width: 75%;
}

.bet__description-name {
  font-weight: 500;
  font-size: 13px;
}

.bet__description-market {
  font-style: italic;
  font-size: 10px;
  line-height: 0.9;
}

.bet__description-event {
  font-size: 11px;
  line-height: 1;
  margin-top: 2px;
}

.bet__odd {
  background: #2b2b29;
  border: none;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet__delete {
  width: 5%;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet__delete div {
  cursor: pointer;
}
</style>
