<template>
  <div class="mobile-coupons">
    <div
      class="coupon"
      v-for="(coupon, index) in bets"
      :key="index"
      :class="{ 'active-item': selectedCoupons.includes(coupon.id) }"
    >
      <div class="coupon__title">
        <div class="content-line">
          <div class="coupon-title">
            <div class="coupon__numbet">№{{ coupon.id }}</div>
            <div class="coupon__created-date">
              <formatted-date :date="coupon.at" />
            </div>
          </div>
          <div class="coupon-title">
            <div class="coupon__odds">{{ coupon.odds }}</div>
            <div class="coupon__status">
              <status-icon :status="coupon.status" :width="15" :height="19" />
            </div>
          </div>
        </div>
      </div>
      <div class="coupon__content">
        <div class="content-line">
          <div class="content-title">Bet type:</div>
          <bet-type class="item-content" :type="coupon.type" />
        </div>
        <div class="content-line">
          <div class="content-title">Bet:</div>
          <bet-stake class="item-content" :stake="coupon.stake" />
        </div>
        <div class="content-line">
          <div class="content-title">Win:</div>
          <bet-result
            class="item-content"
            :stake="coupon.stake"
            :odds="coupon.odds"
            :betStatus="coupon.status"
            :couponBets="coupon.bets"
          />
        </div>
        <div class="content-line">
          <div class="content-title">Tax payment:</div>
          <bet-commission
            class="item-content"
            :stake="coupon.stake"
            :odds="coupon.odds"
            :fee="coupon.fee"
            :betStatus="coupon.status"
            :couponBets="coupon.bets"
          />
        </div>
      </div>
      <div class="coupon__bets" v-if="selectedCoupons.includes(coupon.id)">
        <div class="bet" v-for="(bet, index) in coupon.bets" :key="index">
          <div class="content-line">
            <div class="content-title">Sports:</div>
            <div class="bet__sport">{{ bet.sport }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Country:</div>
            <div class="bet__country">{{ bet.country }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">League:</div>
            <div class="bet__league">{{ bet.league }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Event:</div>
            <div class="bet__event">{{ bet.event }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Event Date:</div>
            <div class="bet__event-date">
              <formatted-date :date="bet.date" />
            </div>
          </div>
          <div class="content-line">
            <div class="content-title">Name:</div>
            <div class="bet__name">{{ bet.name }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Bet on:</div>
            <div class="bet__bet-on">{{ bet.betOn }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Odds:</div>
            <div class="bet__odds">{{ bet.odds }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Result:</div>
            <div class="bet__result">{{ bet.result }}</div>
          </div>
          <div class="content-line">
            <div class="content-title">Status:</div>
            <div class="bet__status">
              <status-icon :status="bet.status" :width="15" :height="15" />
            </div>
          </div>
        </div>
      </div>
      <button class="coupon__action" @click="addSelectedCoupon(coupon.id)">
        <unfolding-icon :item="coupon.id" :itemList="selectedCoupons" />
      </button>
    </div>
  </div>
</template>

<script>
import BetResult from './BetResult'
import BetStake from './BetStake'
import BetCommission from './BetCommission'
import BetType from './BetType'
import StatusIcon from '../../common/StatusIcon'
import FormattedDate from '../../common/FormattedDate'
import UnfoldingIcon from '../../common/UnfoldingIcon'

export default {
  name: 'BetsMobile',

  components: {
    BetResult,
    BetStake,
    BetCommission,
    StatusIcon,
    FormattedDate,
    UnfoldingIcon,
    BetType
  },

  props: {
    bets: Array
  },

  data: () => ({
    selectedCoupons: []
  }),

  methods: {
    addSelectedCoupon(couponId) {
      const currCouponIndex = this.selectedCoupons.indexOf(couponId)
      if (currCouponIndex === -1) {
        this.selectedCoupons.push(couponId)
      } else {
        this.selectedCoupons.splice(currCouponIndex, 1)
      }
    }
  }
}
</script>

<style>
.mobile-coupons .coupon .coupon__action:hover {
  background: #444444;
}

.mobile-coupons .coupon-title:last-child {
  text-align: right;
}

.mobile-coupons .coupon .coupon__title .coupon-title div {
  font-size: 15px !important;
}

.mobile-coupons .coupon__bets {
  padding: 0;
  background: #353535;
}

.mobile-coupons .coupon__action {
  width: 100%;
  border: 0;
  cursor: pointer;
  background: #29292b;
}

.mobile-coupons .coupon__status {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.mobile-coupons .coupon__bets .bet {
  padding: 5px 0;
}

.mobile-coupons .coupon__bets .bet:not(:last-child) {
  border-bottom: 1px solid #808080;
}

.active-item {
  outline: 1px solid #808080;
  border: 0 !important;
}

.coupon__action {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
