import Axios from 'axios'

const state = {
  token: localStorage.getItem('token') || '',
  status: '',
  currentClient: null,
  betsStatusFilter: 'all'
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  CURRENT_CLIENT: state => state.currentClient,
  TRANSACTIONS: state =>
    state.currentClient.transactions.sort((a, b) => -(a.at - b.at)),
  PROFILE: state => {
    return {
      login: state?.currentClient?.login,
      name: state?.currentClient?.name,
      phone: state?.currentClient?.phone,
      fields: state?.currentClient?.fields
    }
  },
  BALANCE: state => state?.currentClient?.balance,
  BETS: state => {
    const sortCoupons = state.currentClient.coupons.sort(
      (a, b) => -(a.id - b.id)
    )
    sortCoupons.forEach(coupon => {
      coupon.odds = 1
      for (let i = 0; i < coupon.bets.length; i++) {
        if (coupon.bets[i].status != 'canceled') {
          coupon.odds *= coupon.bets[i].odds
        }
      }
    })
    return sortCoupons
  },
  FILTERED_BETS: (state, getters) => {
    const sortCoupons = getters.BETS
    const filter = state.betsStatusFilter
    return filter == 'all'
      ? sortCoupons
      : sortCoupons.filter(bet => bet.status === filter)
  },
  FEE: state => state.currentClient.settings.fee,
  MIN_BET: state => state?.currentClient?.settings?.minBet,
  EXCHANGE_RATE: state => state?.currentClient?.settings?.exchangeRate
}

const mutations = {
  CLEAR_TOKEN: state => (state.token = ''),
  AUTH_REQUEST: state => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  AUTH_ERROR: state => {
    state.status = 'error'
  },
  SET_CURRENT_CLIENT: (state, client) => (state.currentClient = client),
  SET_BETS_STATUS_FILTER: (state, val) => (state.betsStatusFilter = val)
}

const actions = {
  GET_TOKEN: ({ state, commit }) => {
    if (state.token != '')
      Axios.defaults.headers.common['X-Token'] = state.token
    commit('AUTH_REQUEST')

    return new Promise((resolve, reject) => {
      Axios.get('/api/token')
        .then(() => {
          resolve()
        })
        .catch(() => {
          localStorage.removeItem('token')
          delete Axios.defaults.headers.common['X-Token']
          commit('CLEAR_TOKEN')
          commit('AUTH_ERROR')
          reject()
        })
    })
  },

  AUTH_REQUEST: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      Axios.post('/api/token', user)
        .then(resp => {
          const token = resp.data.raw
          localStorage.setItem('token', token)
          Axios.defaults.headers.common['X-Token'] = token
          commit('AUTH_SUCCESS', token)
          resolve()
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('token')
          delete Axios.defaults.headers.common['X-Token']
          reject(err)
        })
    })
  },

  GET_CURRENT_CLIENT: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Axios.get('/api/client')
        .then(res => {
          commit('SET_CURRENT_CLIENT', res.data)
          commit('setStake', res.data.settings.minBet.amount)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
