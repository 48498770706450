<template>
  <div id="balance-widget" class="widget balance">
    <div class="widget__title">Balance</div>
    <div class="widget__content">
      <div v-if="balance.single" class="balance__item balance__item_single">
        <div class="balance__amount">
          {{ formattedActiveAmount }}
        </div>
      </div>
      <template v-else>
        <div class="balance__item">
          <div class="balance__amount active-balance">
            {{ formattedActiveAmount }}
          </div>
          <div class="balance__desc">Active</div>
        </div>
        <div class="balance__item">
          <div class="balance__amount">
            {{ formattedBlockedAmount }}
          </div>
          <div class="balance__desc">Blocked</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecretBalance',

  props: {
    balance: Object
  },

  computed: {
    formattedActiveAmount() {
      const amount = this.balance.active.amount
      const currency = this.balance.active.currency
      if (amount) {
        return amount.toFixed(2) + ' ' + currency
      } else {
        return '0.00 ' + currency
      }
    },

    formattedBlockedAmount() {
      const amount = this.balance.blocked.amount
      const currency = this.balance.blocked.currency
      if (amount) {
        return amount.toFixed(2) + ' ' + currency
      } else {
        return '0.00 ' + currency
      }
    }
  }
}
</script>
