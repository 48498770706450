<template>
  <the-modal :title="title">
    <template v-slot:content>
      <div class="error-modal">
        <div class="error-modal__content">
          <div class="error-modal__icon">
            <img src="../../assets/money-lock.svg" alt="money-lock" />
          </div>
          <div>
            <div class="error-modal__text">
              <p v-if="!commissionPaymentText?.text">
                The funds are blocked. To unlock, you have to pay a tax fee in
                <b>{{ fee }}%</b> of the blocked balance.
              </p>
              <p v-else>{{ commissionPaymentText.text }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="!commissionPaymentText?.disabledBtn"
          class="error-modal__actions"
        >
          <button class="commission-pay-btn" @click="onPayClick">Pay</button>
        </div>
      </div>
    </template>
  </the-modal>
</template>

<script>
import TheModal from './TheModal'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CommissionPayment',

  components: {
    TheModal
  },

  computed: {
    ...mapGetters({
      fee: 'FEE',
      commissionPaymentText: 'commissionPaymentText'
    }),

    title() {
      return this.commissionPaymentText?.title || 'Error'
    }
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
      $_close: 'close'
    }),

    openErrorLicenseModal() {
      this.$_showModal({ name: 'ErrorLicense' })
    },

    closeModal() {
      this.$_close({ name: 'CommissionPayment' })
    },

    onPayClick() {
      this.closeModal()
      this.openErrorLicenseModal()
    }
  }
}
</script>

<style>
.commission-pay-btn {
  width: 100%;
  border: 2px solid #1e262e;
  color: #1e262e;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 25px;
  height: 35px;
  margin-top: 10px;
  background: none;
  cursor: pointer;
  font-weight: 700;
}

.commission-pay-btn:hover {
  color: #fff;
  background: #1e262e;
}
</style>
