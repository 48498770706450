<template>
  <div class="widget" id="pa-line-widget">
    <div class="widget__title">{{ title }}</div>
    <component :is="selectedEvents" />
  </div>
</template>

<script>
import InPlayList from '../Line/inPlayList'
import UpcomingList from '../Line/upcomingList'
import { mapGetters } from 'vuex'

export default {
  name: 'PersonalAreaLine',

  components: {
    InPlayList,
    UpcomingList
  },

  computed: {
    ...mapGetters(['activeEvents']),

    title() {
      if (this.activeEvents === 'inPlay') return 'Live'
      else return 'Upcoming'
    },

    selectedEvents() {
      if (this.activeEvents === 'inPlay') {
        return 'InPlayList'
      }
      return 'UpcomingList'
    }
  }
}
</script>

<style>
#pa-line-widget .widget__title {
  background: #151516;
  color: #fff;
  padding: 0 25px;
  font-weight: 500;
  font-size: 15px;
}
</style>
