<template>
  <div id="balance-widget" class="widget balance">
    <div class="widget__title">Balance</div>
    <div class="widget__content">
      <div
        v-if="balance.single"
        class="balance__item balance__item_single"
        @click="openСommissionPaymentModal()"
      >
        <div class="balance__amount">
          <money :money="balance.active" />
        </div>
      </div>
      <template v-else>
        <div class="balance__item">
          <div class="balance__amount active-balance">
            <money :money="balance.active" />
          </div>
          <div class="balance__desc">Active</div>
        </div>
        <div class="balance__item" @click="openСommissionPaymentModal()">
          <div class="balance__amount">
            <money :money="balance.blocked" />
          </div>
          <div class="balance__desc">Blocked</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Money from '../common/Money'

export default {
  name: 'Balance',

  components: {
    Money
  },

  computed: {
    ...mapGetters({
      balance: 'BALANCE'
    })
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openСommissionPaymentModal() {
      this.$_showModal({ name: 'СommissionPayment' })
    }
  }
}
</script>

<style>
.balance {
  margin: 0 !important;
}

.balance .widget__title {
  padding: 0 25px;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
}

.balance .widget__content {
  padding: 5px;
}

.balance__item {
  text-align: center;
  width: 100%;
  color: #fff;
  background: #444444;
}

.balance__item_single {
  cursor: pointer;
  padding: 8px 0;
}

.balance__item:not(:last-child) {
  margin-bottom: 5px;
}

.balance__item:last-child {
  cursor: pointer;
}

.balance__item:last-child:hover {
  background: #828282;
}

.balance .balance__amount {
  font-size: 20px;
}

.balance .balance__desc {
  font-size: 10px;
}

.blocked-balance {
  color: #ff2c2c;
}

.active-balance {
  color: #7be16c;
}

@media screen and (max-width: 992px) {
  #balance-widget .widget__content {
    display: flex;
  }

  .balance__item:first-child {
    margin: 0;
    margin-right: 5px;
  }
}
</style>
