<template>
  <div class="coupon__result">{{ betResult }}</div>
</template>

<script>
export default {
  name: 'BetResult',

  props: {
    stake: Object,
    odds: Number,
    betStatus: String,
    couponBets: Array
  },

  computed: {
    betResult() {
      if (this.betStatus === 'win') {
        if (
          this.betsStatusList.length === 1 &&
          this.betsStatusList[0] === 'canceled'
        ) {
          return (
            (this.stake.amount / 100).toFixed(2) + ' ' + this.stake.currency
          )
        }
        return (
          ((this.stake.amount / 100) * this.odds).toFixed(2) +
          ' ' +
          this.stake.currency
        )
      }
      return '-'
    },

    betsStatusList() {
      return this.couponBets.map(bet => bet.status)
    }
  }
}
</script>
