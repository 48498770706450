<template>
  <div class="menu-list__item-title">{{ name }} ({{ sportCount }})</div>
</template>

<script>
export default {
  name: 'SportName',

  props: {
    sportId: Number,
    sportCount: Number
  },
  computed: {
    name() {
      switch (this.sportId) {
        case 4:
          return 'Football'

        case 5:
          return 'Tennis'

        case 6:
          return 'Formula 1'

        case 7:
          return 'Basketball'

        case 9:
          return 'Alpine Skiing'

        case 10:
          return 'Cycling'

        case 11:
          return 'American Football'

        case 12:
          return 'Ice Hockey'

        case 13:
          return 'Golf'

        case 16:
          return 'Handball'

        case 18:
          return 'Volleyball'

        case 22:
          return 'Cricket'

        case 23:
          return 'Baseball'

        case 24:
          return 'Boxing'

        case 31:
          return 'Rugby League'

        case 32:
          return 'Rugby Union'

        case 33:
          return 'Snooker'

        case 34:
          return 'Darts'

        case 36:
          return 'Aussie Rules'

        case 40:
          return 'Motorbikes'

        case 44:
          return 'Badminton'

        case 56:
          return 'Table Tennis'

        case 63:
          return 'Beach Volleyball'

        case 64:
          return 'Biathlon'

        case 70:
          return 'Futsal'

        default:
          return ''
      }
    }
  }
}
</script>
