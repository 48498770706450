<template>
  <img
    class="status-icon"
    :src="statusIcon"
    alt="status-icon"
    :height="height"
    :width="width"
  />
</template>

<script>
import checkIcon from '../../assets/status-icons/check.svg'
import failIcon from '../../assets/status-icons/fail.svg'
import inProgressIcon from '../../assets/status-icons/in-progress.svg'
import canceledIcon from '../../assets/status-icons/canceled.svg'

export default {
  name: 'StatusIcon',

  props: {
    status: String,
    width: Number,
    height: Number
  },

  computed: {
    statusIcon() {
      if (this.status == 'win' || this.status == 'success') {
        return checkIcon
      } else if (this.status == 'loss' || this.status == 'fail') {
        return failIcon
      } else if (this.status == 'canceled') {
        return canceledIcon
      } else {
        return inProgressIcon
      }
    }
  }
}
</script>

<style>
.status-icon {
  display: block;
}
</style>
