<template>
  <div class="pa-menu">
    <button
      class="pa-menu__item"
      :class="{ active: item.value === activeTab }"
      v-for="(item, index) in menuItems"
      :key="index"
      :disabled="item.disabled"
      @click="
        item.value === 'Casino' ? openCasinoPage() : setActiveTab(item.value)
      "
    >
      {{ item.text }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LeftMenu',

  data: () => ({
    menuItems: [
      {
        text: 'Live Events',
        value: 'Live',
        disabled: false
      },
      {
        text: 'Upcoming Events',
        value: 'Upcoming',
        disabled: false
      },
      {
        text: 'Deposit',
        value: 'Deposit',
        disabled: true
      },
      {
        text: 'Withdrawal',
        value: 'Withdrawal',
        disabled: true
      },
      {
        text: 'My profile',
        value: 'Profile',
        disabled: false
      },
      {
        text: 'Change password',
        value: 'ChangePassword',
        disabled: true
      },
      {
        text: 'Settings',
        value: 'Settings',
        disabled: true
      },
      {
        text: 'Transaction History',
        value: 'Transactions',
        disabled: false
      },
      {
        text: 'Bet History',
        value: 'Bets',
        disabled: false
      },
      {
        text: 'Bonus',
        value: 'Bonus',
        disabled: true
      },
      {
        text: 'Promo Code',
        value: 'PromoCode',
        disabled: true
      },
      {
        text: 'Casino',
        value: 'Casino',
        disabled: true
      },
      {
        text: 'Support',
        value: 'Support',
        disabled: true
      }
    ]
  }),

  computed: {
    ...mapGetters(['activeTab', 'isCasino'])
  },

  mounted() {
    if (this.isCasino) {
      const casinoItem = this.menuItems.find(item => item.text === 'Casino')
      casinoItem.disabled = false
    }
  },

  methods: {
    setActiveTab(item) {
      this.$store.dispatch('GET_TOKEN').catch(() => {
        this.$router.push('/')
      })

      this.$store.commit('setActiveTab', item)
      if (item == 'Upcoming') this.$store.commit('eventsSelection', 'upcoming')
      if (item == 'Live') this.$store.commit('eventsSelection', 'inPlay')
      window.scrollTo(0, 0)
    },

    openCasinoPage() {
      this.$router.push('/casino')
    }
  }
}
</script>

<style>
.pa-menu .pa-menu__item {
  width: 100%;
  min-height: 36px;
  border: 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #fff;
  cursor: pointer;
  background: #151516;
  font-weight: 500;
  font-size: 14px;
  border-radius: 1px;
}

.pa-menu .pa-menu__item:disabled {
  color: #8c8c8c;
  cursor: default;
}

.pa-menu .pa-menu__item:not(:disabled):hover {
  background: #29292b;
}

.pa-menu .pa-menu__item:not(:last-child) {
  border-bottom: 1px solid grey;
}
</style>
