<template>
  <div id="app">
    <div class="container" id="container" v-if="!siteLoading && !error">
      <navigation-menu />
      <router-view></router-view>
      <contact-modal v-if="isContactModalVisible" />
      <error-license v-if="isErrorModalVisible" />
      <error-callback v-if="isErrorCallbackModalVisible" />
      <error-casino v-if="isErrorCasinoModalVisible" />

      <cookies-acceptance-message
        v-if="
          isCookiesAcceptanceMessageVisible &&
            $route.path != '/cookies-privacy-notice'
        "
        @click="isCookiesAcceptanceMessageVisible = false"
      />
      <feedback-form />
      <Footer v-if="!$store.getters.loadLine && !$store.getters.loadClient" />
    </div>
    <div class="technical-work" v-if="error">
      <h2>Offline for maintenance</h2>
      <p style="text-align: center">
        This site is undergoing maintenance right now. <br />
        Please check back later.
      </p>
      <img src="./assets/construction.png" alt="construction" />
    </div>
  </div>
</template>

<script>
import NavigationMenu from './components/NavigationMenu'
import Footer from './components/Footer'
import ContactModal from './components/modals/ContactUs'
import ErrorLicense from './components/modals/ErrorLicense'
import ErrorCallback from './components/modals/ErrorCallback'
import ErrorCasino from './components/modals/ErrorCasino'
import FeedbackForm from './components/modals/FeedbackForm'
import CookiesAcceptanceMessage from './components/CookiesAcceptanceMessage'
import preloadImage from './lib/preloadImage'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    NavigationMenu,
    ErrorLicense,
    ErrorCallback,
    ErrorCasino,
    Footer,
    ContactModal,
    FeedbackForm,
    CookiesAcceptanceMessage
  },

  data: () => ({
    siteLoading: true,
    error: false,
    isCookiesAcceptanceMessageVisible: false
  }),

  computed: {
    ...mapGetters('modal', {
      $_isModalVisible: 'isVisible'
    }),

    isErrorModalVisible() {
      return this.$_isModalVisible({ name: 'ErrorLicense' })
    },

    isContactModalVisible() {
      return this.$_isModalVisible({ name: 'ContactUs' })
    },

    isErrorCallbackModalVisible() {
      return this.$_isModalVisible({ name: 'ErrorCallback' })
    },

    isErrorCasinoModalVisible() {
      return this.$_isModalVisible({ name: 'ErrorCasino' })
    }
  },

  async beforeCreate() {
    Axios.interceptors.request.use(function(config) {
      config.headers['X-Site'] = window.location.hostname

      return config
    })
    await this.$store.dispatch('getSite').catch(() => (this.error = true))
    this.$store.getters.site.vars.promo.forEach(item => {
      if (item.image.indexOf('/v1/media') === 0) {
        preloadImage('/api' + item.image.replace('/v1', ''))
      } else {
        preloadImage(item.image)
      }
    })
    this.siteLoading = false
  },

  async mounted() {
    this.isCookiesAcceptanceMessageVisible = !localStorage.getItem(
      'accept-cookies'
    )

    await this.$store
      .dispatch('GET_TOKEN')
      .then(async () => await this.$store.dispatch('GET_CURRENT_CLIENT'))
      .catch(() => {
        if (this.$route.path == '/personal-account') this.$router.push('/')
      })
    this.$store.commit('setLoadLine', true)
    await this.$store.dispatch('getLineData')
    this.$store.commit('setLoadLine', false)
  }
}
</script>

<style>
.technical-work {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 18px;
}
</style>
