<template>
  <div class="widget" id="line-widget">
    <div class="widget__title">
      <h2
        :class="{ 'active-line': !inPlayListActive }"
        @click="eventsSelection('upcoming')"
      >
        Upcoming
      </h2>
      <h2
        :class="{ 'active-line': inPlayListActive }"
        @click="eventsSelection('inPlay')"
      >
        Live
      </h2>
    </div>
    <component :is="selectedEvents" v-if="thereAreSelectedEvents" />
    <div class="empty-content" v-else>
      Not currently available due to technical issues
    </div>
  </div>
</template>

<script>
import InPlayList from './inPlayList'
import UpcomingList from './upcomingList'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MainLine',

  components: {
    InPlayList,
    UpcomingList
  },

  computed: {
    ...mapGetters(['activeEvents', 'notEmptyLiveList', 'notEmptyUpcomingList']),

    inPlayListActive() {
      return this.activeEvents === 'inPlay'
    },

    selectedEvents() {
      return this.activeEvents === 'inPlay' ? 'InPlayList' : 'UpcomingList'
    },

    thereAreSelectedEvents() {
      return this.activeEvents === 'inPlay'
        ? this.notEmptyLiveList
        : this.notEmptyUpcomingList
    }
  },

  methods: {
    ...mapMutations(['eventsSelection'])
  }
}
</script>

<style>
#line-widget {
  margin-bottom: 0;
}

#line-widget .widget__title {
  justify-content: space-between;
  height: 36px;
  align-items: center;
}

#line-widget .widget__title h2 {
  cursor: pointer;
  color: #d0d0d0;
  display: block;
  height: auto;
  font-size: 15px;
  transition: font-size 0.2s;
}

#line-widget .widget__title h2:hover {
  color: #fff;
  font-size: 20px !important;
}

#line-widget .widget__title .active-line {
  color: #fff;
  font-size: 20px !important;
}

.line {
  padding: 0 25px;
}

.d-flex {
  display: flex;
}

.w-33 {
  width: 33.3%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.first-column,
.second-column,
.third-column {
  text-align: center;
  font-size: 13px;
}

.first-column {
  width: 42%;
  text-align: left;
}

.second-column,
.third-column {
  width: 32.5%;
}

.fourth-column {
  width: 3%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.line__header {
  color: #949495;
}

.line__header-main {
  color: #fff;
  margin-top: 5px;
  font-size: 13px;
}

.league-title-row {
  color: #949495;
}

.events-table-league:not(:first-child) {
  margin-top: 5px;
}

.league-name {
  color: #7be16c;
  font-size: 10px;
  margin: auto 0;
}

.event-row {
  border-bottom: 1px solid #676769;
  padding: 6px 0px;
  color: #fff;
}

/* .event-row:hover {
    background: #3d3d3d;
  } */

.events-table-league:last-child .event-row:last-child {
  padding-bottom: 15px;
  border-bottom: none;
}

.event-teams {
  text-align: left;
  color: #fff;
  line-height: 1.4;
  padding-left: 10px;
  cursor: pointer;
  display: inline-block;
  width: 70%;
  font-size: 13px;
}

.event-teams:hover {
  color: #7be16c;
}

.event-teams p {
  overflow: hidden;
  margin: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.event-scoreboard {
  width: 30%;
  color: #7be16c;
  line-height: 1.4;
  font-weight: 700;
}

.odd-btn {
  background: #1e1e1d;
  color: #fff;
  border: none;
  width: 85%;
  height: 100%;
  font-weight: 500;
  position: relative;
}

.oddBtnSelected {
  /* background: #58a551 !important; */
  background: linear-gradient(
    130deg,
    #58a551,
    #58a551 75%,
    #459b3d 25%,
    #459b3d
  ) !important;
  position: relative;
  color: #1d1d1b !important;
  overflow: hidden;
}

.main-odd::before,
.main-odd::after {
  position: absolute;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.main-odd::before {
  content: '';
  border-width: 6px 5px 0 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
  margin-left: -5px;
  top: -6px;
}

.main-odd::after {
  content: attr(data-tooltip);
  background-color: rgba(255, 255, 255, 1);
  top: -6px;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin-left: -50%;
  color: #000;
  padding: 1px;
  transform: translateY(-100%);
}

.main-odd:hover::before,
.main-odd:hover::after {
  visibility: visible;
  opacity: 1;
}

.odd-btn:hover {
  background: #686868;
  cursor: pointer;
}

.points {
  margin: auto 0;
}

button {
  outline: 0 !important;
}

.lock {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-self: center;
}

.lock svg {
  width: 15px;
  height: 15px;
  margin: auto;
}

.lock svg path {
  fill: #949495;
}

.unfolding-icon-down,
.unfolding-icon-up {
  font-size: 8px;
  cursor: pointer;
  margin: auto;
}

.fourth-column svg path {
  fill: #676769;
}

.unfolding-icon-down:hover svg path,
.unfolding-icon-up:hover svg path {
  fill: #fff;
}

.selectedEvent {
  border: 0 !important;
  outline-offset: -1px;
  outline: 1px solid #676769;
  margin-top: -1px;
  padding-top: 7px;
}

@media (max-width: 1375px) {
  .first-column {
    width: 65%;
  }

  .second-column {
    width: 35%;
  }

  .third-column {
    display: none;
  }
}

@media (max-width: 992px) {
  .line {
    padding: 0 5px;
  }

  .first-column {
    width: 50%;
  }

  .second-column {
    width: 45%;
  }

  .fourth-column {
    width: 5%;
  }

  .third-column {
    display: none;
  }

  .sport-tabs {
    padding: 10px 5px 0;
  }

  .right-scroll {
    right: 5px;
  }

  .left-scroll {
    left: 5px;
  }
}
</style>
