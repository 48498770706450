<template>
  <div class="points-with-sets">
    <div class="home-team-score">
      <div class="points">{{ homeTeamPoints }}</div>
      <div style="width: 5px;"></div>
      <div
        class="set-points"
        :class="{ 'current-set-points': index === currentSetIndex }"
        v-for="(setPoints, index) in homeTeamSets"
        :key="index"
      >
        {{ setPoints }}
      </div>
    </div>
    <div class="away-team-score">
      <div class="points">{{ awayTeamPoints }}</div>
      <div style="width: 5px;"></div>
      <div
        class="set-points"
        :class="{ 'current-set-points': index === currentSetIndex }"
        v-for="(setPoints, index) in awayTeamSets"
        :key="index"
      >
        {{ setPoints }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointsWithSets',

  props: {
    sets: Array,
    points: Array
  },

  computed: {
    homeTeamSets() {
      return this.sets[0]
    },

    awayTeamSets() {
      return this.sets[1]
    },

    homeTeamPoints() {
      return this.points ? this.points[0] : '-'
    },

    awayTeamPoints() {
      return this.points ? this.points[1] : '-'
    },

    currentSetIndex() {
      let nextSetIndex = this.sets[0].indexOf('-')
      const currentSetIndex =
        nextSetIndex > 0
          ? --nextSetIndex
          : nextSetIndex === 0
          ? null
          : this.sets[0].length - 1
      return currentSetIndex
    }
  }
}
</script>

<style>
.points-with-sets .points {
  border-right: 1px solid grey;
  min-width: 34px;
  font-weight: 700 !important;
  color: #7be16c;
}
</style>
