<template>
  <div class="widget" id="bid-configurator">
    <div class="ticket-title" @click="showTicket" v-if="bets.length">
      Ticket
      <span style="color: #7be16c; margin-left: 5px;"
        >({{ selectedBetsCounter }})</span
      >
    </div>
    <div class="widget__title d-flex" v-if="ticket">
      <div>
        <h3 :class="{ configuratorTitleActive: bets.length === 1 }">SINGLE</h3>
      </div>
      <div>
        <h3 :class="{ configuratorTitleActive: bets.length > 1 }">MULTI BET</h3>
      </div>
    </div>
    <div class="widget__body" v-if="bets.length > 0 && ticket">
      <div class="bets">
        <bet v-for="bet in bets" :key="bet.id" :bet="bet" />
      </div>
      <div class="total-odds" v-if="bets.length > 1">
        Total odds
        <span>{{ totalOdds }}</span>
      </div>
      <div class="betslip">
        <div class="betslip__stake d-flex-between">
          <div class="betslip__stake-text">Stake</div>
          <div class="betslip__stake-input">
            <input
              type="text"
              :value="stake"
              @input="updateStake"
              v-money="money"
            />
          </div>
        </div>
        <div class="betslip__possible-winnings d-flex-between">
          <div class="betslip__possible-winnings-text">Possible winnings</div>
          <div class="betslip__possible-winnings-value">
            {{ possibleWinnings }} {{ currency }}
          </div>
        </div>
        <div class="betslip__btn">
          <button @click="openModal">
            Place bet
          </button>
        </div>
        <div class="betslip__tac">
          By placing a bet, you automatically accept the most recent version of
          the <span>Terms of Use.</span>
        </div>
      </div>
    </div>
    <div class="widget__body" v-if="bets.length == 0 && ticket">
      <div class="coupon-message">
        <div class="coupon-message__icon">
          <svg viewBox="0 0 34.94 34.94">
            <path
              class="cls-1"
              d="M17.47,0A17.47,17.47,0,1,1,0,17.47,17.47,17.47,0,0,1,17.47,0Z"
            />
            <path
              class="cls-2"
              d="M17.45,5.38A12.21,12.21,0,0,0,6.71,23.44L5.22,29.82l6.39-1.48a12.09,12.09,0,0,0,5.84,1.48,12.22,12.22,0,0,0,0-24.44Z"
            />
            <path
              class="cls-1"
              d="M17.5,26.49a2,2,0,0,1-1.37-.55,1.72,1.72,0,0,1-.58-1.31,1.78,1.78,0,0,1,.57-1.32,1.85,1.85,0,0,1,1.35-.55,1.94,1.94,0,0,1,1.37.54,1.72,1.72,0,0,1,.58,1.31,1.75,1.75,0,0,1-.57,1.32,1.84,1.84,0,0,1-1.35.56Zm1.37-5.59H16V19.36a3.51,3.51,0,0,1,.24-1.49,5.33,5.33,0,0,1,1.05-1.29l1.83-1.84a2.21,2.21,0,0,0,.59-1.56A2.12,2.12,0,0,0,17.57,11a2.27,2.27,0,0,0-1.6.6,2.39,2.39,0,0,0-.75,1.57H12.17a5.15,5.15,0,0,1,1.75-3.51A5.61,5.61,0,0,1,17.66,8.4a5.36,5.36,0,0,1,3.65,1.23A4.26,4.26,0,0,1,22.72,13a3.84,3.84,0,0,1-.83,2.52,9.49,9.49,0,0,1-.73.85c-.16.17-.38.38-.67.64l-.71.68-.46.5a2.42,2.42,0,0,0-.46,1.56V20.9Z"
            />
          </svg>
        </div>
        <div class="coupon-message__title">
          {{ couponText.mainText }}
          <b v-if="isAuthenticated">
            {{ couponText.textAfterAuth }}
            {{ minBet.amount / 100 + ' ' + minBet.currency }}
          </b>
        </div>
        <div class="coupon-message__description">
          If you have any questions about bets, please contact our
          <span>Tech Support.</span>
        </div>
        <div class="betslip__tac">
          By placing a bet, you automatically accept the most recent version of
          the <span>Terms of Use.</span>
        </div>
      </div>
    </div>
    <resizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import bet from './bet'
import { VMoney } from 'v-money'
import { ResizeObserver } from 'vue-resize'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BetConfigurator',
  components: {
    bet,
    ResizeObserver
  },
  directives: { money: VMoney },
  data: () => ({
    ticket: null,
    allowResizeAction: true,
    money: {
      decimal: '.',
      thousands: '',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      minBet: 'MIN_BET',
      selectedBetsCounter: 'selectedBetsCounter',
      bets: 'bets',
      totalOdds: 'totalOdds',
      possibleWinnings: 'possibleWinnings',
      stake: 'stake',
      site: 'site'
    }),

    couponText() {
      return this.site.vars.couponText
    },

    currency() {
      return this.couponText.currency || 'USD'
    }
  },
  mounted() {
    this.ticket = window.innerWidth < 992 ? false : true
  },
  methods: {
    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openModal() {
      this.$_showModal({ name: 'ErrorLicense' })
    },

    updateStake(e) {
      this.$store.commit('updateStake', e.target.value)
    },

    handleResize() {
      const windowWidth = window.innerWidth

      if (windowWidth > 992) this.allowResizeAction = true

      if (this.allowResizeAction && windowWidth > 992) this.ticket = true
      if (this.allowResizeAction && windowWidth < 992) this.ticket = false
    },
    showTicket() {
      this.ticket = !this.ticket
      if (this.ticket) {
        const scrollY = window.scrollY
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollY}px`
        document.body.style.width = '100%'
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
      this.allowResizeAction = false
    }
  }
}
</script>

<style>
#bid-configurator .widget__title {
  justify-content: space-around;
  height: 36px;
  padding: 0;
}

#bid-configurator .widget__title div {
  margin: auto 0;
}

#bid-configurator .widget__title div h3 {
  color: #d0d0d0;
  font-size: 12px;
}

.configuratorTitleActive {
  color: #fff !important;
  font-weight: 700;
}

#bid-configurator .bets {
  max-height: 20vh;
  overflow-y: auto;
}

#bid-configurator .widget__body {
  background-color: #d8d8d8;
  padding-top: 20px;
}

.d-flex-between {
  display: flex;
  justify-content: space-between;
}

.total-odds {
  background-color: #a9a9a9;
  padding: 0 20px;
  color: #f7f7f7;
  text-align: right;
  font-size: 12px;
  border-top: 1px solid #fff;
}

.total-odds span {
  font-weight: 700;
}

.betslip {
  padding: 20px 20px;
}

.betslip__stake,
.betslip__possible-winnings {
  margin-bottom: 5px;
}

.betslip__stake-text,
.betslip__possible-winnings-text {
  font-weight: 700;
  font-size: 12px;
}

.betslip__possible-winnings-value {
  font-weight: 700;
  font-size: 15px;
}

.betslip__stake-input input {
  width: 75px;
  height: 22px;
  font-size: 12px;
  text-align: right;
  font-weight: 700;
  border: 1px solid #d8d8d8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.betslip__btn {
  height: 50px;
  margin: 10px 0;
}

.betslip__btn button {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(
    150deg,
    #58a551,
    #58a551 20%,
    #459b3c 20%,
    #459b3d 75%,
    #58a551 75%,
    #58a551 80%,
    #459b3c 80%,
    #459b3c
  );
  font-size: 15px;
  font-weight: 700;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.betslip__tac {
  font-size: 10px;
  font-style: italic;
  line-height: 1.2;
}

.coupon-message {
  padding: 0 20px 25px;
}

.coupon-message__icon {
  width: 100%;
  text-align: center;
}

.coupon-message__icon svg {
  width: 40px;
  height: 40px;
}

.coupon-message__icon svg .cls-1 {
  fill: #a4d1a6;
}
.coupon-message__icon svg .cls-2 {
  fill: #fff;
}

.coupon-message__title {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  color: #1d1d1a;
  margin: 10px 0;
}

.coupon-message__description {
  font-size: 10px;
  font-style: italic;
  line-height: 1;
  margin: 35px 0 15px;
}

.coupon-message__description span,
.betslip__tac span {
  font-weight: 700;
}

.ticket-title {
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  background: #151516;
  font-size: 18px;
  font-weight: 500;
  height: 36px;
  display: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  #bid-configurator {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
  }

  .ticket-title {
    display: flex;
  }

  #line-widget {
    margin-bottom: 0;
  }

  #bid-configurator .widget__title {
    border-top: 1px solid #292929;
  }

  #bid-configurator {
    margin: 0;
  }

  #bid-configurator .bets {
    max-height: 40vh;
    overflow-y: auto;
  }

  /* #bid-configurator .widget__title,
    #bid-configurator .widget__body {
      display: none;
    } */
}
</style>
