<template>
  <preloader v-if="$store.getters.loadLine || loading" />
  <div v-else id="page-wrapper" style="padding-top: 75px">
    <div id="main-wrap">
      <div id="main">
        <div class="left">
          <balance />
          <left-menu />
        </div>
        <div class="pa-content-section">
          <div class="center" v-if="isLine">
            <main-line />
          </div>
          <div class="right" v-if="isLine">
            <bidConfigurator />
            <news-block v-if="isNews" />
          </div>
          <component v-else :is="$store.getters.activeTab"></component>
        </div>
      </div>
    </div>
    <commission-payment v-if="isСommissionPaymentModal" />
  </div>
</template>

<script>
import LeftMenu from './LeftMenu'
import Balance from './Balance'
import MainLine from './BetsLine'
import bidConfigurator from '../right/bidConfigurator'
import NewsBlock from '../right/NewsBlock'
import Transactions from './Transactions'
import Profile from './Profile'
import Bets from './Bets'
import preloader from '../Line/preloader'
import CommissionPayment from '../modals/СommissionPayment'

import { mapGetters } from 'vuex'

export default {
  name: 'PersonalArea',

  components: {
    preloader,
    LeftMenu,
    Balance,
    MainLine,
    bidConfigurator,
    Transactions,
    Profile,
    Bets,
    CommissionPayment,
    NewsBlock
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters(['isNews']),

    ...mapGetters('modal', {
      $_isModalVisible: 'isVisible'
    }),

    isLine() {
      return (
        this.$store.getters.activeTab == 'Live' ||
        this.$store.getters.activeTab == 'Upcoming'
      )
    },

    isСommissionPaymentModal() {
      return this.$_isModalVisible({ name: 'СommissionPayment' })
    }
  },

  async mounted() {
    this.loading = true
    this.$store.commit('changeLoadClient')
    // if(this.$store.getters.isAuthenticated) await this.$store.dispatch('GET_CURRENT_CLIENT')
    // else
    this.$store.commit('changeLoadClient')
    this.loading = false
  }
}
</script>

<style lang="scss">
.left {
  width: calc(15% - 10px);
  margin-right: 10px;
  position: sticky;
  top: 85px;
  height: 100%;
  float: left;
  margin-bottom: 15px;
}

.pa-content-section {
  width: 85%;

  &_support {
    display: flex;
  }
}

.pa-content-section .right {
  height: auto;
}

@media screen and (max-width: 992px) {
  .main {
    display: block;
  }

  .left {
    width: 100%;
    position: relative;
    top: 0;
    float: none;
  }

  .pa-menu {
    display: none;
  }

  .pa-content-section {
    width: 100%;

    &_support {
      flex-direction: column-reverse;
    }
  }

  #pa-line-widget {
    margin-bottom: 0 !important;
  }
}
</style>
