<template>
  <div class="promo-carouse">
    <swiper :options="swiperOption">
      <carousel-banner
        v-for="(banner, index) in promoBanners"
        :key="index"
        :banner="banner"
      />
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev">
        <img
          class="slider-btn"
          src="../../../assets/left-arrow.svg"
          alt="prev-btn"
          width="38"
        />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img
          class="slider-btn"
          src="../../../assets/right-arrow.svg"
          alt="next-btn"
          width="38"
        />
      </div>
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper } from 'vue-awesome-swiper'
import CarouselBanner from './CarouselBanner'

function importAllImages(r) {
  return r.keys().map(r)
}

export default {
  name: 'PromoCarousel',

  components: {
    CarouselBanner,
    swiper
  },

  data: () => ({
    promoBanners: importAllImages(
      require.context(
        '../../../assets/casino/promo-images/',
        false,
        /\.(png|jpe?g)$/
      )
    ),

    swiperOption: {
      slidesPerView: 'auto',
      spaceBetween: 0,
      loop: true,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  })
}
</script>

<style>
.promo-carouse {
  height: 374px;
}

.promo-carouse .swiper-pagination,
.promo-carouse .swiper-button-prev,
.promo-carouse .swiper-button-next {
  display: none;
  transition: 0.2s;
}

.promo-carouse:hover .swiper-pagination {
  display: block;
}

.promo-carouse:hover .swiper-button-prev,
.promo-carouse:hover .swiper-button-next {
  display: flex;
}

.promo-carouse .swiper-button-prev {
  left: 0;
}

.promo-carouse .swiper-button-next {
  right: 0;
}

.promo-carouse .swiper-button-prev,
.promo-carouse .swiper-button-next {
  height: 120px;
  width: 45px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  margin-top: -60px;
}

.slider-btn {
  filter: invert(92%) sepia(95%) saturate(595%) hue-rotate(45deg)
    brightness(97%) contrast(81%);
}

.promo-carouse .swiper-button-prev:hover .slider-btn,
.promo-carouse .swiper-button-next:hover .slider-btn {
  filter: brightness(0) invert(1);
}

.promo-carouse .swiper-pagination-bullet-active {
  background: #7be16c;
}

@media screen and (max-width: 768px) {
  .promo-carouse {
    height: 240px;
  }
}
</style>
