import { render, staticRenderFns } from "./ErrorLicense.vue?vue&type=template&id=7fd389cb"
import script from "./ErrorLicense.vue?vue&type=script&lang=js"
export * from "./ErrorLicense.vue?vue&type=script&lang=js"
import style0 from "./ErrorLicense.vue?vue&type=style&index=0&id=7fd389cb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports