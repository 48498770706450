<template>
  <div
    class="modal"
    id="auth-modal"
    :class="{ isVisibleFlex: this.$store.state.authModal }"
    @click="closeModal"
  >
    <div class="modal-container auth" @click.stop>
      <div class="close-btn" @click="closeModal">
        <svg viewBox="0 0 20 20">
          <path
            d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
          />
        </svg>
      </div>
      <!-- <div class="header-logo logo">
        <span style="font-weight: 700;">SPORT</span>BET
      </div> -->
      <div class="modal-body">
        <div class="auth__title">
          User Log In
        </div>
        <div class="error__message" v-if="error">
          {{ messageError }}
        </div>
        <form @submit.prevent="submit">
          <div class="auth__form form">
            <div class="form__row">
              <div class="form__group d-flex">
                <input
                  class="form__input"
                  type="text"
                  placeholder="User ID"
                  v-model="login"
                  required
                />
                <div class="form__input-icon">
                  <svg viewBox="0 0 13.76 15.16">
                    <path
                      d="M 0.48 15.16 A 0.48 0.48 0 0 1 0 14.65 a 6.9 6.9 0 0 1 13.76 0 a 0.48 0.48 0 0 1 -0.48 0.51 Z"
                    />
                    <path
                      d="M 10.38 3.5 A 3.5 3.5 0 1 1 6.88 0 A 3.5 3.5 0 0 1 10.38 3.5 Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="form__row">
              <div class="form__group d-flex">
                <input
                  class="form__input"
                  type="password"
                  placeholder="******"
                  v-model="password"
                  required
                />
                <div class="form__input-icon">
                  <svg viewBox="0 0 18.24 9.81">
                    <path
                      d="M 18.24 3.63 H 9.63 a 4.91 4.91 0 1 0 0 2.55 h 3.12 V 8.64 h 3.83 V 6.18 h 1.66 Z M 5.3 7.22 A 2.35 2.35 0 1 1 7.22 5.3 A 2.35 2.35 0 0 1 5.3 7.22 Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__submit">
                  <input type="submit" class="login-submit" value="Login" />
                </div>
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__restore tooltip">
                  Forgot <span style="color: #000;">Password</span>?
                  <div class="tooltip-text">
                    Please contact our support team using this link:
                    <a
                      :href="
                        'mailto:' + $store.getters.site.contacts.supportEmail
                      "
                      target="_blanc"
                      >{{ $store.getters.site.contacts.supportEmail }}</a
                    >
                    <div class="tooltip-icon">
                      <svg viewBox="0 0 31.41 27.14">
                        <path
                          style="fill:#ddd;"
                          d="M 31.41 1 L 26.44 26 a 1.83 1.83 0 0 1 -2.6 0.9 L 12.37 18.12 l -4.17 -2 l -7 -2.37 S 0.11 13.36 0 12.53 s 1.21 -1.29 1.21 -1.29 L 29.12 0.3 S 31.41 -0.71 31.41 1 Z"
                        />
                        <path
                          style="fill:#a3a3a3;"
                          d="M 11.49 25.73 s -0.33 0 -0.75 -1.36 S 8.2 16.11 8.2 16.11 L 25.05 5.41 s 1 -0.59 0.94 0 c 0 0 0.17 0.1 -0.35 0.59 S 12.41 17.91 12.41 17.91"
                        />
                        <path
                          style="fill:#606060;"
                          d="M 16.77 21.49 l -4.54 4.14 a 0.81 0.81 0 0 1 -0.74 0.1 l 0.87 -7.68"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="auth-intro" @click.stop>
      <div class="registration__intro-icon">
        <svg viewBox="0 0 29.51 29.51">
          <path
            style=""
            d="M 14.75 0 A 14.76 14.76 0 1 0 29.51 14.75 A 14.75 14.75 0 0 0 14.75 0 Z m 3.07 22.87 L 16 23.55 a 4.73 4.73 0 0 1 -1.58 0.24 a 3.1 3.1 0 0 1 -2.14 -0.68 a 2.14 2.14 0 0 1 -0.77 -1.71 a 5.76 5.76 0 0 1 0.06 -0.82 c 0 -0.28 0.1 -0.59 0.18 -0.95 l 1 -3.36 c 0.09 -0.32 0.16 -0.62 0.22 -0.9 a 4.91 4.91 0 0 0 0.08 -0.8 a 1.2 1.2 0 0 0 -0.27 -0.89 a 1.47 1.47 0 0 0 -1 -0.25 a 2.48 2.48 0 0 0 -0.75 0.11 l -0.66 0.22 l 0.25 -1 c 0.62 -0.26 1.22 -0.47 1.78 -0.66 A 5.37 5.37 0 0 1 14 11.8 a 3 3 0 0 1 2.11 0.66 a 2.19 2.19 0 0 1 0.75 1.72 c 0 0.15 0 0.41 -0.05 0.77 a 5.67 5.67 0 0 1 -0.19 1 l -0.95 3.35 c -0.07 0.27 -0.14 0.58 -0.21 0.92 a 5 5 0 0 0 -0.09 0.78 a 1.12 1.12 0 0 0 0.3 0.91 a 1.61 1.61 0 0 0 1 0.24 a 3 3 0 0 0 0.79 -0.12 a 5.67 5.67 0 0 0 0.63 -0.21 Z M 17.66 9.28 a 2.37 2.37 0 0 1 -3.2 0 a 2 2 0 0 1 -0.66 -1.49 a 2 2 0 0 1 0.66 -1.5 a 2.4 2.4 0 0 1 3.2 0 a 2 2 0 0 1 0.66 1.5 a 2 2 0 0 1 -0.66 1.49 Z"
          />
        </svg>
      </div>
      <div class="registration__intro-text">
        Can’t log in to the {{ siteName }} account? Please contact our
        <strong>Tech Support.</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Auth',

  data: () => ({
    login: '',
    password: '',

    error: null,
    loading: false
  }),

  computed: {
    ...mapGetters(['siteName']),

    messageError() {
      if (
        this.error.code == 'client_login_invalid' ||
        this.error.code == 'client_password_invalid'
      ) {
        return 'Incorrect user login or password'
      } else {
        return 'Unknown error'
      }
    }
  },

  mounted() {
    const user = this.$store.getters.userCredentials
    if (user != null) {
      this.login = user.login
      this.password = user.password

      this.$store.commit('showAuthModal', 'hidden')
      this.submit()

      this.$store.commit('setUserCredentials', null)
    }
  },

  methods: {
    ...mapActions({
      authRequest: 'AUTH_REQUEST'
    }),

    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openUserAccessDeniedModal() {
      this.$_showModal({ name: 'UserAccessDenied' })
    },

    closeModal() {
      this.$store.commit('showAuthModal', 'scroll')
    },

    clearData() {
      this.login = ''
      this.password = ''
    },

    async submit() {
      this.error = null
      await this.authRequest({
        login: this.login,
        password: this.password
      })
        .then(async () => {
          await this.$store.dispatch('GET_CURRENT_CLIENT')
          this.$store.commit('showAuthModal', 'scroll')
          this.$router.push('/personal-account')
        })
        .catch(err => {
          if (err.response.data.code == 'client_access_denied') {
            this.$store.commit(
              'setUserAccessDeniedDesc',
              err.response.data.description
            )
            this.$store.commit('showAuthModal', 'scroll')
            this.clearData()
            this.openUserAccessDeniedModal()
          } else {
            this.error = err.response.data
          }
        })
    }
  }
}
</script>

<style>
.auth {
  width: 600px;
  margin: auto;
  max-width: 100%;
  position: relative;
}

.modal-container .logo {
  text-align: center;
  color: #fff;
}

.auth .modal-body {
  position: relative;
  height: auto;
  text-align: center;
  padding-top: 37px;
  background: #fff;
  border-radius: 10px;
  color: #7e7e78;
  padding: 30px 30px 0;
}

.auth .form {
  padding: 10px 0;
}

.auth .form__group input {
  width: 100%;
  color: #000;
  height: 40px;
  background: #f1f1f1;
  border: none;
  padding: 0 10px;
}

.auth .form__group input:focus {
  outline: none;
}

.form__input-icon {
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.form__input-icon svg {
  width: 15px;
  height: 15px;
}

.form__input-icon svg path {
  fill: #7e7e78;
}

.auth .form__submit .login-submit {
  width: 100%;
  border: none;
  margin-top: 0;
  border-radius: 0;
  color: #ececeb;
  font-size: 18px;
  text-transform: uppercase;
  height: 40px;
  background: #141720;
  cursor: pointer;
}

.auth .form__submit .login-submit:hover {
  background: #5c6273 !important;
  color: #ececeb !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* .tooltip:hover {
    text-decoration: underline;
  } */

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #f2f2f2;
  color: #000000;
  text-align: center;
  padding: 5px 0;
  border-radius: 10px;
  position: absolute;
  width: 300px;
  top: 150%;
  left: 50%;
  margin-left: -150px;
}

.tooltip .tooltip-text a {
  color: #000000;
}

.tooltip .tooltip-text a:hover {
  text-decoration: underline;
}

.tooltip-icon {
  text-align: center;
}

.tooltip-icon svg {
  width: 30px;
  height: 30px;
}

.tooltip .tooltip-text::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-radius: 2px;
  border-style: solid;
  border-color: transparent transparent #f2f2f2 transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.auth-intro {
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: #111111;
  color: #ffffff;
  font-size: 10px;
}

.auth-intro .registration__intro-icon {
  margin-bottom: 5px;
}

.auth-intro .registration__intro-icon svg {
  width: 20px;
  height: 20px;
}

#auth-modal .close-btn:hover svg path {
  fill: #000;
}
</style>
