<template>
  <div class="coupon__stake">{{ betStake }}</div>
</template>

<script>
export default {
  name: 'BetStake',

  props: {
    stake: Object
  },

  computed: {
    betStake() {
      return (this.stake.amount / 100).toFixed(2) + ' ' + this.stake.currency
    }
  }
}
</script>
