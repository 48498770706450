<template>
  <div class="mobile-transactions">
    <div class="transactions__header">
      <div
        v-for="(transaction, index) in transactions"
        :key="index"
        class="transaction"
        :class="{
          'active-item': selectedTransactions.includes(transaction.id)
        }"
        @click="addSelectedTransaction(transaction.id)"
      >
        <div class="transaction__main">
          <div class="content-line">
            <div class="transaction__id">{{ transaction.id }}</div>
            <div class="transaction__gateway">{{ transaction.gateway }}</div>
            <div
              class="transaction__amount"
              :style="{
                color: transaction.total.amount > 0 ? '#7be16c' : '#fff'
              }"
            >
              <money :money="transaction.total" />
            </div>
            <div class="transaction__action">
              <unfolding-icon
                :item="transactions.id"
                :itemList="selectedTransactions"
              />
            </div>
          </div>
        </div>
        <div
          class="transaction__details"
          v-if="selectedTransactions.includes(transaction.id)"
        >
          <div class="content-line">
            <div class="content-title">Status:</div>
            <div
              class="transaction__status"
              :style="{ color: transaction.status.color }"
            >
              {{ transaction.status.name }}
            </div>
          </div>
          <div class="content-line">
            <div class="content-title">Comment:</div>
            <div class="transaction__comment">
              {{ transaction.comment || '-' }}
            </div>
          </div>
          <div class="content-line">
            <div class="content-title">Account:</div>
            <div class="transaction__account">
              {{ transaction.account || '-' }}
            </div>
          </div>
          <div class="content-line">
            <div class="content-title">Date:</div>
            <div class="transaction__date">
              <formatted-date :date="transaction.at" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormattedDate from '../../common/FormattedDate'
import UnfoldingIcon from '../../common/UnfoldingIcon'
import Money from '../../common/Money'

export default {
  name: 'TransactionsMobile',

  components: {
    FormattedDate,
    UnfoldingIcon,
    Money
  },

  props: {
    transactions: Array
  },

  data: () => ({
    selectedTransactions: []
  }),

  computed: {},

  methods: {
    addSelectedTransaction(transactionId) {
      const currTransIndex = this.selectedTransactions.indexOf(transactionId)
      if (currTransIndex === -1) {
        this.selectedTransactions.push(transactionId)
      } else {
        this.selectedTransactions.splice(currTransIndex, 1)
      }
    }
  }
}
</script>

<style>
.mobile-transactions .transaction,
.mobile-coupons .coupon {
  height: auto;
  color: #fff;
}

.mobile-transactions .transaction {
  cursor: pointer;
}

.mobile-transactions .transaction:hover {
  background: #444444;
}

.mobile-transactions .transaction div,
.mobile-coupons .coupon div {
  font-size: 13px;
}

.mobile-transactions .transaction .content-line,
.mobile-coupons .coupon .content-line {
  padding: 2px 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.mobile-transactions .transaction__main .content-line {
  align-items: center;
}

.mobile-transactions .transaction__main .transaction__id {
  margin-right: 10px;
}

.content-line .transaction__comment,
.content-line .transaction__date,
.content-line .transaction__account,
.content-line .transaction__status,
.item-content {
  text-align: right;
  max-width: 75%;
  word-wrap: break-word;
}

.transaction__main {
  padding: 4px 0;
}

.mobile-transactions .transaction__main div,
.mobile-coupons .content-title div {
  font-size: 15px !important;
}

.transaction__main .transaction__amount {
  margin-left: auto;
}

.content-title {
  min-width: 25%;
  color: #cecece;
}

.transaction__action {
  margin-left: 10px;
}
</style>
