<template>
  <swiper-slide class="promo-banner">
    <div
      class="banner-img"
      :style="{
        backgroundImage: `url(${banner})`
      }"
    ></div>
    <div class="banner-active" @click="openModal">
      <button class="banner-btn">Play</button>
    </div>
  </swiper-slide>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiperSlide } from 'vue-awesome-swiper'
import { mapActions } from 'vuex'

export default {
  name: 'CarouselBanner',

  components: {
    swiperSlide
  },

  props: {
    banner: String
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openModal() {
      this.$_showModal({ name: 'ErrorLicense' })
    }
  }
}
</script>

<style>
.promo-banner {
  overflow: hidden;
  cursor: pointer;
  height: 374px;
  background-size: contain;
  background-position: center center;
  max-width: 700px;
}

.banner-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0.2s;
  transform: scale(1);
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-active {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 60px 100px;
}

.promo-banner:hover .banner-img {
  transform: scale(1.1);
}

.promo-banner:hover .banner-active {
  opacity: 1;
}

.banner-btn {
  text-transform: uppercase;
  font-size: 26px;
  border: 0;
  background: #7be16c;
  color: #000;
  font-weight: 600;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.banner-btn:hover {
  background: #8fff7e;
}

@media screen and (max-width: 768px) {
  .promo-banner {
    height: 240px;
  }

  .banner-active {
    justify-content: center;
    align-items: center;
  }
}
</style>
