<template>
  <div class="bets-select">
    <div
      class="selected-status"
      :class="{
        'select-arrow-active': !showSelectItems,
        'active-select': showSelectItems
      }"
      @click="setShowSelectItems"
    >
      {{ selectedStatus.text }}
    </div>
    <div class="select-items" :class="{ 'select-hide': !showSelectItems }">
      <div
        class="select-item"
        v-for="(option, index) in statusOptions"
        :key="index"
        :class="{ 'active-item': selectedStatus.value == option.value }"
        @click="setSelectedStatus(option)"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'BetsSelect',

  data: () => ({
    selectedStatus: { text: 'All', value: 'all' },
    statusOptions: [
      { text: 'All', value: 'all' },
      { text: 'Win', value: 'win' },
      { text: 'Loss', value: 'loss' },
      { text: 'In Process', value: 'in_process' }
    ],

    showSelectItems: false
  }),

  methods: {
    ...mapMutations({
      setBetsStatusFilter: 'SET_BETS_STATUS_FILTER'
    }),

    setShowSelectItems() {
      this.showSelectItems = !this.showSelectItems
    },

    setSelectedStatus(option) {
      this.selectedStatus = option
      this.setBetsStatusFilter(option.value)
      this.setShowSelectItems()
    }
  }
}
</script>

<style>
.bets-select {
  font-size: 13px;
  cursor: pointer;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
}

.select-hide {
  display: none;
}

.select-items {
  position: absolute;
  top: 100%;
  z-index: 10;
}

.selected-status {
  padding: 5px 25px 5px 0;
}

.selected-status::after {
  position: absolute;
  content: '';
  top: 10px;
  right: 3px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 3px;
}

.selected-status,
.select-items {
  width: 100px;
  text-align: right;
}

.select-items {
  outline: 1px solid #808080;
}

.select-item {
  text-align: right;
  padding: 5px 10px;
  background: #444444;
}

.select-item:not(:last-child) {
  border-bottom: 1px solid #808080 !important;
}

.select-item:hover {
  background: #151516;
}

.active-select {
  background: #444444;
  outline: 1px solid #808080;
}

.bets-select .active-item {
  outline: 0;
  background: #151516;
}
</style>
