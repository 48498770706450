<template>
  <div id="payment-widget" class="widget commission">
    <div class="widget__title">
      {{ paymentSystem.text }} - {{ payment.type }}
    </div>
    <div class="widget__content">
      <div class="payment">
        <div class="payment__header">
          <div class="payment__logo">
            <payment-system-icon
              :paymentSystem="paymentSystem.value"
              :widgetDesign="'new'"
            />
          </div>
        </div>
        <div class="payment__body">
          <div class="payment__item">
            <div class="payment__title">Amount</div>
            <div class="payment__content payment__amount">
              {{ formattedAmount }}
            </div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Payment system</div>
            <div class="payment__content">{{ paymentSystem.text }}</div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Wallet</div>
            <div class="payment__content">{{ walletAddress }}</div>
          </div>
          <div class="payment__item" v-if="isComment">
            <div class="payment__title">{{ commentLabel }}</div>
            <div class="payment__content">{{ payment.comment }}</div>
          </div>
          <div class="payment__item">
            <div class="payment__title">Status</div>
            <div class="payment__content">
              <payment-status :status="payment.status.name" />
            </div>
          </div>
        </div>
      </div>
      <div class="warning">
        <div class="warning__icon">
          <img
            src="../../../assets/secret-payment/warning.svg"
            alt="warning-icon"
            width="40"
            height="40"
          />
        </div>
        <div class="warning__title">Warning</div>
        <div
          class="warning__message"
          :style="{ color: redAlert ? '#992c2c' : '#000' }"
        >
          Send the exact amount, otherwise the transaction may not go through.
          <br />
          Details for receiving funds:
          <div
            style="word-break: break-word"
            v-for="(withdrawal, index) in payment.withdrawalList"
            :key="index"
          >
            <strong>{{ withdrawal.gateway }}</strong> - {{ withdrawal.account }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentStatus from '../common/PaymentStatus'
import PaymentSystemIcon from '../../common/PaymentSystemIcon'
import ChristmasAmount from '../mixins/ChristmasAmount'

export default {
  name: 'ChristmasCWidget',

  components: {
    PaymentStatus,
    PaymentSystemIcon
  },

  mixins: [ChristmasAmount],

  props: {
    walletAddress: String,
    payment: Object,
    redAlert: Boolean,
    paymentSystem: Object,
    isComment: Boolean,
    commentLabel: String
  }
}
</script>

<style>
#payment-widget .payment {
  background: #444444;
  padding: 0;
}

#payment-widget .payment .payment__header {
  padding: 10px 30px;
  border-bottom: 1px solid #9e9e9e;
}

#payment-widget .payment .payment__logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#payment-widget .payment .payment__body {
  padding: 10px 30px 5px;
}

#payment-widget .payment .payment__item {
  display: block;
  font-size: 13px;
}

#payment-widget .payment .payment__item:not(:last-child) {
  border-bottom: 1px solid #9e9e9e;
  margin-bottom: 5px;
}

#payment-widget .payment .payment__title {
  color: #9e9e9e;
  font-weight: 500;
}

#payment-widget .payment .payment__content {
  color: #fff;
  padding: 5px 10px;
  max-width: 60%;
}

#payment-widget .payment .payment__amount {
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}

#payment-widget .warning {
  background: #d7d8d8;
  text-align: center;
  padding: 15px 0;
}

#payment-widget .warning .warning__title {
  font-style: italic;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 5px 0 15px;
}

#payment-widget .warning .warning__message {
  width: 70%;
  margin: auto;
  font-size: 14px;
}

#payment-widget .payment__status {
  padding: 10px;
  height: auto;
  width: 200px;
  justify-content: space-between;
  align-items: center;
}

/* #payment-widget .payment__logo {
  height: 40px;
  width: 100px;

} */
</style>
