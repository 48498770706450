import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import auth from './modules/auth'
import exchangeRate from './modules/exchangeRate'
import line from './modules/line'
import coupon from './modules/coupon'
import news from './modules/news'
import modal from './modal'

Vue.use(Vuex)

const store = () =>
  new Vuex.Store({
    state: {
      contactModalActive: false,
      registrationFormModal: false,
      authModal: false,
      feedbackModal: false,
      activeTab: 'Upcoming',
      errorLicenseModal: false,
      commissionPaymentModal: false,
      loadClient: false,
      site: null,
      userAccessDeniedModal: false,
      userAccessDeniedDesc: '',
      userCredentials: null,
      casinoFilter: 'top'
    },

    getters: {
      userAccessDeniedDesc: state => state.userAccessDeniedDesc,
      loadClient: state => state.loadClient,
      site: state => state.site,
      siteName: state => state.site.vars.name.begin + state.site.vars.name.end,
      activeTab: state => state.activeTab,
      userCredentials: state => state.userCredentials,
      casinoFilter: state => state.casinoFilter,
      isCasino: state => state.site.vars.casino,
      isNews: state => state.site.vars.news,
      errorLicenseText: state => state.site.vars?.errorLicenseText,
      commissionPaymentText: state => state.site.vars?.commissionPaymentText,

      addingEvents: state => state.site.addingEvents,
    },

    mutations: {
      setUserCredentials: (state, userUredentials) =>
        (state.userCredentials = userUredentials),

      setUserAccessDeniedDesc: (state, message) =>
        (state.userAccessDeniedDesc = message),

      changeLoadClient: state => (state.loadClient = !state.loadClient),

      contactModalChanges(state, val) {
        state.contactModalActive = !state.contactModalActive
        document.body.style.overflowY = val
      },

      errorLicenseModalChanges(state, val) {
        state.errorLicenseModal = !state.errorLicenseModal
        document.body.style.overflowY = val
      },

      showRegistrationFormModal(state, val) {
        state.registrationFormModal = !state.registrationFormModal
        document.body.style.overflowY = val
      },

      showAuthModal(state, val) {
        state.authModal = !state.authModal
        document.body.style.overflowY = val
      },

      showFeedbackModal(state, val) {
        state.feedbackModal = !state.feedbackModal
        document.body.style.overflowY = val
      },

      showCommissionPaymentModal(state, val) {
        state.commissionPaymentModal = !state.commissionPaymentModal
        document.body.style.overflowY = val
      },

      showUserAccessDeniedModal(state, val) {
        state.userAccessDeniedModal = !state.userAccessDeniedModal
        document.body.style.overflowY = val
      },

      setActiveTab: (state, tabName) => (state.activeTab = tabName),

      setSite: (state, site) => {
        state.site = site
      },

      setStake: (state, amount) => (state.stake = amount),

      setCasinoFilter: (state, filter) => (state.casinoFilter = filter)
    },

    actions: {
      getSite: ({ commit }) => {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/site')
            .then(res => {
              commit('setSite', res.data)
              resolve()
            })
            .catch(err => reject(err))
        })
      },

      activeContactUsModal(context, val) {
        context.commit('contactModalChanges', val)
      },

      activeFeedbackModal(context, val) {
        context.commit('showFeedbackModal', val)
      },

      activeErrorLicenseModal(context, val) {
        context.commit('errorLicenseModalChanges', val)
      },

      SEND_FEEDBACK: (context, feedback) => {
        return new Promise((resolve, reject) => {
          axios
            .post('/api/feedback', feedback)
            .then(() => resolve())
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      }
    },

    modules: {
      auth,
      exchangeRate,
      coupon,
      line,
      news,
      modal
    }
  })

export default store
