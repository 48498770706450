<template>
  <div class="d-flex" style="height: 100%;" v-if="visible">
    <div class="w-33 points">{{ numberOfPoints }}</div>
    <div class="w-33" v-for="odd in results" :key="odd.id">
      <button
        class="odd-btn"
        @click="addBet(odd)"
        :class="{ oddBtnSelected: bets.find(bet => bet.id == odd.id) }"
      >
        {{ odd.odds }}
      </button>
    </div>
  </div>
  <div class="lock" v-else>
    <svg viewBox="0 0 16.86 22.48">
      <title>lock</title>
      <g id="lock">
        <g id="lock_2">
          <path
            d="M12.18,8.43H4.68V6.56a3.75,3.75,0,0,1,7.5,0V8.43ZM9.54,15.56l.29,2.65a.48.48,0,0,1-.11.37.47.47,0,0,1-.35.15H7.49a.47.47,0,0,1-.35-.15A.48.48,0,0,1,7,18.21l.29-2.65a1.85,1.85,0,0,1-.76-1.51,1.87,1.87,0,0,1,3.74,0,1.85,1.85,0,0,1-.76,1.51Zm6.85-7.13H15V6.56a6.56,6.56,0,0,0-13.12,0V8.43H.47A.47.47,0,0,0,0,8.9V20.61a1.87,1.87,0,0,0,1.87,1.87H15a1.87,1.87,0,0,0,1.87-1.87V8.9a.47.47,0,0,0-.47-.47Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'OtherOdds',

  props: {
    event: Object
  },

  computed: {
    ...mapGetters(['bets']),

    currentMarket() {
      if (this.event.Odd != null) {
        const markets = this.event.Odd.Markets

        const filteredArr = markets.filter(
          obj =>
            obj.name.value.indexOf('Total Goals - Over/Under') != -1 ||
            obj.name.value.indexOf('Total Games - Match') != -1 ||
            obj.name.value.indexOf('Totals') != -1 ||
            obj.name.value.indexOf(
              'How many points will be scored in total?'
            ) != -1 ||
            obj.name.value.indexOf(
              'How many points will be scored in the first set?'
            ) != -1 ||
            obj.name.value.indexOf(
              'How many points will be scored in the second set?'
            ) != -1 ||
            obj.name.value.indexOf(
              'How many points will be scored in the third set?'
            ) != -1 ||
            obj.name.value.indexOf(
              'How many points will be scored in the fourth set?'
            ) != -1
        )

        if (filteredArr.length > 0) {
          const result = filteredArr.reduce(function(prev, curr) {
            return prev.attr < curr.attr ? prev : curr
          })

          return result
        }
      }
      return ''
    },

    results() {
      return this.currentMarket.results
    },

    numberOfPoints() {
      return this.currentMarket.attr
    },

    visible() {
      const odds = this.results
      if (odds != null && odds.length > 0) {
        return true
      }
      return false
    }
  },

  methods: {
    ...mapMutations(['addBetToCoupon']),

    addBet(odd) {
      const bet = {
        id: odd.id,
        eventId: this.event.Id,
        name: odd.name.value,
        market: this.currentMarket.name.value,
        event: this.event.HomeTeam + ' - ' + this.event.AwayTeam,
        odd: odd.odds
      }
      this.addBetToCoupon(bet)
    }
  }
}
</script>
