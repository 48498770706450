<template>
  <div
    class="modal"
    id="registration-modal"
    :class="{ isVisibleFlex: this.$store.state.registrationFormModal }"
    @click="closeModal"
  >
    <div class="modal-container registration" @click.stop>
      <div class="close-btn" @click="closeModal">
        <svg viewBox="0 0 20 20">
          <path
            d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
          />
        </svg>
      </div>
      <div class="modal-body">
        <div class="registration__description">
          <div class="registration__title">SIGN UP</div>
          <div class="feedback-contacts">
            <div class="feedback__mail">
              {{ $store.getters.site.contacts.joinEmail }}
            </div>
            <div class="feedback__description">
              To purchase a license key, please contact us at.
            </div>
          </div>
          <div class="registration__intro">
            <div class="registration__intro-icon">
              <svg viewBox="0 0 29.51 29.51">
                <g>
                  <path
                    style=""
                    d="M 14.75 0 A 14.76 14.76 0 1 0 29.51 14.75 A 14.75 14.75 0 0 0 14.75 0 Z m 3.07 22.87 L 16 23.55 a 4.73 4.73 0 0 1 -1.58 0.24 a 3.1 3.1 0 0 1 -2.14 -0.68 a 2.14 2.14 0 0 1 -0.77 -1.71 a 5.76 5.76 0 0 1 0.06 -0.82 c 0 -0.28 0.1 -0.59 0.18 -0.95 l 1 -3.36 c 0.09 -0.32 0.16 -0.62 0.22 -0.9 a 4.91 4.91 0 0 0 0.08 -0.8 a 1.2 1.2 0 0 0 -0.27 -0.89 a 1.47 1.47 0 0 0 -1 -0.25 a 2.48 2.48 0 0 0 -0.75 0.11 l -0.66 0.22 l 0.25 -1 c 0.62 -0.26 1.22 -0.47 1.78 -0.66 A 5.37 5.37 0 0 1 14 11.8 a 3 3 0 0 1 2.11 0.66 a 2.19 2.19 0 0 1 0.75 1.72 c 0 0.15 0 0.41 -0.05 0.77 a 5.67 5.67 0 0 1 -0.19 1 l -0.95 3.35 c -0.07 0.27 -0.14 0.58 -0.21 0.92 a 5 5 0 0 0 -0.09 0.78 a 1.12 1.12 0 0 0 0.3 0.91 a 1.61 1.61 0 0 0 1 0.24 a 3 3 0 0 0 0.79 -0.12 a 5.67 5.67 0 0 0 0.63 -0.21 Z M 17.66 9.28 a 2.37 2.37 0 0 1 -3.2 0 a 2 2 0 0 1 -0.66 -1.49 a 2 2 0 0 1 0.66 -1.5 a 2.4 2.4 0 0 1 3.2 0 a 2 2 0 0 1 0.66 1.5 a 2 2 0 0 1 -0.66 1.49 Z"
                  />
                </g>
              </svg>
            </div>
            <div class="registration__intro-text">
              Please fill in all fields of this short form. <br />
              It will help us validate your identity to comply with our legal
              obligations <br />
              and allow you to enjoy our services.
            </div>
          </div>
          <div class="error__message" v-if="keyError">
            The license key is entered incorrectly
          </div>
          <div class="registration__form form">
            <form @submit.prevent="checkForm">
              <div class="form__row">
                <div class="form__group">
                  <div class="form__label">Title</div>
                  <div class="form__radio">
                    <div
                      class="form__radio-tab"
                      v-for="(gender, index) in genders"
                      :key="index"
                      :class="{ active: selectedGender === gender }"
                      @click="changeGender(gender)"
                    >
                      {{ gender }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__row">
                <div class="form__group">
                  <div class="form__label">First Name</div>
                  <input
                    class="form__input"
                    type="text"
                    v-model="firstName"
                    required
                  />
                </div>
                <div class="form__group">
                  <div class="form__label">Surname</div>
                  <input
                    class="form__input"
                    type="text"
                    v-model="surname"
                    required
                  />
                </div>
              </div>
              <div class="form__row">
                <div class="form__group">
                  <div class="form__label">Date of birth (dd.mm.yyyy)</div>
                  <input
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-model="dateOfBirth"
                    v-mask="'##.##.####'"
                    required
                  />
                </div>
              </div>
              <div class="form__row">
                <div class="form__group">
                  <div class="form__label">Email</div>
                  <input
                    class="form__input"
                    type="email"
                    placeholder="will.smith@company.com"
                    v-model="email"
                    required
                  />
                </div>
              </div>
              <div class="form__row">
                <div class="form__group">
                  <div class="form__label">License key</div>
                  <input
                    class="form__input"
                    type="text"
                    placeholder="AAAA-BBBB-CCCC-DDDD"
                    v-model="licenseKey"
                    v-mask="'NNNN-NNNN-NNNN-NNNN'"
                    required
                  />
                </div>
              </div>
              <div class="form__row">
                <div class="form__group">
                  <div class="form__submit">
                    <input type="submit" value="Continue" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'RegistrationForm',

  directives: {
    mask: VueMaskDirective
  },

  data: () => ({
    genders: ['Mr', 'Mrs', 'Ms', 'Miss'],
    selectedGender: 'Mr',
    firstName: '',
    surname: '',
    dateOfBirth: '',
    email: '',
    licenseKey: '',
    keyError: false
    // errorsValidation: []
  }),

  methods: {
    closeModal() {
      this.$store.commit('showRegistrationFormModal', 'scroll')
    },

    changeGender(gender) {
      this.selectedGender = gender
    },

    checkForm() {
      this.keyError = true
    }
  }
}
</script>

<style>
.registration {
  width: 600px;
  margin: auto;
  max-width: 100%;
  position: relative;
}

.registration .modal-body {
  position: relative;
  height: 169px;
  overflow: hidden;
  text-align: center;
  padding-top: 37px;
}

.close-btn {
  position: absolute;
  box-sizing: border-box;
  left: 5px;
  top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  padding: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.close-btn svg {
  width: 15px;
}

.close-btn svg path {
  fill: #7d757a;
}

.close-btn:hover svg path {
  fill: rgba(255, 255, 255, 1);
}

.registration .title {
  font-size: 20px;
  text-align: center;
  color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(#1e262e, #11121d);
}

.registration .modal-body {
  height: 85vh;
  overflow-y: scroll;
  background-image: url('../../assets/registration-bg.png');
  background-size: cover;
  color: #fff;
}

.registration__title {
  font-weight: 600;
  font-size: 50px;
  font-style: italic;
}

.feedback__mail {
  background: url('../../assets/advantage-icons/center-bg.png') no-repeat center;
  background-size: 80% 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: italic;
  z-index: 1000;
  cursor: pointer;
}

.feedback__mail:hover {
  text-decoration: underline;
}

.feedback__description {
  font-size: 10px;
  margin-top: 5px;
  color: #c6cbc4;
}

.registration__intro {
  background: rgba(0, 0, 0, 0.6);
  margin-top: 10px;
  padding: 5px 10px 10px;
  font-size: 10px;
}

.registration__intro-icon svg {
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
}

.registration__intro-icon svg path {
  fill: #fff;
}

input,
textarea {
  font-family: 'Montserrat', sans-serif;
}

.error__message {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  background: #bb0000;
  min-height: 40px;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 10px 70px;
}

.form__row,
.form__radio {
  display: flex;
}

.form__radio {
  justify-content: space-around;
}

.form__radio-tab {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.form__radio-tab:hover {
  background-color: #cacaca;
}

.form__group {
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
}

.form__label {
  text-align: left;
  font-weight: 500;
}

.form-radio .active {
  background: #000;
  color: #fff;
  border: 1px solid #4b4b4b;
}

.form__group input,
.form__group textarea,
.form__group select {
  border: 1px solid #afafaf;
  width: 100%;
  color: #000;
}

.form__group input,
.form__group select {
  height: 26px;
  padding: 0 10px;
}

.form__submit input[type='submit'] {
  width: 100%;
  border: 2px solid #fff;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 25px;
  height: 35px;
  margin-top: 10px;
  background: none;
  cursor: pointer;
}

.form__submit input[type='submit']:hover:not(:disabled) {
  background-color: #fff;
  color: #000;
}

/* .registration .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  } */

.registration .modal-body::-webkit-scrollbar {
  width: 6px;
}

.registration .modal-body::-webkit-scrollbar-thumb {
  background-color: #525252;
  border-radius: 5px;
}

@media only screen and (max-width: 992px) {
  .form {
    padding: 0 20px;
  }
}
</style>
