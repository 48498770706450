<template>
  <preloader v-if="loadLine" />
  <div class="content" id="home-content" v-else>
    <HeaderPromo />
    <div id="page-wrapper">
      <div id="main-wrap">
        <div id="main" style="border-top: 1px solid #7a7a7b">
          <div class="center">
            <div id="bet-offer">
              <main-line />
            </div>
          </div>
          <div class="right">
            <bidConfigurator />
            <casino-banner v-if="isCasino" />
            <news-block v-if="isNews" />
          </div>
        </div>
        <advantages />
      </div>
    </div>
    <user-access-denied v-if="isUserAccessDeniedModalVisible" />
    <registration-form />
    <auth />
  </div>
</template>

<script>
import RegistrationForm from './modals/RegistrationForm'
import Auth from './modals/Auth'
import HeaderPromo from './HeaderPromo'
import mainLine from './Line'
import preloader from './Line/preloader'
import bidConfigurator from './right/bidConfigurator'
import advantages from './advantages'
import UserAccessDenied from './modals/UserAccessDenied'
import CasinoBanner from './right/CasinoBanner'
import NewsBlock from './right/NewsBlock'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',

  components: {
    RegistrationForm,
    Auth,
    HeaderPromo,
    preloader,
    mainLine,
    bidConfigurator,
    advantages,
    UserAccessDenied,
    CasinoBanner,
    NewsBlock
  },

  computed: {
    ...mapGetters(['isCasino', 'isNews', 'loadLine']),

    ...mapGetters('modal', {
      $_isModalVisible: 'isVisible'
    }),

    isUserAccessDeniedModalVisible() {
      return this.$_isModalVisible({ name: 'UserAccessDenied' })
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,400i,500,700,700i&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
button {
  font-family: 'Montserrat', sans-serif;
  background-color: #38393f;
}

body {
  margin: 0;
  padding: 0;
}

body::after {
  position: absolute;
  z-index: -1;
  content: url('../assets/header-promo-bg.jpg')
    url('../assets/registration-bg.png');
  width: 0;
  height: 0;
  overflow: hidden;
}

.container {
  width: 100%;
  min-height: 100%;
}

.scrollHidden {
  overflow: hidden;
}

#main-wrap {
  margin: 0 auto;
}

#main-wrap #main {
  padding: 10px;
  display: flex;
  /* width: 955px; */
  overflow: visible;
  /* margin-left: auto; */
  /* margin-right: auto; */
  position: relative;
}

.center {
  float: left;
  width: calc(70% - 10px);
  margin-right: 10px;
  position: relative;
}

.right {
  float: left;
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  position: sticky;
  top: 85px;
  width: calc(30% - 10px);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

a,
a:hover {
  color: white;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #ffffff;
}

.isVisible {
  display: block !important;
}

.isVisibleFlex {
  display: flex !important;
}

.widget__title {
  position: relative;
  display: flex;
  background: #151516;
  height: 36px;
  align-items: center;
  font-size: 100%;
  padding: 0px 30px 0 30px;
  color: #fff;
}

.widget__content {
  background: #29292b;
}

.sports-link {
  padding: 7px 17px 25px;
}

.sports-link__item {
  border-bottom: 1px solid #808081;
  padding: 7px 0 3px;
}

@media only screen and (min-width: 1560px) {
  .right {
    width: 25%;
  }

  .center {
    width: calc(75% - 10px);
  }
}

@media only screen and (max-width: 992px) {
  #main-wrap #main {
    display: block;
    padding: 0 0 10px;
  }

  .right,
  .center {
    width: 100%;
    margin: 0;
  }

  .center {
    text-align: center;
  }

  .right {
    max-height: 100%;
    overflow: hidden;
    margin-top: 5px;
    bottom: 0;
    width: 100%;
    position: static;
  }
}

/* 
  моб стили линии

  .right {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .center {
    width: 100%;
    margin: 0;
  } */
</style>
