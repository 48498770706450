<template>
  <div
    class="payment__status status"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div class="status__title">{{ title }}</div>
    <div class="status__icon">
      <img :src="icon" alt="payment-status-icon" width="25" height="25" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '../../../assets/status-icons/payment/check.svg'
import RepeatIcon from '../../../assets/status-icons/payment/repeat.svg'
import FailIcon from '../../../assets/status-icons/payment/fail.svg'
import InProgressIcon from '../../../assets/status-icons/payment/in-progress.svg'

export default {
  name: 'PaymentStatus',

  props: {
    status: String
  },

  computed: {
    title() {
      switch (this.status) {
        case 'success':
          return 'Successfully'
        case 'fail':
          return 'Failed'
        case 'check':
          return 'Repeat'
        default:
          return 'Loading'
      }
    },

    icon() {
      switch (this.status) {
        case 'success':
          return CheckIcon
        case 'fail':
          return FailIcon
        case 'check':
          return RepeatIcon
        default:
          return InProgressIcon
      }
    },

    backgroundColor() {
      switch (this.status) {
        case 'success':
          return '#459a3d'
        case 'fail':
          return '#992c2c'
        case 'check':
          return '#29292b'
        default:
          return '#20201e'
      }
    }
  }
}
</script>

<style>
.status .status__title {
  font-weight: 600;
  font-size: 20px;
  font-style: italic;
}
</style>
