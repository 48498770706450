<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FormattedDate',

  props: {
    date: Number
  },

  computed: {
    formattedDate() {
      return moment.unix(this.date).format('DD.MM.YYYY HH:mm:ss')
    }
  }
}
</script>
