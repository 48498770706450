import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import types from './types'

export default {
  namespaced: true,

  state: () => ({
    modals: []
  }),

  actions,
  getters,
  mutations,
  types
}
