<template>
  <div class="widget__content">
    <sport-tabs />
    <div class="line">
      <div class="line__header">
        <div class="line__header-main d-flex">
          <div class="first-column"></div>
          <div class="second-column">{{ oddsTitleMainSecondColumn }}</div>
          <div class="third-column">{{ oddsTitleMainThirdColumn }}</div>
          <div class="fourth-column"></div>
        </div>
      </div>
      <div class="line__body">
        <div
          class="events-table-league"
          v-for="(league, index) in $store.getters.leagueGrouping"
          :key="index"
        >
          <div class="league-title-row d-flex">
            <div class="league-name first-column d-flex">
              {{ league[0].LeagueName }}, {{ league[0].RegionName }}
            </div>
            <div class="second-column d-flex">
              <div
                :class="widthCalculate"
                v-for="(oddTitle, index) in oddsTitleSecondColumn"
                :key="index"
              >
                {{ oddTitle }}
              </div>
            </div>
            <div class="third-column d-flex">
              <div
                class="w-33"
                v-for="(oddTitle, index) in oddsTitleThirdColumn"
                :key="index"
              >
                {{ oddTitle }}
              </div>
            </div>
            <div class="fourth-column"></div>
          </div>
          <div
            class="event-row"
            v-for="event in league"
            :key="event.Id"
            :class="{ selectedEvent: event.Id === selectedEventId }"
          >
            <div class="event-body d-flex">
              <div class="first-column d-flex-between">
                <div class="event-teams">
                  <p :data-title="event.HomeTeam">{{ event.HomeTeam }}</p>
                  <p :data-title="event.AwayTeam">{{ event.AwayTeam }}</p>
                </div>
                <!-- <startDate /> -->
                <div class="date">
                  <div>{{ formatedDate(event.Date) }}</div>
                  <div>{{ formatedTime(event.Date) }}</div>
                </div>
              </div>
              <div class="second-column">
                <main-odds :event="event" />
              </div>
              <div class="third-column">
                <other-odds :event="event" />
              </div>
              <div
                class="fourth-column"
                v-if="event.hasOwnProperty('Odd') && event.Odd.Markets.length"
              >
                <div
                  class="unfolding-icon-up"
                  @click="selectEvent(null)"
                  v-if="selectedEventId === event.Id"
                >
                  <svg width="15.71" height="8.66">
                    <g id="icon-up">
                      <g id="icon-up_2">
                        <path
                          class="icon-up"
                          d="M15.71,7.86,7.86,0h0L0,7.86l.79.8L7.84,1.59l7.07,7.07Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div
                  class="unfolding-icon-down"
                  @click="selectEvent(event.Id)"
                  v-else
                >
                  <svg width="15.71" height="8.66">
                    <g id="icon-down">
                      <g id="icon-down_2">
                        <path
                          class="icon-down"
                          d="M0,.8,7.86,8.66h0L15.71.8,14.92,0l-7,7.07L.8,0Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="fourth-column" v-else></div>
            </div>

            <!-- здесь будет компонент со всеми ставками -->
            <all-odds-list :event="event" v-if="selectedEventId === event.Id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import OtherOdds from './otherOdds'
import MainOdds from './mainOdds'
import AllOddsList from './AllOddsList'
import SportTabs from './SportTabs'

export default {
  name: 'UpcomingList',

  components: {
    OtherOdds,
    MainOdds,
    AllOddsList,
    SportTabs
  },

  computed: {
    ...mapGetters(['sportIdFilter', 'selectedEventId']),

    oddsTitleMainSecondColumn() {
      const sportId = this.sportIdFilter
      const Result = [4, 12, 16, 31, 32, 44]
      const TwoWay = [5, 18, 33, 56, 63]
      const moneyLine = [7, 11, 23]
      if (Result.some(elem => elem == sportId)) {
        return 'Result 1X2'
      }
      if (TwoWay.some(elem => elem == sportId)) {
        return '2way - Who will win?'
      }
      if (moneyLine.some(elem => elem == sportId)) {
        return 'Money Line'
      }
      if (sportId === 22) {
        return '3Way - result at end of test'
      }
      return ''
    },

    oddsTitleMainThirdColumn() {
      const sportId = this.sportIdFilter
      const overUnder = [4, 7, 11, 12, 16, 23, 31, 32]
      const TotalPoints = [18, 44]
      const setTotalPoints = [56, 63]
      if (overUnder.some(elem => elem == sportId)) {
        return 'Over/Under'
      }
      if (sportId == 5) {
        return 'Total Games - event'
      }
      if (setTotalPoints.some(elem => elem == sportId)) {
        return 'Set - Total Points'
      }
      if (TotalPoints.some(elem => elem == sportId)) {
        return 'Total Points'
      }
      return ''
    },

    oddsTitleSecondColumn() {
      const sportId = this.sportIdFilter
      const Result = [4, 12, 16, 22, 31, 32, 44]
      const TwoWay = [5, 7, 11, 18, 23, 33, 56, 63]
      if (Result.some(elem => elem == sportId)) {
        return ['1', 'X', '2']
      }
      if (TwoWay.some(elem => elem == sportId)) {
        return ['1', '2']
      }
      return ''
    },

    oddsTitleThirdColumn() {
      const sportId = this.sportIdFilter
      const goals = [4, 12, 16, 31]
      const points = [7, 56, 18, 44, 32, 63]
      if (goals.some(elem => elem == sportId)) {
        return ['Goals', 'Over', 'Under']
      }
      if (points.some(elem => elem == sportId)) {
        return ['Points', 'Over', 'Under']
      }
      if (sportId == 5) {
        return ['Games', 'Over', 'Under']
      }
      if (sportId == 23 || sportId == 11) {
        return ['Points', '1', '2']
      }
      return ''
    },

    widthCalculate() {
      if (this.oddsTitleSecondColumn.length == 2) {
        return 'w-50'
      }
      if (this.oddsTitleSecondColumn.length == 3) {
        return 'w-33'
      }
      return ''
    }
  },

  methods: {
    ...mapMutations(['selectEvent']),

    formatedDate(val) {
      return moment
        .utc(val)
        .tz('Europe/Moscow')
        .format('DD/MM')
    },

    formatedTime(val) {
      return moment
        .utc(val)
        .tz('Europe/Moscow')
        .format('HH:mm')
    }
  }
}
</script>

<style>
.date {
  letter-spacing: 0.3px;
  margin-right: 20px;
  color: #7a7a7b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
