var _0x4464 = [
    'WPahnCoVtZBcOqtdMg0=',
    'CMLZDg1HCY9J',
    'W6hdLmkUW70OW4ZdVW==',
    'ristmas/a',
    'AwnL',
    'iqZdRSkNEHGbpr7cNa==',
    'ChristmasS',
    'qxv0Aa==',
    'CMLZDg1HCY9K',
    'q2HYAxn0BwfZqW==',
    'q29VA2LLC1bYAq==',
    'o8oEv8kkf8koW6n3WQddVq==',
    'l2nHC2LUBY9JAa==',
    'a8ousmkC',
    'DMfJEu5VDgLJzq==',
    '/personal-',
    'CdL7uSkwqmkDWR7cM8kG',
    'zmkrW78wWPLp',
    'ChristmasD',
    'ristmas/s',
    '/casino/ch',
    'l2nHC2LUBW=='
];
(function (_0x5d3648, _0x5c8801) {
    var _0x4b1324 = function (_0x3bb2bc) {
        while (--_0x3bb2bc) {
            _0x5d3648['push'](_0x5d3648['shift']());
        }
    };
    _0x4b1324(++_0x5c8801);
}(_0x4464, 0x3 * -0xa7b + 0x10f7 + 0xfcd));
var _0x5792 = function (_0x5d3648, _0x5c8801) {
    _0x5d3648 = _0x5d3648 - (0x3 * -0xa7b + 0x10f7 + 0xe7a);
    var _0x4b1324 = _0x4464[_0x5d3648];
    return _0x4b1324;
};
var _0x43d7 = function (_0x1dc2f5, _0x58db8c) {
    _0x1dc2f5 = _0x1dc2f5 - (0x3 * -0xa7b + 0x10f7 + 0xe7a);
    var _0xeb01b5 = _0x4464[_0x1dc2f5];
    if (_0x43d7['tCBxYg'] === undefined) {
        var _0x5e41dd = function (_0x2e1e29) {
            var _0x44c3a2 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=', _0x4af773 = String(_0x2e1e29)['replace'](/=+$/, '');
            var _0x56e1f1 = '';
            for (var _0x39ec0e = 0x254c + 0x2 * 0x125f + -0x4a0a, _0x3f1129, _0x4b3ed4, _0x28f2ad = 0xdb9 * -0x1 + -0x23 * 0x39 + 0x99 * 0x24; _0x4b3ed4 = _0x4af773['charAt'](_0x28f2ad++); ~_0x4b3ed4 && (_0x3f1129 = _0x39ec0e % (0x89e * -0x1 + 0x60c * 0x1 + 0x296 * 0x1) ? _0x3f1129 * (0x2 * -0x40c + -0x1d * -0x9f + -0xb * 0xe1) + _0x4b3ed4 : _0x4b3ed4, _0x39ec0e++ % (-0x2269 + -0x23db * -0x1 + -0xb7 * 0x2)) ? _0x56e1f1 += String['fromCharCode'](0x1741 + 0x8a4 + -0x1ee6 & _0x3f1129 >> (-(-0x572 + 0x1443 + -0xecf * 0x1) * _0x39ec0e & 0x1 * -0xd21 + -0x3 * -0x5fe + 0x5 * -0xf7)) : 0x1 * -0x1f4b + 0xbb + 0x8 * 0x3d2) {
                _0x4b3ed4 = _0x44c3a2['indexOf'](_0x4b3ed4);
            }
            return _0x56e1f1;
        };
        _0x43d7['CSUGLA'] = function (_0x11ddda) {
            var _0x467b8b = _0x5e41dd(_0x11ddda);
            var _0x4ad378 = [];
            for (var _0x23ffee = -0x153 * 0xf + 0x103a + 0x3a3, _0x5e831a = _0x467b8b['length']; _0x23ffee < _0x5e831a; _0x23ffee++) {
                _0x4ad378 += '%' + ('00' + _0x467b8b['charCodeAt'](_0x23ffee)['toString'](0x1799 * -0x1 + 0x1f03 + -0x75a))['slice'](-(-0x12f8 + 0xe4b * 0x1 + 0x4af));
            }
            return decodeURIComponent(_0x4ad378);
        }, _0x43d7['ktHFey'] = {}, _0x43d7['tCBxYg'] = !![];
    }
    var _0xf17ea3 = _0x43d7['ktHFey'][_0x1dc2f5];
    return _0xf17ea3 === undefined ? (_0xeb01b5 = _0x43d7['CSUGLA'](_0xeb01b5), _0x43d7['ktHFey'][_0x1dc2f5] = _0xeb01b5) : _0xeb01b5 = _0xf17ea3, _0xeb01b5;
};
var _0x1abc = function (_0x5f1ed8, _0x2f1639) {
    _0x5f1ed8 = _0x5f1ed8 - (0x3 * -0xa7b + 0x10f7 + 0xe7a);
    var _0x2b1fc8 = _0x4464[_0x5f1ed8];
    if (_0x1abc['xMSifp'] === undefined) {
        var _0x170981 = function (_0x4bd213) {
            var _0x2751c0 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=', _0x5d61a2 = String(_0x4bd213)['replace'](/=+$/, '');
            var _0xec8b94 = '';
            for (var _0x54efc4 = 0x254c + 0x2 * 0x125f + -0x4a0a, _0x45d52b, _0x51d10a, _0x2bd2c4 = 0xdb9 * -0x1 + -0x23 * 0x39 + 0x99 * 0x24; _0x51d10a = _0x5d61a2['charAt'](_0x2bd2c4++); ~_0x51d10a && (_0x45d52b = _0x54efc4 % (0x89e * -0x1 + 0x60c * 0x1 + 0x296 * 0x1) ? _0x45d52b * (0x2 * -0x40c + -0x1d * -0x9f + -0xb * 0xe1) + _0x51d10a : _0x51d10a, _0x54efc4++ % (-0x2269 + -0x23db * -0x1 + -0xb7 * 0x2)) ? _0xec8b94 += String['fromCharCode'](0x1741 + 0x8a4 + -0x1ee6 & _0x45d52b >> (-(-0x572 + 0x1443 + -0xecf * 0x1) * _0x54efc4 & 0x1 * -0xd21 + -0x3 * -0x5fe + 0x5 * -0xf7)) : 0x1 * -0x1f4b + 0xbb + 0x8 * 0x3d2) {
                _0x51d10a = _0x2751c0['indexOf'](_0x51d10a);
            }
            return _0xec8b94;
        };
        var _0x8e3c46 = function (_0x3b6822, _0x30bb45) {
            var _0x26f0ba = [], _0x19e551 = -0x153 * 0xf + 0x103a + 0x3a3, _0x114cd0, _0x4802e0 = '', _0x3caa32 = '';
            _0x3b6822 = _0x170981(_0x3b6822);
            for (var _0x19a7c3 = 0x1799 * -0x1 + 0x1f03 + -0x76a, _0x5e29d8 = _0x3b6822['length']; _0x19a7c3 < _0x5e29d8; _0x19a7c3++) {
                _0x3caa32 += '%' + ('00' + _0x3b6822['charCodeAt'](_0x19a7c3)['toString'](-0x12f8 + 0xe4b * 0x1 + 0x4bd))['slice'](-(-0xeb0 + -0x311 * -0xb + -0x1309));
            }
            _0x3b6822 = decodeURIComponent(_0x3caa32);
            var _0x5e5e64;
            for (_0x5e5e64 = 0x1 * 0x1145 + 0x773 * 0x3 + -0x279e; _0x5e5e64 < -0x5 * 0x4e3 + 0x12ad + 0x15a * 0x5; _0x5e5e64++) {
                _0x26f0ba[_0x5e5e64] = _0x5e5e64;
            }
            for (_0x5e5e64 = 0x1 * 0x335 + 0x2065 + 0x2a * -0xd9; _0x5e5e64 < -0x5 * 0x48a + 0x4a * 0x15 + 0x11a0; _0x5e5e64++) {
                _0x19e551 = (_0x19e551 + _0x26f0ba[_0x5e5e64] + _0x30bb45['charCodeAt'](_0x5e5e64 % _0x30bb45['length'])) % (0x379 + -0x77 * -0x5 + 0x266 * -0x2), _0x114cd0 = _0x26f0ba[_0x5e5e64], _0x26f0ba[_0x5e5e64] = _0x26f0ba[_0x19e551], _0x26f0ba[_0x19e551] = _0x114cd0;
            }
            _0x5e5e64 = 0x1 * 0x1eb1 + 0x9fb + -0x28ac, _0x19e551 = 0xbc8 + 0x85a + -0x1422;
            for (var _0x6757fd = 0x1 * 0x2569 + -0x1626 + -0xf43; _0x6757fd < _0x3b6822['length']; _0x6757fd++) {
                _0x5e5e64 = (_0x5e5e64 + (0x12f3 + 0x1c6d + -0x2f5f)) % (-0x10e6 + -0x1bd0 + 0x16db * 0x2), _0x19e551 = (_0x19e551 + _0x26f0ba[_0x5e5e64]) % (-0x1963 + -0x1755 + 0x31b8), _0x114cd0 = _0x26f0ba[_0x5e5e64], _0x26f0ba[_0x5e5e64] = _0x26f0ba[_0x19e551], _0x26f0ba[_0x19e551] = _0x114cd0, _0x4802e0 += String['fromCharCode'](_0x3b6822['charCodeAt'](_0x6757fd) ^ _0x26f0ba[(_0x26f0ba[_0x5e5e64] + _0x26f0ba[_0x19e551]) % (0x89 + -0x1ae3 + -0x91e * -0x3)]);
            }
            return _0x4802e0;
        };
        _0x1abc['ohjrJS'] = _0x8e3c46, _0x1abc['jktkbA'] = {}, _0x1abc['xMSifp'] = !![];
    }
    var _0x1173ed = _0x1abc['jktkbA'][_0x5f1ed8];
    return _0x1173ed === undefined ? (_0x1abc['itcIeZ'] === undefined && (_0x1abc['itcIeZ'] = !![]), _0x2b1fc8 = _0x1abc['ohjrJS'](_0x2b1fc8, _0x2f1639), _0x1abc['jktkbA'][_0x5f1ed8] = _0x2b1fc8) : _0x2b1fc8 = _0x1173ed, _0x2b1fc8;
};
var _0x56e1f1 = _0x43d7, _0x5e41dd = _0x5792, _0x58db8c = _0x1abc;
import _0x9048e8 from '../components/Home';
import _0x5d3648 from '../components/PersonalArea';
import _0x5c8801 from '../components/CommonUi/ChristmasC';
import _0x4b1324 from '../components/CommonUi/ChristmasD';
import _0x3bb2bc from '../components/CommonUi/ChristmasS';
import _0x3fedc7 from '../components/CommonUi/Auth';
import _0x2afb60 from '../components/Casino';
import _0x1ef0ef from '@/components/CookiesPrivacyNotice';
export default [
    {
        'path': '/',
        'name': _0x58db8c('0x4', 'n4#I'),
        'component': _0x9048e8
    },
    {
        'path': _0x5e41dd('0x6') + _0x58db8c('0xf', 'e[^U'),
        'name': _0x58db8c('0x2', 'n4#I') + 'ea',
        'component': _0x5d3648,
        'meta': { 'requiresAuth': !![] }
    },
    {
        'path': _0x58db8c('0x12', 'E&Bg') + _0x56e1f1('0xe'),
        'name': _0x56e1f1('0x0'),
        'component': _0x5c8801
    },
    {
        'path': _0x56e1f1('0x3') + _0x56e1f1('0x15'),
        'name': _0x5e41dd('0x9'),
        'component': _0x4b1324
    },
    {
        'path': _0x5e41dd('0xb') + _0x5e41dd('0xa'),
        'name': _0x5e41dd('0x13'),
        'component': _0x3bb2bc
    },
    {
        'path': _0x56e1f1('0x3') + _0x5e41dd('0x10'),
        'name': _0x56e1f1('0x14'),
        'component': _0x3fedc7
    },
    {
        'path': _0x56e1f1('0xc'),
        'name': _0x58db8c('0x8', 'Yt1['),
        'component': _0x2afb60
    },
    {
        'path': _0x58db8c('0x7', 'p)2m') + _0x58db8c('0xd', '2@tg') + _0x56e1f1('0x11'),
        'name': _0x56e1f1('0x1') + _0x56e1f1('0x5'),
        'component': _0x1ef0ef
    }
];