<template>
  <error-auth-message v-if="isErrorAuth" />
  <preloader v-else-if="isClientLoading" />
  <div v-else id="page-wrapper" style="padding-top: 75px;">
    <div id="main-wrap">
      <div id="main">
        <div class="left" style="position: relative; top: 0;">
          <balance :balance="form.balance" />
          <left-menu :activeTab="'Deposit'" />
        </div>
        <div class="pa-content-section" style="min-height: calc(100vh - 95px)">
          <christmas-d-widget
            v-if="form.newDesign"
            :walletAddress="form.walletAddress"
            :payment="form.payment"
            :redAlert="form.redAlert"
            :paymentSystem="form.selectedPaymentSystem"
            :commentLabel="form.commentLabel"
            :isComment="form.isComment"
          />
          <christmas-d-widget-old-design
            v-else
            :walletAddress="form.walletAddress"
            :payment="form.payment"
            :whiteAlert="form.whiteAlert"
            :paymentSystem="form.selectedPaymentSystem"
            :commentLabel="form.commentLabel"
            :isComment="form.isComment"
            :walletType="walletType"
            :statusText="form.statusText"
          />
        </div>
      </div>
      <div class="commission-form">
        <div class="form__row">
          <div class="form__group" v-if="!form.balance.single">
            <div class="form__label">Заблокированый баланс</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.balance.blocked.amount"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">{{ balanceText }}</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.balance.active.amount"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group" style="width: 70%; padding-right: 0;">
            <div class="form__label">Сумма пополнения</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.payment.total.amount"
              required
            />
          </div>
          <div class="form__group" style="width: 30%; padding-left: 0;">
            <div class="form__label">Валюта</div>
            <select
              class="form__input"
              v-model="form.payment.total.currency"
              required
            >
              <option
                v-for="(option, index) in currencyList"
                :key="index"
                :value="option"
                >{{ option }}</option
              >
            </select>
          </div>
          <div class="form__group">
            <div class="form__label">Платёжнеая система</div>
            <select
              class="form__input"
              v-model="form.selectedPaymentSystem"
              required
            >
              <option
                v-for="(option, index) in paymentSystemList"
                :key="index"
                :value="option"
                >{{ option.text }}</option
              >
            </select>
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Кошелёк для принятия средств</div>
            <input
              v-if="isWalletAddressCard"
              class="form__input"
              type="text"
              v-model="form.walletAddress"
              required
              v-mask="'#### #### #### ####'"
            />
            <input
              v-else
              class="form__input"
              type="text"
              v-model="form.walletAddress"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Заглавие поля с комментом</div>
            <input
              class="form__input"
              type="text"
              v-model="form.commentLabel"
              :disabled="!form.isComment"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">Комментарий к платежу</div>
            <input
              class="form__input"
              type="text"
              v-model="form.payment.comment"
              :disabled="!form.isComment"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Статус платежа</div>
            <select
              class="form__input"
              v-model="form.payment.status.name"
              required
            >
              <option
                v-for="(option, index) in paymentStatusList"
                :key="index"
                :value="option.value"
                >{{ option.text }}</option
              >
            </select>
          </div>
          <div class="form__group" v-if="!form.newDesign">
            <div class="form__label">Текст статуса</div>
            <input
              class="form__input"
              type="text"
              v-model="form.statusText"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group checkbox" v-if="form.newDesign">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.redAlert"
              style="width: auto;"
              required
            />
            <div class="form__label">Предупреждение красным цветом</div>
          </div>
          <div class="form__group checkbox" v-else>
            <input
              class="form__input"
              type="checkbox"
              v-model="form.whiteAlert"
              style="width: auto;"
              required
            />
            <div class="form__label">Предупреждение белым цветом</div>
          </div>
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.isComment"
              style="width: auto;"
              required
            />
            <div class="form__label">Включить комментарий</div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.newDesign"
              style="width: auto;"
              required
            />
            <div class="form__label">Новый дизайн</div>
          </div>
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.balance.single"
              style="width: auto;"
              required
            />
            <div class="form__label">Единный баланс</div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__group checkbox" v-if="!form.newDesign">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.isAddress"
              style="width: auto;"
              required
            />
            <div class="form__label">Address/Username</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChristmasDWidget from './ChristmasDWidget'
import ChristmasDWidgetOldDesign from './ChristmasDWidgetOldDesign'
import ChristmasMixin from '../../mixins/ChristmasMixin.vue'
import ChristmasCDMixin from '../../mixins/ChristmasCDMixin.vue'
import Preloader from '../../Line/preloader'

export default {
  name: 'ChristmasD',

  mixins: [ChristmasMixin, ChristmasCDMixin],

  components: {
    ChristmasDWidget,
    ChristmasDWidgetOldDesign,
    Preloader
  }
}
</script>
