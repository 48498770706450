<template>
  <div
    class="modal"
    id="feedback-modal"
    :class="{ isVisibleFlex: this.$store.state.feedbackModal }"
    @click="$store.dispatch('activeFeedbackModal', 'scroll')"
  >
    <div class="modal-container feedback" @click.stop>
      <div
        class="close-btn"
        @click="$store.dispatch('activeFeedbackModal', 'scroll')"
      >
        <svg viewBox="0 0 20 20">
          <path
            d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
          />
        </svg>
      </div>
      <div class="modal-body">
        <div class="feedback-title">FEEDBACK FORM</div>
        <div class="error__message" v-if="error">
          {{ error.description }}
        </div>
        <div class="feedback__form form">
          <form @submit.prevent="submit">
            <div class="form__row">
              <div class="form__group">
                <div class="form__label">First Name</div>
                <input
                  class="form__input"
                  type="text"
                  v-model="feedback.first_name"
                  required
                />
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__label">Last Name</div>
                <input
                  class="form__input"
                  type="text"
                  v-model="feedback.second_name"
                  required
                />
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__label">Message</div>
                <textarea
                  class="form__input"
                  type="text"
                  rows="5"
                  v-model="feedback.message"
                  required
                ></textarea>
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__label">Email</div>
                <input
                  class="form__input"
                  type="email"
                  placeholder="will.smith@company.com"
                  v-model="feedback.email"
                  required
                />
              </div>
            </div>
            <div class="form__row">
              <div class="form__group">
                <div class="form__submit">
                  <input type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackForm',

  data: () => ({
    feedback: {
      email: '',
      first_name: '',
      message: '',
      second_name: ''
    },

    error: null
  }),

  methods: {
    submit() {
      this.error = null
      this.$store
        .dispatch('SEND_FEEDBACK', this.feedback)
        .then(() => {
          this.$store.dispatch('activeFeedbackModal', 'scroll')
          this.feedback = {
            email: '',
            first_name: '',
            message: '',
            second_name: ''
          }
        })
        .catch(err => (this.error = err.response.data))
    }
  }
}
</script>

<style>
.feedback {
  width: 600px;
  margin: auto;
  max-width: 100%;
  background-color: #fff;
  background-position: 750px;
  position: relative;
  min-height: 300px;
}

.feedback .close-btn {
  top: 0;
  left: 0;
}

.feedback .modal-body {
  position: relative;
  min-height: 275px;
  padding-top: 37px;
  text-align: center;
  overflow-y: auto;
  background-image: url('../../assets/registration-bg.png');
  background-size: cover;
  color: #fff;
}

.feedback .feedback-title {
  font-weight: 600;
  font-size: 50px;
  font-style: italic;
  margin-bottom: 10px;
}
</style>
