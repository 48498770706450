import axios from 'axios'

const state = {
  newsArray: [],
  loadNews: true
}

const getters = {
  newsArray: state => state.newsArray,

  loadNews: state => state.loadNews
}

const mutations = {
  setNews: (state, newsArray) => (state.newsArray = newsArray),

  setLoadNews: (state, val) => (state.loadNews = val)
}

const actions = {
  fetchNews: async ({ commit }) => {
    commit('setLoadNews', true)

    try {
      const res = await axios.get('/api/news')

      commit('setNews', res.data)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoadNews', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
