<template>
  <div class="unfolding-icon">
    <img :src="unfoldingIcon" alt="unfolding-icon" width="17" />
  </div>
</template>

<script>
import upIcon from '../../assets/unfolding-icons/up.svg'
import downIcon from '../../assets/unfolding-icons/down.svg'

export default {
  name: 'UnfoldingIcon',

  props: {
    item: Number,
    itemList: Array
  },

  computed: {
    unfoldingIcon() {
      if (this.itemList.includes(this.item)) return upIcon
      return downIcon
    }
  }
}
</script>

<style>
.unfolding-icon {
  width: 17px;
}

.unfolding-icon img {
  margin: auto;
  display: block;
  filter: brightness(0) invert(0.5);
}

.transaction:hover .unfolding-icon img,
.coupon__details:hover .unfolding-icon img {
  filter: brightness(0) invert(1);
}
</style>
