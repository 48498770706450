<template>
  <div class="advantage-item">
    <div class="advantage-item__icon">
      <img :src="icon" alt="icon" width="85%" height="85%" />
    </div>
    <div class="advantage-item__body">
      <h5 class="advantage-item__body-title">{{ title }}</h5>
      <p class="advantage-item__body-text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    icon: String
  }
}
</script>

<style>
.advantage-item {
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 40px);
  margin: 20px;
  align-items: center;
}

.advantage-item__icon {
  width: 10%;
  margin-right: 15px;
}

.advantage-item__body {
  color: #fff;
  width: 100%;
  border-left: 1px solid #fff;
  padding-left: 10px;
  height: 100%;
  background: url('../assets/advantage-icons/8.svg') no-repeat right;
  background-size: cover;
}

.advantage-item__body-title {
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  width: 70%;
}

.advantage-item__body-text {
  font-size: 10px;
  width: 70%;
}

.advantage-item:last-child {
  display: block;
  margin: 20px auto 0;
  text-align: center;
  width: 50%;
}

.advantage-item:last-child .advantage-item__icon {
  margin: 0 auto 15px;
}

.advantage-item:last-child .advantage-item__body {
  width: 100%;
  border: none;
  background: url('../assets/advantage-icons/center-bg.png') no-repeat center;
  padding: 0 20%;
  height: auto;
  background-size: 100% 100%;
  text-align: center;
  display: block;
}

.advantage-item:last-child .advantage-item__body-title,
.advantage-item:last-child .advantage-item__body-text {
  width: 100%;
}

@media only screen and (min-width: 1560px) {
  #main-wrap #advantages {
    /* width: 1528px; */
    margin-left: auto;
    margin-right: auto;
  }

  #main-wrap #advantages .advantage-item__body {
    border-left: none;
  }
}

@media only screen and (max-width: 1559px) and (min-width: 1242px) {
  #main-wrap #main,
  #main-wrap #advantages {
    /* width: 1216px; */
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1080px) {
  #main-wrap #advantages {
    /* width: 1100px; */
    margin-left: auto;
    margin-right: auto;
  }

  #main-wrap #advantages .advantage-item {
    width: 100%;
  }

  #main-wrap #advantages .advantage-item__body {
    border-left: none;
  }

  #main-wrap #advantages .advantage-item__body {
    border-left: none;
  }

  #main-wrap #advantages .advantage-item:last-child {
    display: flex;
    margin: 20px;
  }

  .advantage-item:last-child .advantage-item__icon {
    width: 10%;
    margin-right: 15px;
  }

  .advantage-item:last-child .advantage-item__body {
    color: #fff;
    width: 100%;
    border-left: 1px solid #fff;
    padding-left: 10px;
    text-align: left;
    height: 100%;
    background: url('../assets/advantage-icons/8.svg') no-repeat right;
    background-size: cover;
  }

  .advantage-item:last-child .advantage-item__body-title,
  .advantage-item:last-child .advantage-item__body-text {
    width: 70%;
  }
}
</style>
