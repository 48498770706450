import types from './types'
export default {
  [types.SHOW_MODAL](state, modal) {
    state.modals.push(modal)
  },

  [types.CLOSE_MODAL](state) {
    state.modals.pop()
  }
}
