<script>
import LeftMenu from '../CommonUi/LeftMenu'
import Balance from '../CommonUi/Balance'
import AuthFromAdminPanel from './AuthFromAdminPanel.vue'

export default {
  name: 'ChristmasMixin',

  components: {
    LeftMenu,
    Balance
  },

  mixins: [AuthFromAdminPanel],

  data: () => ({
    form: {
      balance: {
        active: {
          amount: 0,
          currency: 'USD'
        },
        blocked: {
          amount: 0,
          currency: 'USD'
        },
        single: false,
        fee: 0,
        exchangeRate: null
      }
    },
  }),

  computed: {
    balanceText() {
      return this.form.balance.single ? 'Баланс' : 'Активный баланс'
    }
  },

  watch: {
    client(client) {
      const exchangeRate = client.settings.exchangeRate
      const balance = client.balance

      this.form.balance.exchangeRate = exchangeRate
        ? exchangeRate / 100
        : null
      this.form.balance.fee = client.settings.fee
      this.form.balance.single = balance.single
      this.form.balance.active.currency = balance.active.currency
      this.form.balance.blocked.currency = balance.blocked.currency

      this.form.balance.active.amount = Number(balance.active.amount) / 100

      this.form.balance.blocked.amount = Number(balance.blocked.amount) / 100
    }
  },

  methods: {
    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>
