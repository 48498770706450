<template>
  <div>redirect</div>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'SecretAuth',

  created() {
    localStorage.removeItem('token')
    delete Axios.defaults.headers.common['X-Token']
    this.$store.commit('CLEAR_TOKEN')

    this.$store.commit('setUserCredentials', this.$route.query)
    this.$router.push('/')
  }
}
</script>
