<template>
  <error-auth-message v-if="isErrorAuth" />
  <preloader v-else-if="isClientLoading" />
  <div v-else id="page-wrapper" style="padding-top: 75px;">
    <div id="main-wrap">
      <div id="main">
        <div class="left" style="position: relative; top: 0;">
          <balance :balance="form.balance" />
          <left-menu :activeTab="'Support'" />
        </div>
        <div
          class="pa-content-section pa-content-section_support"
          style="min-height: calc(100vh - 95px); "
        >
          <christmas-s-widget
            :accountId="String(form.accountId)"
            :requesterName="form.requesterName"
            :supportName="form.supportName"
            :messages="formatMessages"
            :bkLink="form.bkLink"
          />
          <christmas-s-widget-right
            :requesterName="form.requesterName"
            :supportName="form.supportName"
            :requestNumber="String(form.requestNumber)"
            :createdDate="formatCreatedDate"
            :lastActivityDate="formatLastActivityDate"
            :status="form.status"
            :priority="form.priority"
            :bkName="form.bkName"
            :serviceOrProduct="form.serviceOrProduct"
            :ticketType="form.ticketType"
          />
        </div>
      </div>
      <div class="commission-form">
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">ID аккаунта</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.accountId"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">Номер заявки</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.requestNumber"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Имя саппорта</div>
            <input class="form__input" v-model="form.supportName" />
          </div>
          <div class="form__group">
            <div class="form__label">Статус заявки</div>
            <select class="form__input" v-model="form.status" required>
              <option
                v-for="(option, indexStatus) in formStatuses"
                :key="indexStatus"
                :value="option.value"
                >{{ option.text }}</option
              >
            </select>
          </div>
          <div class="form__group">
            <div class="form__label">Важность</div>
            <select class="form__input" v-model="form.priority" required>
              <option
                v-for="(option, indexPriority) in priorityOptions"
                :key="indexPriority"
                :value="option.value"
                >{{ option.text }}</option
              >
            </select>
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Сервис или продукт</div>
            <input
              class="form__input"
              v-model="form.serviceOrProduct"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">Тип заявки</div>
            <input class="form__input" v-model="form.ticketType" required />
          </div>
          <div class="form__group">
            <label for="comissionAmount">Сумма комиссии:</label>
            <input
              id="comissionAmount"
              v-model="form.comissionAmount"
              readonly
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Имя клиента</div>
            <input class="form__input" v-model="form.requesterName" readonly />
          </div>
          <div class="form__group">
            <div class="form__label">Название БК</div>
            <input class="form__input" v-model="form.bkName" readonly />
          </div>
          <div class="form__group">
            <div class="form__label">Домен</div>
            <input class="form__input" v-model="form.bkLink" readonly />
          </div>
        </div>
        <div
          class="form__row"
          style="margin: 12px 0; border: 1px solid grey; border-radius: 5px; position: relative; display: flex; flex-direction: column;"
          v-for="(message, index) in form.messages"
          :key="index"
        >
          <div class="close" @click="removeMessageItem(index)"></div>
          <div class="form__row">
            <div class="form__group">
              <div class="form__group checkbox">
                <input
                  type="radio"
                  id="client"
                  :value="false"
                  v-model="message.isSupport"
                />
                <label for="client">Клиент</label><br />
                <input
                  type="radio"
                  id="support"
                  :value="true"
                  v-model="message.isSupport"
                />
                <label for="support">Саппорт</label><br />
              </div>
            </div>
            <div class="form__group checkbox" v-if="message.isSupport">
              <input
                class="form__input"
                type="checkbox"
                v-model="message.isBottomMessage"
                style="width: auto;"
                required
              />
              <div class="form__label">Сообщение снизу</div>
            </div>
            <div class="form__group">
              <div class="form__label">Дата и время</div>
              <input
                class="form__input"
                type="datetime-local"
                v-model="message.time"
                required
              />
            </div>
            <div class="form__group">
              <div class="form__label">Вид даты</div>
              <select class="form__input" v-model="message.dateType" required>
                <option
                  v-for="(option, ind) in dateTypes"
                  :key="ind"
                  :value="option.value"
                  >{{ option.text }}</option
                >
              </select>
            </div>
          </div>
          <div class="form__row">
            <div class="form__group">
              <textarea
                v-model="message.text"
                rows="4"
                placeholder="Текст сообщения"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="new-message-btn" @click="addMessageItem()">
          Добавить сообщение
          <div class="up-btn" @click="scrollToTop()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { mapGetters } from 'vuex'

import { VMoney } from 'v-money'

import ChristmasSWidget from './ChristmasSWidget'
import ChristmasSWidgetRight from './ChristmasSWidgetRight'
import ChristmasMixin from '../../mixins/ChristmasMixin.vue'
import AuthFromAdminPanel from '@/components/mixins/AuthFromAdminPanel'

import Preloader from '../../Line/preloader'

const DATE_TIME = 'YYYY-MM-DDTHH:mm'
const MONTH_DAY_TIME = 'MMM D, H:mm'
const MONTH_DAY_YEAR_TIME = 'MMM D YYYY, H:mm'

const MESSAGE_ITEM = {
  isSupport: true,
  isBottomMessage: true,
  time: moment().format(DATE_TIME),
  text: 'Hello!',
  dateType: 'datetime-local'
}

export default {
  name: 'ChristmasS',

  mixins: [ChristmasMixin, AuthFromAdminPanel],

  directives: { money: VMoney },

  components: {
    ChristmasSWidget,
    ChristmasSWidgetRight,
    Preloader
  },

  data: () => ({
    form: {
      accountId: '394558311',
      requestNumber: '386454',
      requesterName: 'Vladimir Kosogov',
      createdDate: moment().format(DATE_TIME),
      lastActivityDate: moment().format(DATE_TIME),
      supportName: 'Thomas Acton',
      status: 'Open',
      priority: 'High',
      bkName: '',
      serviceOrProduct: 'Bets-Withdrawals',
      ticketType: 'Bets-Withdrawals > Withdrawal issues',
      bkLink: '',
      comissionAmount: 0,

      messages: [JSON.parse(JSON.stringify(MESSAGE_ITEM))]
    },

    formStatuses: [
      { value: 'Open', text: 'Открытая заявка' },
      { value: 'Success', text: 'Решено' },
      { value: 'Failed', text: 'Отклонено' }
    ],

    priorityOptions: [
      { value: 'High', text: 'Высокая' },
      { value: 'Medium', text: 'Средняя' },
      { value: 'Low', text: 'Низкая' }
    ],

    dateTypes: [
      { value: 'time', text: 'Только время' },
      { value: 'datetime-local', text: 'Дата и время' }
    ]
  }),

  computed: {
    ...mapGetters(['site']),

    formatCreatedDate() {
      return moment(this.form.createdDate).format(MONTH_DAY_YEAR_TIME)
    },

    formatLastActivityDate() {
      return moment(this.form.lastActivityDate).format(MONTH_DAY_YEAR_TIME)
    },

    formatMessages() {
      return this.form.messages.map(message => {
        const format = message.dateType == 'time' ? 'HH:mm' : MONTH_DAY_TIME
        const time = moment(message.time).format(format)

        const newMessage = {
          isSupport: message.isSupport,
          time,
          text: message.text
        }

        if (message.isSupport) {
          newMessage.isBottomMessage = message.isBottomMessage
        }

        return newMessage
      })
    }
  },

  watch: {
    client(client) {
      this.form.requesterName = client.name
      this.form.bkName = `${this.site.vars.name.begin}${this.site.vars.name.end}`
      this.form.bkLink = document.domain
    }
  },

  // watch: {
  //   form: {
  //     handler: function(val) {
  //       const name = `ChristmasS-${this.form.requesterName}`
  //       localStorage[name] = JSON.stringify(val)

  //       if (val.messages.length) {
  //         this.form.createdDate = moment(val.messages[0].time).format(DATE_TIME)

  //         this.form.lastActivityDate = moment(val.messages.at(-1).time).format(
  //           DATE_TIME
  //         )
  //       }
  //     },
  //     deep: true
  //   }
  // },

  // async mounted() {
  //   this.isClientLoading = true

  //   setTimeout(() => {
  //     let requesterName = this.form.requesterName

  //     if (this.client) {
  //       requesterName = this.client.name
  //     }

  //     const name = `ChristmasS-${requesterName}`

  //     if (name in localStorage) {
  //       const data = JSON.parse(localStorage.getItem(name))
  //       Object.assign(this.form, data)
  //     } else {
  //       this.form.requesterName = requesterName
  //       this.form.bkName = `${this.site.vars.name.begin}${this.site.vars.name.end}`
  //       this.form.bkLink = document.domain
  //     }

  //     this.isClientLoading = false
  //   }, 1000)
  // },

  methods: {
    addMessageItem() {
      let messageItem = JSON.parse(JSON.stringify(MESSAGE_ITEM))

      this.form.messages.push({
        ...messageItem,
        isSupport: this.form.messages.length
          ? !this.form.messages.at(-1).isSupport
          : true
      })
    },

    removeMessageItem(index) {
      this.form.messages.splice(index, 1)
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  mask-image: url('../../../assets/support/close.svg');
  background-color: #6c6c6c;
  cursor: pointer;

  &:hover {
    background-color: #ca8f8f;
  }

  &:active {
    background-color: #0d0d0d;
  }
}

.new-message-btn {
  position: relative;
  background-color: white;
  border-radius: 24px;
  margin: 10px auto;
  width: 25%;
  color: black;
  text-align: center;
  cursor: pointer;
}
.up-btn {
  position: absolute;
  top: -10px;
  right: -420px;
  background: url('../../../assets/support/up.png') no-repeat;
  background-size: cover;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
</style>
