<template>
  <div id="slider" class="slider" v-if="site.vars.promo.length == 1">
    <div
      class="header-promo"
      :style="{
        backgroundImage: backgroundImage(
          site.vars.promo[0].image,
          site.vars.promo[0].blackout
        ),
        color: site.vars.promo[0].color + '!important'
      }"
      :class="promoPosition(site.vars.promo[0].position || 'left')"
    >
      <div class="header-promo__desc">
        <h1
          class="header-promo__title"
          :style="{ color: site.vars.promo[0].color }"
        >
          {{ site.vars.promo[0].title }}
        </h1>
        <p
          class="header-promo__text"
          :class="promoTextPosition(site.vars.promo[0].position || 'left')"
          :style="{ fontWeight: site.vars.promo[0].fontWeight || 400 }"
        >
          {{ site.vars.promo[0].description }}
        </p>
      </div>
      <div class="header-promo__action">
        <button
          class="auth-btn"
          :style="{
            background: site.vars.promo[0].color,
            color: site.vars.promo[0].btnColor
          }"
        >
          {{ btnName }}
        </button>
      </div>
    </div>
  </div>
  <div id="slider" class="slider" v-else>
    <swiper :options="swiperOption">
      <swiper-slide
        class="header-promo"
        v-for="(promo, index) in site.vars.promo"
        :key="index"
        :style="{
          backgroundImage: backgroundImage(promo.image, promo.blackout),
          color: promo.color
        }"
        :class="promoPosition(promo.position || 'left')"
      >
        <div class="header-promo__desc">
          <h1 class="header-promo__title" :style="{ color: promo.color }">
            {{ promo.title }}
          </h1>
          <p
            class="header-promo__text"
            :class="promoTextPosition(promo.position || 'left')"
            :style="{ fontWeight: promo.fontWeight || 400 }"
          >
            {{ promo.description }}
          </p>
        </div>
        <div class="header-promo__action">
          <button
            class="auth-btn"
            :style="{ background: promo.color, color: promo.btnColor }"
          >
            {{ btnName }}
          </button>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev">
        <svg x="0px" y="0px" viewBox="0 0 370.814 370.814">
          <polygon
            fill="#fff"
            points="292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401   "
          />
        </svg>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <svg x="0px" y="0px" viewBox="0 0 478.448 478.448">
          <polygon
            fill="#fff"
            points="131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448     377.954,239.232   "
          />
        </svg>
      </div>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'swiper/dist/css/swiper.css'

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'HeaderPromo',

  components: {
    swiper,
    swiperSlide
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),

  computed: {
    ...mapGetters(['site', 'isAuthenticated']),

    btnName() {
      return this.isAuthenticated ? 'MY ACCOUNT' : 'LOG IN'
    }
  },

  mounted() {
    const btns = document.querySelectorAll('.auth-btn')
    btns.forEach(btn => {
      btn.addEventListener('click', this.auth, false)
    })
  },

  methods: {
    auth() {
      if (this.isAuthenticated) this.$router.push('/personal-account')
      else this.$store.commit('showAuthModal', 'hidden')
    },

    promoPosition(position) {
      return `${position}-promo-position`
    },

    promoTextPosition(position) {
      return `${position}-promo-text-position`
    },

    backgroundImage(imageUrl, blackout) {
      if (imageUrl.indexOf('/v1/media') === 0) {
        return blackout
          ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
              '/api' +
              imageUrl.replace('/v1', '') +
              ')'
          : 'url(' + '/api' + imageUrl.replace('/v1', '') + ')'
      } else {
        return blackout
          ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
              imageUrl +
              ')'
          : 'url(' + imageUrl + ')'
      }
    }
  }
}
</script>

<style>
.swiper-container {
  z-index: 0;
}

.header-promo {
  position: relative;
  padding: 0 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease;
  background-size: cover;
}

.header-promo__title {
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
}

.header-promo__text {
  width: 50%;
  line-height: 2;
}

.header-promo__action button {
  border: 0;
  color: #fff;
  background: none;
  width: 150px;
  height: 30px;
  text-transform: uppercase;
  border-radius: 15px;
  cursor: pointer;
  display: block;
  margin: 0;
}

.header-promo__action .auth-btn {
  width: 160px;
  color: #000;
  background: #fff;
  height: 30px;
  border-radius: 25px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.header-promo__action .auth-btn:hover {
  filter: brightness(85%);
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
  width: 38px;
  height: 38px;
  margin-top: -19px;
}

.left-promo-position {
  align-items: flex-start;
  text-align: left;
}

.center-promo-position {
  align-items: center;
  text-align: center;
}

.right-promo-position {
  align-items: flex-end;
  text-align: right;
}

.left-promo-text-position {
  margin: 10px 0 80px;
}

.center-promo-text-position {
  margin: 10px auto 80px;
}

.right-promo-text-position {
  margin: 10px 0 80px auto;
}

@media screen and (max-width: 992px) {
  .header-promo {
    background-position: center center;
    background-size: cover;
    padding: 0 50px;
  }

  .left-promo-position,
  .right-promo-position {
    align-items: center;
    text-align: center;
  }

  .header-promo__desc {
    order: 2;
    margin: 100px 0 100px;
  }

  .header-promo__text {
    width: 100%;
    margin: 10px 0 0;
    line-height: 2;
  }

  .header-promo__action {
    text-align: center !important;
    order: 1;
    margin-top: auto;
  }

  .header-promo__action button {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .header-promo {
    padding: 0 30px;
  }

  .header-promo__title {
    font-size: 30px;
  }

  .header-promo__text {
    font-size: 13px;
  }
}
</style>
