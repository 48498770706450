import Axios from 'axios'

const state = {
  exchangeRate: null
}

const getters = {
  dollarRate: state => {
    return state.exchangeRate?.Valute?.USD?.Value || 0
  },
  euroRate: state => state.exchangeRate?.Valute?.EUR?.Value || 0
}

const mutations = {
  SET_EXCHANGE_RATE: (state, exchangeRate) =>
    (state.exchangeRate = exchangeRate)
}

const actions = {
  GET_EXCHANGE_RATE: async ({ commit }) => {
    await Axios.get('/currency')
      .then(res => {
        commit('SET_EXCHANGE_RATE', res.data)
      })
      .catch(err => {
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
