<script>
import Axios from 'axios'
import { mapActions } from 'vuex'
import ErrorAuthMessage from '@/components/CommonUi/ErrorAuthMessage'

export default {
  name: 'AuthFromAdminPanel',

  components: {
    ErrorAuthMessage
  },

  data: () => ({
    isClientLoading: false,
    client: null,
    isErrorAuth: false
  }),

  async created() {
    const query = this.$route.query

    const isQueryHaveLoginData =
      query.hasOwnProperty('login') && query.hasOwnProperty('password')

    if (isQueryHaveLoginData) {
      try {
        this.isClientLoading = true

        const { data } = await Axios.post('/api/token', {
          login: query.login,
          password: query.password,
          ignoreAccess: query.ignoreAccess === 'true'
        })

        const token = data.raw

        const client = await Axios.get('/api/client', {
          headers: {
            'X-Token': token
          }
        })

        this.client = client.data
      } catch (error) {
        console.error('Auth problems: ', error)
        this.isErrorAuth = true
      } finally {
        this.isClientLoading = false
      }
    }
  },

  methods: {
    ...mapActions({
      authRequest: 'AUTH_REQUEST'
    })
  }
}
</script>
