const state = {
  bets: [],
  stake: 500
}

const getters = {
  possibleWinnings(state, getters) {
    if (state.bets.length == 0) {
      return '-.--'
    }

    const summaryOdd = getters.totalOdds
    const possibleWinnings = summaryOdd * state.stake

    return possibleWinnings.toFixed(2)
  },

  totalOdds(state) {
    let summaryOdds = 1

    for (let i = 0; i < state.bets.length; i++) {
      summaryOdds *= state.bets[i].odd
    }

    return summaryOdds.toFixed(2)
  },

  selectedBetsCounter: state => state.bets.length,

  bets: state => state.bets,

  stake: state => state.stake
}

const mutations = {
  addBetToCoupon(state, bet) {
    const inputBet = bet

    const oddIndex = state.bets.findIndex(bet => bet.id == inputBet.id)
    const eventIndex = state.bets.findIndex(
      bet => bet.eventId == inputBet.eventId
    )

    if (oddIndex > -1) {
      state.bets.splice(oddIndex, 1)
    } else {
      if (eventIndex > -1) {
        state.bets.splice(eventIndex, 1)
      }
      state.bets.push(inputBet)
    }
  },

  removeOdd(state, id) {
    const index = state.bets.findIndex(bet => bet.id == id)
    state.bets.splice(index, 1)
  },

  updateStake: (state, val) => (state.stake = val)
}

export default {
  state,
  getters,
  mutations
}
