<template>
  <div class="coupon__type">{{ betType }}</div>
</template>

<script>
export default {
  name: 'BetType',

  props: {
    type: String
  },

  computed: {
    betType() {
      return this.type == 'ordinary' ? 'Single' : 'Multi'
    }
  }
}
</script>
