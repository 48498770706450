<template>
  <the-modal title="Contact Us">
    <template v-slot:content>
      <div class="contacts-modal">
        <div class="contacts">
          <div class="column">
            <div class="mail">
              <div class="mail-icon">
                <svg viewBox="0 0 67.55 47.77">
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      x1="0.04"
                      y1="11.93"
                      x2="67.51"
                      y2="11.93"
                    >
                      <stop stop-color="#1d262d" offset="0" />
                      <stop stop-color="#11121c" offset="1" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-2"
                      x1="0"
                      y1="27.38"
                      x2="67.55"
                      y2="27.38"
                      xlink:href="#linear-gradient"
                    />
                  </defs>
                  <polygon
                    class="cls-1"
                    points="67.51,0 0.04,0 33.78,23.86 67.51,0"
                  />
                  <polygon
                    class="cls-2"
                    points="33.78,30.88 0,6.99 0,47.77 67.55,47.77 67.55,6.99 33.78,30.88"
                  />
                </svg>
              </div>
              <div class="mail-text">Support</div>
            </div>
            <div class="mail-adress" style="margin-bottom: 14px;">
              <a :href="'mailto:' + site.contacts.supportEmail">{{
                site.contacts.supportEmail
              }}</a>
            </div>
            <div class="mail">
              <div class="mail-icon">
                <svg viewBox="0 0 67.55 47.77">
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      x1="0.04"
                      y1="11.93"
                      x2="67.51"
                      y2="11.93"
                    >
                      <stop stop-color="#1d262d" offset="0" />
                      <stop stop-color="#11121c" offset="1" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-2"
                      x1="0"
                      y1="27.38"
                      x2="67.55"
                      y2="27.38"
                      xlink:href="#linear-gradient"
                    />
                  </defs>
                  <polygon
                    class="cls-1"
                    points="67.51,0 0.04,0 33.78,23.86 67.51,0"
                  />
                  <polygon
                    class="cls-2"
                    points="33.78,30.88 0,6.99 0,47.77 67.55,47.77 67.55,6.99 33.78,30.88"
                  />
                </svg>
              </div>
              <div class="mail-text">Registration</div>
            </div>
            <div class="mail-adress">
              <a :href="'mailto:' + site.contacts.joinEmail">{{
                site.contacts.joinEmail
              }}</a>
            </div>
          </div>

          <div class="column">
            <div class="phone">
              <div class="phone-icon">
                <svg x="0px" y="0px" viewBox="0 0 512 512">
                  <path
                    fill="#1d262d"
                    d="M436.992,74.953c-99.989-99.959-262.08-99.935-362.039,0.055s-99.935,262.08,0.055,362.039s262.08,99.935,362.039-0.055    c48.006-48.021,74.968-113.146,74.953-181.047C511.986,188.055,485.005,122.951,436.992,74.953z M387.703,356.605    c-0.011,0.011-0.022,0.023-0.034,0.034v-0.085l-12.971,12.885c-16.775,16.987-41.206,23.976-64.427,18.432    c-23.395-6.262-45.635-16.23-65.877-29.525c-18.806-12.019-36.234-26.069-51.968-41.899    c-14.477-14.371-27.483-30.151-38.827-47.104c-12.408-18.242-22.229-38.114-29.184-59.051    c-7.973-24.596-1.366-51.585,17.067-69.717l15.189-15.189c4.223-4.242,11.085-4.257,15.326-0.034    c0.011,0.011,0.023,0.022,0.034,0.034l47.957,47.957c4.242,4.223,4.257,11.085,0.034,15.326c-0.011,0.011-0.022,0.022-0.034,0.034    l-28.16,28.16c-8.08,7.992-9.096,20.692-2.389,29.867c10.185,13.978,21.456,27.131,33.707,39.339    c13.659,13.718,28.508,26.197,44.373,37.291c9.167,6.394,21.595,5.316,29.525-2.56l27.221-27.648    c4.223-4.242,11.085-4.257,15.326-0.034c0.011,0.011,0.022,0.022,0.034,0.034l48.043,48.128    C391.911,345.502,391.926,352.363,387.703,356.605z"
                  />
                </svg>
              </div>
              <div class="phone-text">Callback</div>
            </div>

            <div class="phone-number">
              <form @submit.prevent="submit">
                <input
                  type="text"
                  v-model="phone"
                  v-mask="'(###)###-####'"
                  placeholder="(111)111-1111"
                  required
                />
                <input
                  class="phone-submit"
                  value="sumbit"
                  type="submit"
                  @click="onSubmitClick"
                />
              </form>
            </div>
            <div class="address">
              <div class="address-icon">
                <svg x="0px" y="0px" viewBox="0 0 395.71 395.71">
                  <path
                    fill="#1d262d"
                    d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                  />
                </svg>
              </div>
              <div class="address-text">Address</div>
            </div>
            <div class="address-desc">
              <a>{{ site.vars.location.address }}</a>
            </div>
          </div>
        </div>
        <div class="map">
          <iframe
            :src="site.vars.location.mapSrc"
            width="100%"
            height="200px"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </template>
  </the-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheModal from './TheModal'
import mask from 'v-mask'
import Vue from 'vue'

Vue.use(mask)

export default {
  name: 'ContactUs',

  components: {
    TheModal
  },

  data: () => ({
    phone: ''
  }),

  computed: {
    ...mapGetters(['site'])
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
      $_close: 'close'
    }),

    openCallbackErrorModal() {
      this.$_showModal({ name: 'ErrorCallback' })
    },

    closeContactsModal() {
      this.$_close({ name: 'ContactUs' })
    },

    clearData() {
      this.phone = ''
    },

    onSubmitClick() {
      this.closeContactsModal()
      this.openCallbackErrorModal()
      this.clearData()
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.contact-us {
  width: 700px;
  margin: auto;
  max-width: 100%;
  background-color: #fff;
  background-position: 750px;
  position: relative;
  min-height: 450px;
}

.contact-us .modal-body {
  position: relative;
  padding: 10px;
  min-height: 425px;
  overflow: hidden;
}

.contacts .column {
  border: 0 !important;
  margin: 0;
  width: 100%;
  padding: 0 10px;
}

.map {
  padding: 15px;
  min-height: 200px;
}

.contacts {
  display: flex;
}

.contact-us .title,
.error-license .title,
.balance-blocked .title,
.user-blocked .title {
  font-size: 20px;
  text-align: center;
  color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(#1e262e, #11121d);
}

.mail,
.phone,
.address {
  display: flex;
  align-items: center;
  height: 48px;
  min-width: 200px;
  margin-top: 10px;
}

.mail-icon svg {
  width: 60px;
}

.phone-icon svg {
  width: 50px;
}

.address-icon svg {
  width: 45px;
}

.mail-text,
.phone-text,
.address-text {
  padding: 7px 15px;
  height: 42px;
  font-size: 20px;
  font-weight: 700;
  width: 70%;
  position: relative;
}

.mail-adress a,
.phone-number a,
.address-desc a {
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
}

.mail-adress a:hover,
.phone-number a:hover,
.address-desc a:hover {
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid rgba(0, 0, 0, 0.65);
}

.phone-number input {
  height: 26px;
  padding: 0 10px;
  border: 1px solid #afafaf;
  width: 70%;
  color: #000;
}

.phone-submit {
  height: 26px;
  width: 30% !important;
  border: 1px solid #afafaf;
  border-left: 0 !important;
  background: #fff;
  cursor: pointer;
}

.phone-submit:hover {
  background: #afafaf;
}

.cls-1,
.cls-2 {
  fill-rule: evenodd;
}
.cls-1 {
  fill: url(#linear-gradient);
}
.cls-2 {
  fill: url(#linear-gradient-2);
}

.contacts-modal {
  background: #fff;
  max-width: 700px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .contacts {
    display: block;
  }
}
</style>
