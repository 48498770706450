<template>
  <error-auth-message v-if="isErrorAuth" />
  <preloader v-else-if="exchangeRateLoading || isClientLoading" />
  <div v-else id="page-wrapper" style="padding-top: 75px;">
    <div id="main-wrap">
      <div id="main">
        <div class="left" style="position: relative; top: 0;">
          <balance :balance="form.balance" />
          <left-menu :activeTab="'Withdrawal'" />
        </div>
        <div class="pa-content-section" style="min-height: calc(100vh - 95px)">
          <christmas-c-widget
            v-if="form.newDesign"
            :walletAddress="form.walletAddress"
            :payment="form.payment"
            :redAlert="form.redAlert"
            :paymentSystem="form.selectedPaymentSystem"
            :commentLabel="form.commentLabel"
            :isComment="form.isComment"
          />
          <christmas-c-widget-old-design
            v-else
            :walletAddress="form.walletAddress"
            :payment="form.payment"
            :whiteAlert="form.whiteAlert"
            :paymentSystem="form.selectedPaymentSystem"
            :walletType="walletType"
            :commentLabel="form.commentLabel"
            :isComment="form.isComment"
            :addingMessage="form.addingMessage"
            :isBtns="form.isBtns"
            :statusText="form.statusText"
          />
        </div>
      </div>
      <div class="commission-form">
        <div class="form__row">
          <div class="form__group" v-if="!form.balance.single">
            <div class="form__label">Заблокированый баланс</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.balance.blocked.amount"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">{{ balanceText }}</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.balance.active.amount"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group" style="width: 70%; padding-right: 0;">
            <div class="form__label">Сумма пополнения</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.payment.total.amount"
              required
            />
          </div>
          <div class="form__group" style="width: 30%; padding-left: 0;">
            <div class="form__label">Валюта</div>
            <select
              class="form__input"
              v-model="form.payment.total.currency"
              required
            >
              <option
                v-for="(option, index) in currencyList"
                :key="index"
                :value="option"
                >{{ option }}</option
              >
            </select>
          </div>
          <div class="form__group">
            <div class="form__label">Процент комиссии (0 - 100)</div>
            <input
              class="form__input"
              type="number"
              v-model.number="form.balance.fee"
              required
            />
          </div>
        </div>

        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Платёжная система</div>
            <select
              class="form__input"
              v-model="form.selectedPaymentSystem"
              required
            >
              <option
                v-for="(option, index) in paymentSystemList"
                :key="index"
                :value="option"
                >{{ option.text }}</option
              >
            </select>
          </div>
          <div class="form__group">
            <div class="form__label">Кошелёк для принятия средств</div>
            <input
              v-if="isWalletAddressCard"
              class="form__input"
              type="text"
              v-model="form.walletAddress"
              required
              v-mask="'#### #### #### ####'"
            />
            <input
              v-else
              class="form__input"
              type="text"
              v-model="form.walletAddress"
              required
            />
          </div>
        </div>

        <div
          v-for="(item, index) in form.payment.withdrawalList"
          :key="index"
          style="position: relative"
          class="form__row form__row-payment"
        >
          <div class="form__group">
            <div class="form__label">Платёжнеая система клиента</div>

            <div class="select-editable">
              <select
                class="form__input"
                v-model="item.gateway"
                onchange="this.nextElementSibling.value=this.value"
                required
              >
                <option
                  v-for="(option, index) in clientPaymentSystemList"
                  :key="index"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <input
                class="form__input"
                type="text"
                name="format"
                v-model="item.gateway"
              />
            </div>
          </div>

          <div class="form__group">
            <div class="form__label">Номер кошелька клиента</div>
            <input
              v-if="cardPayments.includes(item.gateway)"
              class="form__input"
              type="text"
              v-model="item.account"
              required
              v-mask="'#### #### #### ####'"
            />
            <input
              v-else
              class="form__input"
              type="text"
              v-model="item.account"
              required
            />
          </div>
          <button
            v-if="isWithdrawalListLeghtUpperOne"
            class="btn-detele"
            @click="removePaymentSystem"
          >
            X
          </button>
        </div>

        <div class="form__row form__row-btn">
          <button class="btn-add" @click="addPaymentSystem">
            Добавить платёжную систему
          </button>
        </div>

        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Заглавие поля с комментом</div>
            <input
              class="form__input"
              type="text"
              v-model="form.commentLabel"
              :disabled="!form.isComment"
              required
            />
          </div>
          <div class="form__group">
            <div class="form__label">Комментарий к платежу</div>
            <input
              class="form__input"
              type="text"
              v-model="form.payment.comment"
              :disabled="!form.isComment"
              required
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__group">
            <div class="form__label">Статус платежа</div>
            <select
              class="form__input"
              v-model="form.payment.status.name"
              required
            >
              <option
                v-for="(option, index) in paymentStatusList"
                :key="index"
                :value="option.value"
                >{{ option.text }}</option
              >
            </select>
          </div>
          <div class="form__group" v-if="!form.newDesign">
            <div class="form__label">Текст статуса</div>
            <input
              class="form__input"
              type="text"
              v-model="form.statusText"
              required
            />
          </div>
        </div>

        <div class="form__row" style="margin-bottom: 20px;">
          <div class="form__group">
            <div class="form__label">Тип отплаты</div>

            <div class="select-editable">
              <select
                class="form__input"
                v-model="form.payment.type"
                onchange="this.nextElementSibling.value=this.value"
                required
              >
                <option
                  v-for="(option, index) in paymentTypeList"
                  :key="index"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>

              <input
                class="form__input"
                type="text"
                name="format"
                v-model="form.payment.type"
              />
            </div>
          </div>
        </div>

        <div class="form__row" v-if="!form.newDesign">
          <div class="form__group">
            <div class="form__label">
              Дополнительное сообщение с предупреждением
            </div>
            <input
              class="form__input"
              type="text"
              v-model="form.addingMessage"
              :disabled="!form.isBtns"
              required
            />
          </div>
        </div>

        <div class="form__row">
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.isComment"
              style="width: auto;"
              required
            />
            <div class="form__label">Включить комментарий</div>
          </div>
          <div class="form__group checkbox" v-if="form.newDesign">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.redAlert"
              style="width: auto;"
              required
            />
            <div class="form__label">Предупреждение красным цветом</div>
          </div>
          <div class="form__group checkbox" v-else>
            <input
              class="form__input"
              type="checkbox"
              v-model="form.whiteAlert"
              style="width: auto;"
              required
            />
            <div class="form__label">Предупреждение белым цветом</div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.newDesign"
              style="width: auto;"
              required
            />
            <div class="form__label">Новый дизайн</div>
          </div>
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.balance.single"
              style="width: auto;"
              required
            />
            <div class="form__label">Единный баланс</div>
          </div>
        </div>
        <div class="form__row" v-if="!form.newDesign">
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.isAddress"
              style="width: auto;"
              required
            />
            <div class="form__label">Address/Username</div>
          </div>
          <div class="form__group checkbox">
            <input
              class="form__input"
              type="checkbox"
              v-model="form.isBtns"
              style="width: auto;"
              required
            />
            <div class="form__label">Отображать кнопки Cancel&Confirm</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '../../Line/preloader'
import ChristmasCWidget from './ChristmasCWidget'
import ChristmasCWidgetOldDesign from './ChristmasCWidgetOldDesign'
import ChristmasMixin from '../../mixins/ChristmasMixin.vue'
import ChristmasCDMixin from '../../mixins/ChristmasCDMixin.vue'
import { mapActions, mapGetters } from 'vuex'

import { CARD_PAYMENTS } from '../../../constatns/CristmasConstants'

const WITHDRAWAL_LIST_ITEM = { account: '0', gateway: 'QIWI wallet' }

export default {
  name: 'ChristasC',

  components: {
    Preloader,
    ChristmasCWidget,
    ChristmasCWidgetOldDesign
  },

  mixins: [ChristmasMixin, ChristmasCDMixin],

  data: () => ({
    form: {
      isBtns: false,
      addingMessage:
        'Confirm the operation ONLY AFTER transferring of funds to the specified details'
    },

    exchangeRateLoading: false,
    cardPayments: CARD_PAYMENTS,

    clientPaymentSystemList: [
      { value: 'QIWI wallet', text: 'QIWI wallet' },
      { value: 'MasterCard wallet', text: 'MasterCard wallet' },
      { value: 'Visa wallet', text: 'Visa wallet' },
      { value: 'Bitcoin wallet', text: 'Bitcoin wallet' },
      { value: 'Litecoin wallet', text: 'Litecoin wallet' },
      { value: 'Mir wallet', text: 'Mir wallet' },
      { value: 'Perfect money', text: 'Perfect money' },
      { value: 'USDT (TRC-20) wallet', text: 'USDT (TRC-20) wallet' },
      { value: 'USDT (ERC-20) wallet', text: 'USDT (ERC-20) wallet' },
      { value: 'TON wallet', text: 'TON' },
      { value: 'XLM wallet', text: 'Stellar' },
      { value: 'Monero wallet', text: 'Monero' },
      { value: 'Solana wallet', text: 'Solana' }
    ]
  }),

  computed: {
    ...mapGetters(['dollarRate', 'euroRate']),

    ...mapGetters({
      balance: 'BALANCE'
    }),

    paymentTypeList() {
      return ['Tax Payment', 'Verification Payment', 'Refund Payment']
    },

    totalAmountInRub() {
      const currency = this.form.balance.single
        ? this.form.balance.active.currency
        : this.form.balance.blocked.currency

      const blockedAmount = this.form.balance.single
        ? this.form.balance.active.amount
        : this.form.balance.blocked.amount

      const fee = this.form.balance.fee

      let totalAmount

      if (!this.form.balance.exchangeRate) {
        const dollarRate = this.dollarRate
        const euroRate = this.euroRate

        if (currency == 'USD') {
          totalAmount = blockedAmount * dollarRate * (fee / 100)
        } else if (currency == 'EUR') {
          totalAmount = blockedAmount * euroRate * (fee / 100)
        } else {
          totalAmount = blockedAmount * (fee / 100)
        }
      } else {
        totalAmount =
          blockedAmount * this.form.balance.exchangeRate * (fee / 100)
      }
      return totalAmount
    },

    isWithdrawalListLeghtUpperOne() {
      return this.form.payment.withdrawalList.length > 1
    }
  },

  watch: {
    'form.balance.fee'() {
      this.amountСalculation()
    },
    'form.balance.blocked.amount'() {
      this.amountСalculation()
    },
    'form.balance.active.amount'() {
      if (this.form.balance.single) {
        this.amountСalculation()
      }
    },
    'form.balance.single'() {
      this.amountСalculation()
    }
  },

  async mounted() {
    try {
      this.exchangeRateLoading = true

      if (!this.form.balance.exchangeRate) {
        await this.getExchangeRate()
      }

      this.amountСalculation()
    } catch (e) {
      console.log(e)
    } finally {
      this.exchangeRateLoading = false
    }
  },

  methods: {
    ...mapActions({
      getExchangeRate: 'GET_EXCHANGE_RATE'
    }),

    amountСalculation() {
      let totalCurrency = this.form.payment.total.currency

      if (totalCurrency == 'RUB') {
        this.form.payment.total.amount = parseFloat(
          this.totalAmountInRub
        ).toFixed(2)
      }
    },

    addPaymentSystem() {
      const paymentSystemItem = JSON.parse(JSON.stringify(WITHDRAWAL_LIST_ITEM))

      this.form.payment.withdrawalList.push(paymentSystemItem)
    },

    removePaymentSystem(index) {
      this.form.payment.withdrawalList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.payment {
  padding: 10px 20px;

  &__btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
  }

  &__btn {
    width: 100px;
    color: #fff;
    height: 100%;
    border-radius: 25px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;

    &_cancel {
      background-color: gray;
    }

    &_confirm {
      background-color: green;
    }
  }
}

.payment .payment__message {
  text-align: center;
  font-size: 16px;
  padding: 0 30px;
  font-weight: 700;
  width: 100%;
}

.payment .payments__logo {
  width: 35%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment__details {
  width: 55%;
}

.payment-column {
  display: flex;
  align-items: center;
}

.payment .payment__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.payment .payment__icon {
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  text-align: left;
  color: #fff;
  font-size: 14px;
}

.info .info__title {
  min-width: 40%;
  font-weight: 700;
}

.info .info__content {
  text-align: right;
  min-width: 60%;
  word-break: break-all;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.commission-form {
  width: 1000px;
  margin: auto;
  color: #fff;
}

.checkbox {
  display: flex;
  align-items: center;
}

.check-btn {
  margin: auto;
  text-align: center;
  height: 35px;
  font-size: 13px;
  background-color: #076d7d;
  border: none;
  outline: none;
  color: #fff;
}

.payment__status {
  height: 87px;
  display: flex;
  width: 10%;
}

.form__row-payment:not(:last-child) {
  margin-bottom: 12px;
  border: 1px solid rgb(164, 162, 162);
}

.form__row-btn {
  justify-content: center;
  margin-top: 12px;
}

.btn-add {
  color: #000;
  background: rgb(255, 255, 255);
  height: 100%;
  border-radius: 25px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.btn-detele {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 20px;
  height: 20px;
  background: rgb(229, 95, 95);
  text-align: center;
  cursor: pointer;
}

.select-editable {
  position: relative;

  select {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 95%;
  }
}
</style>
