<template>
  <div class="pa-menu">
    <button
      class="pa-menu__item"
      :class="{ active: item.value === activeTab }"
      v-for="(item, index) in menuItems"
      :key="index"
    >
      {{ item.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SecretLeftMenu',

  props: {
    activeTab: String
  },

  data: () => ({
    menuItems: [
      {
        text: 'Live Events',
        value: 'Live'
      },
      {
        text: 'Upcoming Events',
        value: 'Upcoming'
      },
      {
        text: 'Deposit',
        value: 'Deposit'
      },
      {
        text: 'Withdrawal',
        value: 'Withdrawal'
      },
      {
        text: 'My profile',
        value: 'Profile'
      },
      {
        text: 'Change password',
        value: 'ChangePassword'
      },
      {
        text: 'Settings',
        value: 'Settings'
      },
      {
        text: 'Transaction History',
        value: 'Transactions'
      },
      {
        text: 'Bet History',
        value: 'Bets'
      },
      {
        text: 'Bonus',
        value: 'Bonus'
      },
      {
        text: 'Promo Code',
        value: 'PromoCode'
      },
      {
        text: 'Casino',
        value: 'Casino'
      },
      {
        text: 'Support',
        value: 'Support'
      }
    ]
  }),

  methods: {
    setActiveTab(item) {
      this.$store.dispatch('GET_TOKEN').catch(() => {
        this.$router.push('/')
      })

      this.$store.commit('setActiveTab', item)
      if (item == 'Upcoming') this.$store.commit('eventsSelection', 'upcoming')
      if (item == 'Live') this.$store.commit('eventsSelection', 'inPlay')
      window.scrollTo(0, 0)
    }
  }
}
</script>
