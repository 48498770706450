<template>
  <div class="casino__filters">
    <div class="casino__filters-title">
      <div>Filters</div>
      <div class="menu-icon" @click="addActiveClass">
        <img src="../../assets/menu-icon.svg" alt="menu-icon" width="25" />
      </div>
    </div>
    <div class="filters" :class="{ 'active-filters': activeFilters }">
      <div
        class="casino__filter"
        v-for="(filter, index) in filters"
        :key="index"
        :class="{ 'active-filter': filter.value === casinoFilter }"
        @click="setCasinoFilter(filter.value)"
      >
        {{ filter.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CasinoFilters',

  data: () => ({
    activeFilters: false,
    filters: [
      {
        text: 'Top Games',
        value: 'top'
      },
      {
        text: 'New Games',
        value: 'new'
      },
      {
        text: 'Slots',
        value: 'slots'
      },
      {
        text: 'Jackpots',
        value: 'jackpots'
      },
      {
        text: '3D Slots',
        value: '3dslots'
      },
      {
        text: 'Table games',
        value: 'table'
      },
      {
        text: 'Video poker',
        value: 'videopoker'
      },
      {
        text: 'Other',
        value: 'other'
      }
    ]
  }),

  computed: {
    ...mapGetters(['casinoFilter'])
  },

  methods: {
    ...mapMutations(['setCasinoFilter']),

    addActiveClass() {
      this.activeFilters = !this.activeFilters
    }
  }
}
</script>

<style>
.casino__filters {
  width: 25%;
  background: #29292b;
  font-size: 14px;
  font-weight: 600;
  color: #868687;
  padding: 10px 20px;
}

.casino__filter {
  padding: 10px 0;
  cursor: pointer;
}

.casino__filter:hover {
  color: #fff;
}

.active-filter {
  color: #fff;
}

.casino__filters-title {
  font-weight: 400;
  width: 100%;
  padding: 5px 0;
  color: #585858;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.casino__filters-title .menu-icon {
  display: none;
  height: 25px;
}

.casino__filters-title .menu-icon img {
  filter: invert(33%) sepia(6%) saturate(21%) hue-rotate(52deg) brightness(101%)
    contrast(93%);
  cursor: pointer;
}

.casino__filters-title .menu-icon img:hover {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 768px) {
  .casino__filters {
    width: 100%;
    padding: 5px 20px;
  }

  .casino__filter {
    text-align: center;
  }

  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .casino__filter {
    display: none;
    width: 50%;
    text-align: center;
  }

  .casino__filters-title .menu-icon {
    display: block;
  }

  .active-filters .casino__filter {
    display: block !important;
  }
}
</style>
