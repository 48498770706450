<template>
  <div class="error">
    При получении данных клиента произошла ошибка
  </div>
</template>

<script>
export default {
  name: 'ErrorAuthMessage'
}
</script>

<style scoped>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-size: 32px;
  font-weight: 700;
}
</style>
