<template>
  <div id="nav">
    <div class="header-logo">
      <a class="logo" @click="backToMainPage">
        <img
          v-if="site.vars.logoUrl"
          class="logo__img"
          :src="logoImg"
          alt="Logo"
        /><span style="font-weight: 700;">{{ site.vars.name.begin }}</span
        >{{ site.vars.name.end }}
      </a>
    </div>
    <div class="header-bar" ref="navMenu" :style="{ display: menuDisplay }">
      <div class="header-link-list">
        <div class="header-link-item" v-if="isHomePage">
          <a @click="scrollTo('slider')">
            <span class="header-link-icon">
              <img src="../assets/nav-item-icons/home.svg" alt="home-icon" />
            </span>
            <span>HOME</span>
          </a>
        </div>
        <div class="header-link-item" v-if="isHomePage">
          <a @click="scrollTo('advantages')">
            <span class="header-link-icon">
              <img
                src="../assets/nav-item-icons/about-us.svg"
                alt="about-us-icon"
              />
            </span>
            <span>ABOUT US</span>
          </a>
        </div>
        <div class="header-link-item">
          <a @click="scrollTo('footer')">
            <span class="header-link-icon">
              <img
                src="../assets/nav-item-icons/legal-info.svg"
                alt="legal-info-icon"
              />
            </span>
            <span>LEGAL INFO</span>
          </a>
        </div>
        <div class="header-link-item" v-if="isCasino">
          <router-link to="/casino">
            <span class="header-link-icon">
              <img
                src="../assets/nav-item-icons/casino.svg"
                alt="casino-icon"
              />
            </span>
            <span>CASINO</span>
          </router-link>
        </div>
        <div
          class="personal-account-menu"
          v-if="isPersonalAccount && dropdownMenu"
        >
          <div
            class="header-link-item"
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <a @click="setActiveTab(item.value)">
              <span class="header-link-icon"> </span>
              <span style="text-transform: uppercase">{{ item.text }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="user-state"
      ref="userState"
      :style="{ display: userStateDisplay }"
    >
      <template v-if="isAuthButtons">
        <div class="login-btn" @click="auth">
          Log in
        </div>
        <div class="registration-btn" @click="join">
          Join
        </div></template
      >
      <div v-else class="logout-btn" @click="logout">Logout</div>
    </div>
    <a class="header-icon" @click="openMenu()">
      <img src="../assets/nav-button.svg" alt="nav-button" />
    </a>
    <resizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import 'vue-resize/dist/vue-resize.css'
import { ResizeObserver } from 'vue-resize'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  components: {
    ResizeObserver
  },

  data: () => ({
    dropdownMenu: false,
    windowWidth: 0,
    menuItems: [
      {
        text: 'Live Events',
        value: 'Live'
      },
      {
        text: 'Upcoming Events',
        value: 'Upcoming'
      },
      {
        text: 'My profile',
        value: 'Profile'
      },
      {
        text: 'Transaction History',
        value: 'Transactions'
      },
      {
        text: 'Bet History',
        value: 'Bets'
      }
    ]
  }),

  computed: {
    ...mapGetters(['site', 'isAuthenticated', 'isCasino']),

    menuDisplay() {
      if (!this.dropdownMenu && this.windowWidth <= 992) return 'none'
      return 'block'
    },

    userStateDisplay() {
      if (!this.dropdownMenu && this.windowWidth > 992) return 'flex'
      if (this.dropdownMenu && this.windowWidth <= 992) return 'block'
      return 'none'
    },

    isPersonalAccount() {
      return this.$route.path == '/personal-account'
    },

    isSecretPages() {
      return this.$route.path.includes('/casino/christmas')
    },

    isCasinoPage() {
      return this.$route.path == '/casino'
    },

    isAuthButtons() {
      return !this.isAuthenticated && !this.isSecretPages
    },

    isHomePage() {
      return (
        this.$route.path == '/' || this.$route.path == '/cookies-privacy-notice'
      )
    },

    logoImg() {
      const image = this.site.vars.logoUrl

      return image.indexOf('/v1/media') === 0
        ? '/api' + image.replace('/v1', '')
        : image
    }
  },

  mounted() {
    this.windowWidth = window.innerWidth
  },

  methods: {
    setActiveTab(item) {
      this.closeMenu()
      this.$store.commit('setActiveTab', item)
      if (item == 'Upcoming') this.$store.commit('eventsSelection', 'upcoming')
      if (item == 'Live') this.$store.commit('eventsSelection', 'inPlay')
      window.scrollTo(0, 0)
    },

    openMenu() {
      this.dropdownMenu = !this.dropdownMenu
    },

    handleResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth > 992) {
        this.dropdownMenu = false
      }
    },

    closeMenu() {
      this.dropdownMenu = false
    },

    join() {
      this.closeMenu()
      this.$store.commit('showRegistrationFormModal', 'hidden')
    },

    auth() {
      this.closeMenu()
      this.$store.commit('showAuthModal', 'hidden')
    },

    logout() {
      this.closeMenu()
      localStorage.removeItem('token')
      delete Axios.defaults.headers.common['X-Token']
      if (this.$route.path != '/') this.$router.push('/')
      this.$store.commit('CLEAR_TOKEN')
    },

    scrollTo(val) {
      if (this.$route.path === '/cookies-privacy-notice') {
        this.$router.push('/')
      }

      this.closeMenu()
      const elem = document.getElementById(val)

      let yOffset = -65

      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    },

    backToMainPage() {
      if (this.$route.path != '/') this.$router.push('/')
    }
  }
}
</script>

<style>
.white-filter {
  filter: brightness(0) invert(1);
}

#nav {
  background-image: linear-gradient(#1e262e, #11121d);
  padding: 0 70px;
  width: 100%;
  min-height: 65px;
  display: flex;
  align-items: center;
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.4);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

a {
  cursor: pointer;
}

.header-logo {
  font-size: 36px;
}

.logo {
  font-style: italic;
  display: flex;
  align-items: center;
}

.logo__img {
  height: 44px;
  margin-right: 8px;
}

.header-link-list {
  display: flex;
  margin: auto 0 auto 40px;
}

.header-bar .header-link-item a {
  text-align: center;
  font-size: 14px;
  margin: 19px 36px;
  display: flex;
  align-items: center;
}

.header-bar .header-link-item a:hover {
  box-shadow: inset 0 -1px 0 #fff;
}

.user-state {
  margin-left: auto;
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
}

.user-state .registration-btn,
.user-state .logout-btn {
  width: 100px;
  color: #000;
  background: #fff;
  height: 100%;
  border-radius: 25px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.user-state .registration-btn:hover,
.user-state .logout-btn:hover {
  background-color: #d8d8d8;
}

.user-state .login-btn {
  color: #fff;
  font-style: italic;
  cursor: pointer;
  margin-right: 20px;
}

.user-state .login-btn:hover {
  box-shadow: inset 0 -1px 0 #fff;
}

.header-icon {
  display: none;
  width: 36px;
  height: 21px;
  margin-left: auto;
  position: absolute;
  right: 10px;
  top: 22px;
}

.header-icon img {
  filter: brightness(0) invert(1);
  opacity: 0.5;
}

.header-icon:hover img {
  filter: invert(66%) sepia(13%) saturate(11%) hue-rotate(357deg)
    brightness(99%) contrast(96%);
}

.header-link-icon {
  height: 23px;
  width: 23px;
  margin-right: 10px;
  display: none;
}

.header-link-icon img {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 992px) {
  .header-icon,
  .header-link-icon {
    display: block;
  }

  .header-logo {
    margin: 5px 0;
  }

  .header-bar,
  .user-state {
    display: none;
  }

  #nav {
    padding: 5px 20px;
    display: block;
  }

  .header-link-list {
    display: block;
    text-align: left;
    margin: 0;
  }

  .header-bar .header-link-item a {
    background: #37393f;
    border-radius: 50px;
    padding: 5px;
    margin: 10px 30px;
  }

  .header-bar .header-link-item a:hover {
    box-shadow: none;
  }

  .user-state {
    text-align: center;
    margin: 5px auto 0;
    width: 100px;
  }

  .user-state .login-btn {
    width: 100px;
    color: #000;
    background: #fff;
    height: 100%;
    border-radius: 25px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    margin: 0 0 5px;
  }

  .user-state .registration-btn,
  .user-state .logout-btn {
    margin-bottom: 5px;
  }
}
</style>
