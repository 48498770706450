<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mapActions } from 'vuex'

export default {
  name: 'TheModal',

  props: {
    bodyClasses: {
      type: Array,
      default: () => []
    },

    title: {
      type: String
    }
  },

  mounted() {
    document.body.addEventListener('keydown', this.onEscapeKeydown)

    this.disableBodyScroll()
  },

  beforeDestroy() {
    document.body.removeEventListener('keydown', this.onEscapeKeydown)

    this.enableBodyScroll()
  },

  methods: {
    ...mapActions('modal', {
      $_close: 'close'
    }),

    disableBodyScroll() {
      disableBodyScroll(this.$refs['modalBody'])
    },

    enableBodyScroll() {
      clearAllBodyScrollLocks()
    },

    onEscapeKeydown(event) {
      if (event.key === 'Escape') {
        event.preventDefault()
        event.stopImmediatePropagation()

        this.close()
      }
    },

    close() {
      this.$_close({ name: this.$parent.name })
    }
  }
}
</script>

<template>
  <div class="modal" @mousedown="close">
    <div class="modal__container">
      <div class="modal__wrapper" @mousedown.stop>
        <button class="modal__close" @click="close"></button>
        <div class="modal__header">
          <div class="modal__title">{{ title }}</div>
        </div>
        <div :class="['modal__body', ...bodyClasses]" ref="modalBody">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  display: block;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
  }

  &__close {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    mask-image: url('../../assets/close.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #d7d8d8;
    transition: 0.2s;

    border: none;

    -webkit-tap-highlight-color: transparent;

    &:hover {
      background-color: grey;
    }
  }

  &__body {
    overflow-y: auto;
  }

  &__header {
    height: 46px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#1e262e, #11121d);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
