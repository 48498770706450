<template>
  <div class="content">
    <div id="page-wrapper" style="padding-top: 45px;">
      <div id="main-wrap">
        <div id="main" style="padding: 0px; display: block;">
          <promo-carousel />
          <div class="casino">
            <filters />
            <game-grid />
          </div>
        </div>
      </div>
    </div>
    <registration-form />
    <auth />
  </div>
</template>

<script>
import RegistrationForm from '../modals/RegistrationForm'
import Auth from '../modals/Auth'

import PromoCarousel from './PromoCarousel'
import Filters from './Filters'
import GameGrid from './GameGrid'

export default {
  name: 'Casino',

  components: {
    Filters,
    GameGrid,
    PromoCarousel,
    RegistrationForm,
    Auth
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.casino {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 768px) {
  .casino {
    display: block;
  }
}
</style>
