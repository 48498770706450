<script>
const TWO_ZEROS_CUR = ['RUB', 'EUR', 'USD']

export default {
  computed: {
    formattedAmount() {
      const amount = this.payment.total.amount
      const currency = this.payment.total.currency
      if (amount) {
        const formattedAmount = TWO_ZEROS_CUR.includes(currency)
          ? parseFloat(amount).toFixed(2)
          : amount

        return formattedAmount + ' ' + currency
      } else {
        return '0.00 ' + currency
      }
    }
  }
}
</script>
