<script>
import TheModal from './TheModal'

export default {
  name: 'ErrorCallback',

  components: {
    TheModal
  }
}
</script>

<template>
  <the-modal title="Callback permission">
    <template v-slot:content>
      <div class="error-modal">
        <div class="error-modal__content">
          <div class="error-modal__icon">
            <img src="../../assets/secret-payment/warning.svg" alt="alert" />
          </div>

          <div>
            <div class="error-modal__text">
              Based on your region, the callback feature is not available.
            </div>
          </div>
        </div>
      </div>
    </template>
  </the-modal>
</template>
