<template>
  <div class="footer" id="footer">
    <div class="payment-systems">
      <h4>payment systems</h4>
      <div class="payment-system__logo">
        <div class="row">
          <div
            v-for="(item, index) in site.vars.paymentSystems"
            :key="index"
            :class="item"
          ></div>
        </div>
      </div>
    </div>
    <div class="licence">
      <h4>our licence</h4>
      <p>
        {{ site.vars.license }}
      </p>
    </div>
    <div class="privacy-policy">
      <h4>privacy policy</h4>
      <p>
        We use cookies and third-party cookies to improve our services; analyze
        and personalize your preferences and to show you advertisements. <br />
        If you continue the navigation, we consider that you are accepting its
        use. <br />
        You can modify the settings and obtain further information in our Cookie
        Notice.
      </p>
    </div>
    <div class="contacts-button">
      <button class="contact-us-btn" @click="openContactUsModal">
        address
      </button>
      <button
        class="feedback-btn"
        @click="$store.dispatch('activeFeedbackModal', 'hidden')"
      >
        contact us
      </button>
    </div>
    <div class="copyright">
      <p>{{ site.vars.copyright }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Footer',

  computed: {
    ...mapGetters(['site'])
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openContactUsModal() {
      this.$_showModal({ name: 'ContactUs' })
    }
  }
}
</script>

<style>
#footer {
  width: 100%;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  margin: 0 auto;
  background: #37393f;
  border-top: 1px solid #7a7a7b;
}

#footer h4 {
  color: #fff;
  font-style: italic;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

#footer p {
  color: #fff;
  font-size: 11px;
}

.payment-systems .payment-system__logo .row {
  display: flex;
  justify-content: center;
  align-content: space-around;
  min-height: 100px;
  flex-wrap: wrap;
  width: 70vw;
}

.payment-system__logo .row {
  margin: 20px auto 20px;
}

.row div {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 40px;
  margin: 5px 20px;
  cursor: pointer;
  background-position: center, 0px -40px;
}

.payment-system__logo .row .visa {
  background-image: url('../assets/footer-payment-icons/visa2.svg'),
    url('../assets/footer-payment-icons/visa1.svg');
}

.payment-system__logo .row .mastercard {
  background-image: url('../assets/footer-payment-icons/mastercard2.svg'),
    url('../assets/footer-payment-icons/mastercard1.svg');
}

.payment-system__logo .row .maestro {
  background-image: url('../assets/footer-payment-icons/maestro2.svg'),
    url('../assets/footer-payment-icons/maestro1.svg');
}

.payment-system__logo .row .qiwi {
  background-image: url('../assets/footer-payment-icons/qiwi2.svg'),
    url('../assets/footer-payment-icons/qiwi1.svg');
}

.payment-system__logo .row .webmoney {
  background-image: url('../assets/footer-payment-icons/webmoney2.svg'),
    url('../assets/footer-payment-icons/webmoney1.svg');
}

.payment-system__logo .row .skrill {
  background-image: url('../assets/footer-payment-icons/skrill2.svg'),
    url('../assets/footer-payment-icons/skrill1.svg');
}

.payment-system__logo .row .paypal {
  background-image: url('../assets/footer-payment-icons/paypal2.svg'),
    url('../assets/footer-payment-icons/paypal1.svg');
}

.payment-system__logo .row .mir {
  background-image: url('../assets/footer-payment-icons/mir2.svg'),
    url('../assets/footer-payment-icons/mir1.svg');
}

.payment-system__logo .row .visa-electron {
  background-image: url('../assets/footer-payment-icons/visa-electron2.svg'),
    url('../assets/footer-payment-icons/visa-electron1.svg');
}

.payment-system__logo .row .netteller {
  background-image: url('../assets/footer-payment-icons/netteller2.svg'),
    url('../assets/footer-payment-icons/netteller1.svg');
}

.payment-system__logo .row .bitcoin {
  background-image: url('../assets/footer-payment-icons/bitcoin2.svg'),
    url('../assets/footer-payment-icons/bitcoin1.svg');
  width: 40px;
}

.payment-system__logo .row .american-express {
  background-image: url('../assets/footer-payment-icons/american-express2.svg'),
    url('../assets/footer-payment-icons/american-express1.svg');
  width: 40px;
}

.payment-system__logo .row .ethereum {
  background-image: url('../assets/footer-payment-icons/ethereum2.svg'),
    url('../assets/footer-payment-icons/ethereum1.svg');
}

.payment-system__logo .row .bitcoin-cash {
  background-image: url('../assets/footer-payment-icons/bitcoin-cash2.svg'),
    url('../assets/footer-payment-icons/bitcoin-cash1.svg');
  width: 150px;
}

.payment-system__logo .row .litecoin {
  background-image: url('../assets/footer-payment-icons/litecoin2.svg'),
    url('../assets/footer-payment-icons/litecoin1.svg');
  width: 40px;
}

.payment-system__logo .row .monero {
  background-image: url('../assets/footer-payment-icons/monero2.svg'),
    url('../assets/footer-payment-icons/monero1.svg');
  width: 40px;
}

.payment-system__logo .row .swift {
  background-image: url('../assets/footer-payment-icons/swift2.svg'),
    url('../assets/footer-payment-icons/swift1.svg');
  width: 40px;
}

.payment-system__logo .row .yandex-money {
  background-image: url('../assets/footer-payment-icons/yandex-money2.svg'),
    url('../assets/footer-payment-icons/yandex-money1.svg');
}

.payment-system__logo .row .advcash {
  background-image: url('../assets/footer-payment-icons/advcash2.svg'),
    url('../assets/footer-payment-icons/advcash1.svg');
}

.payment-system__logo .row .usdt {
  background-image: url('../assets/footer-payment-icons/usdt2.svg'),
    url('../assets/footer-payment-icons/usdt1.svg');
}

.payment-system__logo .row .ton {
  background-image: url('../assets/footer-payment-icons/ton2.svg'),
    url('../assets/footer-payment-icons/ton1.svg');
}

.payment-system__logo .row .stellar {
  background-image: url('../assets/footer-payment-icons/stellar2.svg'),
    url('../assets/footer-payment-icons/stellar1.svg');
}

.payment-system__logo .row .solana {
  background-image: url('../assets/footer-payment-icons/solana2.svg'),
    url('../assets/footer-payment-icons/solana1.svg');
}

.payment-system__logo .row div:hover {
  background-position: 0px -40px, center;
}

.payment-system__logo .row .mastercard,
.payment-system__logo .row .maestro {
  width: 50px;
  margin: 5px 35px;
}

.privacy-policy {
  margin: 30px 0 40px;
}

.contacts-button {
  margin-bottom: 30px;
}

.contacts-button .contact-us-btn,
.contacts-button .feedback-btn {
  text-transform: uppercase;
  border: 1px solid #fff;
  background: none;
  color: #fff;
  margin: 5px 10px;
  cursor: pointer;
  min-width: 155px;
  font-size: 18px;
  border-radius: 20px;
}

.contacts-button .contact-us-btn:hover,
.contacts-button .feedback-btn:hover {
  background: #fff;
  color: #000;
}

.copyright p {
  font-size: 9px;
  color: #d7d7d9;
}

.licence {
  max-width: 1000px;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .row div {
    width: 80px;
    margin: 5px 10px;
  }
  .payment-systems .payment-system__logo .row {
    width: 95vw;
  }

  .payment-system__logo .row .bitcoin-cash {
    width: 100px;
  }
}
</style>
