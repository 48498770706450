<template>
  <div class="event-scoreboard">
    <goals v-if="currentScoreboard === 'goals'" :score="score" />
    <sets v-if="currentScoreboard === 'sets'" :sets="scoreboard.sets" />
    <points-with-sets
      v-if="currentScoreboard === 'pointsWithSets'"
      :sets="scoreboard.sets"
      :points="scoreboard.points"
    />
  </div>
</template>

<script>
import Goals from './Goals'
import Sets from './Sets'
import PointsWithSets from './PointsWithSets'

export default {
  name: 'Scoreboard',

  components: {
    Goals,
    Sets,
    PointsWithSets
  },

  props: {
    scoreboard: Object
  },

  computed: {
    score() {
      const score = this.scoreboard.score.split(':')
      return score
    },

    currentScoreboard() {
      const sportId = this.scoreboard.sportId
      const eventIdsWithGoals = [4, 7, 11, 12, 16, 22, 32, 34]
      const eventIdsWithSets = [18, 44, 56, 63]
      const eventIdsWithPointsAndSets = [5]
      if (eventIdsWithGoals.find(id => id == sportId)) {
        return 'goals'
      } else if (eventIdsWithSets.find(id => id == sportId)) {
        return 'sets'
      } else if (eventIdsWithPointsAndSets.find(id => id == sportId)) {
        return 'pointsWithSets'
      } else {
        return ''
      }
    }
  }
}
</script>
