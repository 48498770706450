var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.site.vars.promo.length == 1)?_c('div',{staticClass:"slider",attrs:{"id":"slider"}},[_c('div',{staticClass:"header-promo",class:_vm.promoPosition(_vm.site.vars.promo[0].position || 'left'),style:({
      backgroundImage: _vm.backgroundImage(
        _vm.site.vars.promo[0].image,
        _vm.site.vars.promo[0].blackout
      ),
      color: _vm.site.vars.promo[0].color + '!important'
    })},[_c('div',{staticClass:"header-promo__desc"},[_c('h1',{staticClass:"header-promo__title",style:({ color: _vm.site.vars.promo[0].color })},[_vm._v(" "+_vm._s(_vm.site.vars.promo[0].title)+" ")]),_c('p',{staticClass:"header-promo__text",class:_vm.promoTextPosition(_vm.site.vars.promo[0].position || 'left'),style:({ fontWeight: _vm.site.vars.promo[0].fontWeight || 400 })},[_vm._v(" "+_vm._s(_vm.site.vars.promo[0].description)+" ")])]),_c('div',{staticClass:"header-promo__action"},[_c('button',{staticClass:"auth-btn",style:({
          background: _vm.site.vars.promo[0].color,
          color: _vm.site.vars.promo[0].btnColor
        })},[_vm._v(" "+_vm._s(_vm.btnName)+" ")])])])]):_c('div',{staticClass:"slider",attrs:{"id":"slider"}},[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.site.vars.promo),function(promo,index){return _c('swiper-slide',{key:index,staticClass:"header-promo",class:_vm.promoPosition(promo.position || 'left'),style:({
        backgroundImage: _vm.backgroundImage(promo.image, promo.blackout),
        color: promo.color
      })},[_c('div',{staticClass:"header-promo__desc"},[_c('h1',{staticClass:"header-promo__title",style:({ color: promo.color })},[_vm._v(" "+_vm._s(promo.title)+" ")]),_c('p',{staticClass:"header-promo__text",class:_vm.promoTextPosition(promo.position || 'left'),style:({ fontWeight: promo.fontWeight || 400 })},[_vm._v(" "+_vm._s(promo.description)+" ")])]),_c('div',{staticClass:"header-promo__action"},[_c('button',{staticClass:"auth-btn",style:({ background: promo.color, color: promo.btnColor })},[_vm._v(" "+_vm._s(_vm.btnName)+" ")])])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"x":"0px","y":"0px","viewBox":"0 0 370.814 370.814"}},[_c('polygon',{attrs:{"fill":"#fff","points":"292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401   "}})])]),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"},[_c('svg',{attrs:{"x":"0px","y":"0px","viewBox":"0 0 478.448 478.448"}},[_c('polygon',{attrs:{"fill":"#fff","points":"131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448     377.954,239.232   "}})])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }