<template>
  <table class="transactions__table">
    <thead class="transactions__header">
      <tr>
        <td v-for="(header, index) in headers" :key="index">{{ header }}</td>
      </tr>
    </thead>
    <tbody border="0">
      <tr
        v-for="(transaction, index) in transactions"
        :key="index"
        class="transaction"
      >
        <td class="transaction__id">{{ transaction.id }}</td>
        <td
          class="transaction__amount"
          :style="{ color: transaction.total.amount > 0 ? '#7be16c' : '#fff' }"
        >
          <money :money="transaction.total" />
        </td>
        <td class="transaction__gateway">{{ transaction.gateway }}</td>
        <td
          class="transaction__status"
          :style="{ color: transaction.status.color }"
        >
          {{ transaction.status.name }}
        </td>
        <td class="transaction__comment">{{ transaction.comment || '-' }}</td>
        <td class="transaction__account">{{ transaction.account || '-' }}</td>
        <td class="transaction__date">
          <formatted-date :date="transaction.at" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import FormattedDate from '../../common/FormattedDate'
import Money from '../../common/Money'

export default {
  name: 'TransactionDesktop',

  components: {
    FormattedDate,
    Money
  },

  props: {
    transactions: Array
  },

  data: () => ({
    headers: [
      'ID',
      'Amount',
      'Payment System',
      'Status',
      'Comment',
      'Account',
      'Date'
    ]
  })
}
</script>
