<template>
  <img
    :class="{
      'white-filter': paymentSystem === 'qiwi'
    }"
    :src="paymentSystemIcon"
    :alt="`${paymentSystem}-logo`"
    :height="iconHeight"
  />
</template>

<script>
export default {
  name: 'PaymentSystemIcon',

  props: {
    paymentSystem: String,
    widgetDesign: String
  },

  computed: {
    paymentSystemIcon() {
      const images = require.context(
        '../../assets/footer-payment-icons/',
        false,
        /\.svg$/
      )
      return images('./' + `${this.paymentSystem}1` + '.svg')
    },

    iconHeight() {
      if (this.widgetDesign === 'new') {
        return 40
      } else if (this.paymentSystem === 'bitcoin-old') {
        return 50
      } else if (
        this.paymentSystem === 'visa' ||
        this.paymentSystem === 'mir'
      ) {
        return 70
      } else if (
        this.paymentSystem === 'mastercard' ||
        this.paymentSystem === 'litecoin'
      ) {
        return 80
      }
      return 100
    }
  }
}
</script>

<style>
.white-filter {
  filter: brightness(0) invert(1);
}
</style>
