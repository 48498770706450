<template>
  <the-modal title="Access Denied">
    <template v-slot:content>
      <div class="error-modal">
        <div class="error-modal__content">
          <div class="error-modal__icon">
            <img src="../../assets/lock.svg" alt="lock" />
          </div>
          <div class="error-modal__text">
            <p>{{ userAccessDeniedDesc }}</p>
          </div>
        </div>
      </div>
    </template>
  </the-modal>
</template>

<script>
import TheModal from './TheModal'
import { mapGetters } from 'vuex'

export default {
  name: 'UserAccessDenied',

  components: {
    TheModal
  },

  computed: {
    ...mapGetters(['userAccessDeniedDesc'])
  }
}
</script>
