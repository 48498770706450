<template>
  <div class="sport-tabs">
    <div class="menu-list" ref="sportMenu">
      <div
        class="menu-list__item"
        :class="{ active: sportIdFilter == sport.id }"
        v-for="sport in sports"
        :key="sport.id"
        @click="onSportFilterClick(sport)"
      >
        <sport-icon :sportId="sport.id" />
        <sport-name :sportId="sport.id" :sportCount="sport.count" />
      </div>
      <resizeObserver @notify="handleResize" />
    </div>
    <div class="right-scroll" @click="scroll('right')" v-if="rightScroll">
      <span></span>
      <div>
        <svg viewBox="0 0 8.66 15.71">
          <g id="right-scroll-icon">
            <g>
              <path
                d="M 0.8 15.71 L 8.66 7.86 h 0 L 0.8 0 L 0 0.79 L 7.07 7.84 L 0 14.91 Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="left-scroll" @click="scroll('left')" v-if="this.left > 0">
      <div>
        <svg viewBox="0 0 8.66 15.71">
          <g id="left-scroll-icon">
            <g>
              <path
                d="M 7.86 0 L 0 7.86 H 0 l 7.85 7.85 l 0.8 -0.79 l -7.07 -7 L 8.66 0.8 Z"
              />
            </g>
          </g>
        </svg>
      </div>
      <span></span>
    </div>
  </div>
</template>

<script>
import SportIcon from './SportIcon'
import SportName from './SportName'
import 'vue-resize/dist/vue-resize.css'
import { ResizeObserver } from 'vue-resize'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SportTabs',

  data() {
    return {
      isMounted: false,
      left: 0,
      clientWidth: 0
    }
  },

  components: {
    SportIcon,
    SportName,
    ResizeObserver
  },

  computed: {
    ...mapGetters(['sportIdFilter', 'sports']),

    rightScroll() {
      if (!this.isMounted) return

      const content = this.$refs.sportMenu
      if (
        content.scrollWidth > this.clientWidth &&
        this.clientWidth + this.left <= content.scrollWidth
      ) {
        return true
      }
      return false
    }
  },

  mounted() {
    this.isMounted = true
    this.handleResize()
  },

  methods: {
    ...mapMutations(['onSportFilterClick']),

    scroll(direction) {
      let content = this.$refs.sportMenu
      let scrollAmount = 0
      var slideTimer = setInterval(function() {
        if (direction == 'left') {
          content.scrollLeft -= 20
        } else {
          content.scrollLeft += 20
        }
        scrollAmount += 20
        if (scrollAmount >= 160) {
          window.clearInterval(slideTimer)
        }
      }, 20)
      if (direction == 'left') {
        this.left -= 160
      } else {
        this.left += 160
      }
    },

    handleResize() {
      this.clientWidth = this.$refs.sportMenu.clientWidth
      this.left = this.$refs.sportMenu.scrollLeft
    }
  }
}
</script>

<style>
.sport-tabs {
  padding: 10px 25px 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.hidden {
  display: none;
}

.right-scroll,
.left-scroll {
  position: absolute;
  height: 100%;
  width: 50px;
  padding: 10px 0;
  top: 0;
  opacity: 1;
  background: transparent !important;
}

.right-scroll {
  right: 25px;
  text-align: right;
}

.left-scroll {
  left: 25px;
  text-align: left;
}

.right-scroll div,
.left-scroll div {
  width: 24px;
  height: 30px;
  position: absolute;
  background: #29292b;
  cursor: pointer;
  padding: 0 5px;
}

.right-scroll div {
  right: 0;
  /* margin: auto 0 auto auto; */
}

.left-scroll div {
  left: 0;
  /* margin: auto 0 auto auto; */
}

.right-scroll span,
.left-scroll span {
  width: 26px;
  height: 30px;
  position: absolute;
}

.right-scroll span {
  left: 0;
  /* background: linear-gradient(to left, transparent ,#29292b 100%); */
  /* background: -moz-linear-gradient(to left, transparent ,#29292b 100%); */
  /* background: -webkit-linear-gradient(to left, transparent ,#29292b 100%); */
  /* background: -o-linear-gradient(to left, transparent ,#29292b 100%); */
  /* background: -ms-linear-gradient(to left, transparent ,#29292b 100%); */
}

.left-scroll span {
  right: 0;
  /* background: linear-gradient(to right, transparent ,#29292b 100%); */
  /* background: -moz-linear-gradient(to right, transparent ,#29292b 100%); */
  /* background: -webkit-linear-gradient(to right, transparent ,#29292b 100%); */
  /* background: -o-linear-gradient(to right, transparent ,#29292b 100%); */
  /* background: -ms-linear-gradient(to right, transparent ,#29292b 100%); */
}

.right-scroll div:hover,
.left-scroll div:hover {
  background-color: #686868;
}

.right-scroll div svg,
.left-scroll div svg {
  width: 12px;
  height: 100%;
}

.right-scroll div svg path,
.left-scroll div svg path {
  fill: grey;
}

.right-scroll div:hover svg path,
.left-scroll div:hover svg path {
  fill: #fff;
}

.menu-list {
  display: flex;
  border-bottom: 1px solid #7a7a7b;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.menu-list__item {
  display: flex;
  margin-right: 10px;
  height: 100%;
  color: #868687;
  cursor: pointer;
}

.active {
  background: #545455 !important;
  color: #fff !important;
}

.active path {
  fill: #fff !important;
}

.menu-list__item-icon {
  margin: 5px 0px 5px 5px;
  display: flex;
}

.menu-list__item-icon svg {
  width: 20px;
  height: 20px;
}

.menu-list__item-icon svg path {
  fill: #949495;
}

.menu-list__item-title {
  font-size: 11px;
  margin: auto 5px auto 3px;
  text-transform: uppercase;
  white-space: nowrap;
}

.menu-list__item:hover {
  color: #fff;
}

.menu-list__item:hover svg path {
  fill: #fff;
}
</style>
