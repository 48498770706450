<template>
  <div id="commission-widget" class="widget commission ">
    <div class="widget__title">
      {{ paymentSystem.text }} - {{ payment.type }}
    </div>
    <div class="widget__content">
      <div class="payment">
        <div class="payment-column" style="margin-bottom: 20px;">
          <div class="payments__logo">
            <payment-system-icon
              :paymentSystem="correctPaymentSystem"
              :widgetDesign="'old'"
            />
          </div>
          <div class="payment__details">
            <div class="payment__item info">
              <div class="info__title">Amount of {{ paymentSystem.text }}:</div>
              <div class="info__content">
                {{ formattedAmount }}
              </div>
            </div>
            <div class="payment__item info">
              <div class="info__title">
                {{ paymentSystem.text }} wallet {{ walletType }}:
              </div>
              <div class="info__content">{{ walletAddress }}</div>
            </div>
            <div class="payment__item info" v-if="isComment">
              <div class="info__title">{{ commentLabel }}:</div>
              <div class="info__content">{{ payment.comment }}</div>
            </div>
            <div class="payment__item info">
              <div class="info__title">Status:</div>
              <div class="info__content">{{ statusText }}</div>
            </div>
          </div>
          <div class="payment__status">
            <div class="payment__icon" v-if="payment.status.name == 'pending'">
              <div class="lds-dual-ring"></div>
            </div>
            <div
              class="payment__icon"
              v-else-if="payment.status.name !== 'check'"
            >
              <status-icon
                :status="payment.status.name"
                :width="30"
                :height="30"
              />
            </div>
            <button class="check-btn" v-else>Check</button>
          </div>
        </div>

        <div class="payment-column">
          <div
            class="payment__message"
            :style="{ color: whiteAlert ? '#fff' : '#ff2c2c' }"
          >
            <span v-if="isBtns">*</span>Send the exact amount, otherwise the
            transaction may not go through.<br />
            Details for receiving funds:
            <div
              style="word-break: break-word"
              v-for="(withdrawal, index) in payment.withdrawalList"
              :key="index"
            >
              {{ withdrawal.gateway }} -
              {{ withdrawal.account }}
            </div>
            <template v-if="isBtns"
              ><br />
              <br />
              <span>**{{ addingMessage }}</span></template
            >
          </div>
        </div>
        <div class="payment__btns" v-if="isBtns">
          <div class="payment__btn payment__btn_cancel">Cancel</div>
          <div class="payment__btn payment__btn_confirm">Confirm</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusIcon from '../../common/StatusIcon'
import PaymentSystemIcon from '../../common/PaymentSystemIcon'
import ChristmasAmount from '../mixins/ChristmasAmount'

export default {
  name: 'ChristmasCWidgetOldDesign',

  components: {
    StatusIcon,
    PaymentSystemIcon
  },

  mixins: [ChristmasAmount],

  props: {
    walletAddress: String,
    payment: Object,
    whiteAlert: Boolean,
    paymentSystem: Object,
    isComment: Boolean,
    walletType: String,
    addingMessage: String,
    isBtns: Boolean,
    statusText: String,
    commentLabel: String
  },

  computed: {
    correctPaymentSystem() {
      if (this.paymentSystem.value === 'qiwi') {
        return 'qiwi-old'
      } else if (this.paymentSystem.value === 'bitcoin') {
        return 'bitcoin-old'
      }
      return this.paymentSystem.value
    }
  }
}
</script>
