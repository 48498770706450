<template>
  <div class="casino__item">
    <div class="game" @click="openModal">
      <div
        class="game__img"
        :style="{ backgroundImage: `url('${gameImg}')` }"
      ></div>
      <div class="game__active">
        <img
          src="../../../assets/casino/play.svg"
          alt="play-btn"
          width="60"
          height="60"
        />
        <div class="game__playfree">
          play free
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CasinoGame',

  props: {
    gameImg: String
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show'
    }),

    openModal() {
      this.$_showModal({ name: 'ErrorCasino' })
    }
  }
}
</script>

<style>
.casino__item {
  width: calc(100% / 2);
  position: relative;
  padding-top: 28%;
}

.casino__item .game {
  position: absolute;
  top: 5px;
  right: 5px;
  left: 5px;
  bottom: 5px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  overflow: hidden;
}

.casino__item .game__img {
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);
  background-color: #3e3c3a;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.casino__item .game__active {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.2s;
  padding: 11px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.casino__item:hover .game__img {
  transform: scale(1.1);
  background-size: cover, 0;
}

.casino__item:hover .game__active {
  opacity: 1;
}

.game__active img {
  filter: brightness(0) invert(1);
  transition: 0.2s;
}

.game__active img:hover {
  filter: invert(81%) sepia(8%) saturate(2585%) hue-rotate(62deg)
    brightness(97%) contrast(92%);
}

.game__playfree {
  color: #fff;
  text-transform: uppercase;
  transition: 0.2s;
  text-decoration: underline;
}

.game__playfree:hover {
  color: #7be16c;
}

@media screen and (min-width: 576px) {
  .casino__item {
    width: calc(100% / 3);
    padding-top: 19%;
  }

  .casino__item .game__img {
    background-size: cover, 260px 110px;
    background-repeat: no-repeat;
    background-position: center, -30px;
  }
}

@media screen and (min-width: 992px) {
  .casino__item {
    width: calc(100% / 4);
    padding-top: 15%;
  }

  .casino__item .game__img {
    background-size: cover, 260px 110px;
    background-repeat: no-repeat;
    background-position: center, -30px;
  }
}

@media screen and (min-width: 1241px) {
  .casino__item {
    width: calc(100% / 5);
    padding-top: 11%;
  }
}
</style>
